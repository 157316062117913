<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"> {{'label.AddNote' | translate}}</h4>
    <button type="button" class="pop-up-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
       <span aria-hidden="true" class="pop-up-close-bt">×</span>
    </button>
 </div>
 <div class="modal-body">
<div class="login">
    <div  class="container">
      <form>
        <ng-container >

          <textarea rows="4" cols="" class="form-control note-text" [(ngModel)]="suggestion" [ngModelOptions]="{standalone: true}"  placeholder="Enter Text Here..." style="margin-bottom: 10px; width: 100%;">
           </textarea>

            <!-- <div class="form-label-group">
                <input  type="email" id="userName" [(ngModel)]="suggestion" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="">
             </div> -->
             <a class="btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2"  (click)="onAdd()">{{'button.Submit' | translate}}</a>
      </ng-container>
      </form>
    </div>
  </div>
 </div>


