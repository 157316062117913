import { Component, OnInit, ViewEncapsulation, AfterViewInit, Output, EventEmitter, HostListener, Renderer2, ElementRef,ViewChild } from '@angular/core';
import { Store } from '../models/stores';
import { CommonService } from '../services/common.service';
import { StoreService } from '../services/store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  storeList : Store[] =[];
  trendingStores : Store[]=[];
  filterList =[];
  searchKey : string ="";

  navbarOpen = false;
  public clicked = false;
  _el: any;
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  /**
   * This is the toogle button elemenbt
   */
  @ViewChild('search') search: ElementRef;
  @ViewChild('searchResult') searchResult: ElementRef;

  constructor( private storeService : StoreService , 
    private commonService : CommonService, 
    private router : Router, private renderer: Renderer2  ) { 
      this.renderer.listen('window', 'click',(e:Event)=>{
        if(e.target !== this.search.nativeElement){
          this.resultWrapper=false;
        }
      })
    }

  ngOnInit(): void {
    this.commonService.viewState=""
    this.getAllStores();
  }

  onClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicked = true;
  }

  @HostListener('document:click', ['event'])
  private clickedOutside(event): void {
    if (this.clicked) {
      this._el.nativeElement.querySelector('.dropdown-menu').classList.toggle('show');
    }
  }

  getAllStores(){
    this.storeService.getStores().subscribe(resdata =>{
      this.storeList = resdata;
    })
  }

  resultWrapper = false;
  showSearchResult() {
    this.resultWrapper = true;
  }

  onSearch(){
    if(this.searchKey.length > 0){
      this.filterList = this.storeList.filter((x) =>
      x.storeName
        .toLocaleLowerCase()
        .includes(this.searchKey.toLocaleLowerCase())
    );
    }
    else{
    this.filterList =[]
    }
   
  }
  navigate(storeId){
    this.router.navigate(['/menu'], {
      queryParams: { storeId: storeId},
    });
    this.resultWrapper=false;

  }

}
