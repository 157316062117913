<!-- Call to action box -->

<!-- <section class="section pt-3 pb-3 text-center bg-white-1">
	<div class="container">
	   <div class="row">
		  <div class="col-sm-12">
			 <h5 class="m-0 bg-white-1">Operate food store or restaurants? <a href="https://www.edinemenu.com/">Work With Us</a></h5>
		  </div>
	   </div>
	</div>
 </section> -->
<!-- Call to action box ends here-->
<!-- Footer -->
<!-- <div class="clear"></div> -->
<div class="clear"></div>
<footer class="pt-4 pb-4 text-center footer">
	<div class="container">
	  © Copyright {{today | date : 'yyyy'}} eDine Menu | Qatar. All Rights Reserved
	</div>
 </footer>
<!-- Footer ends here-->
