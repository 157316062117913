import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from "@angular/common";
import { SignInPopupComponent } from '../sign-in-popup/sign-in-popup.component';
import { SignupPopupComponent } from '../signup-popup/signup-popup.component';
import { LoginAsGuestPopupComponent } from '../login-as-guest-popup/login-as-guest-popup.component';




import { DbService} from '../../services/db.service'
import { ConfirmationService } from 'src/app/shared/confirmation.service';
import { StoreService } from 'src/app/services/store.service';
import { Config } from 'src/app/services/config';
@Component({
  selector: 'app-store-rating-popup',
  templateUrl: './store-rating-popup.component.html',
  styleUrls: ['./store-rating-popup.component.scss']
})
export class StoreRatingPopupComponent implements OnInit {
  storeRatingForm : number = 0;
  @Input() storeId: number;
  @Input() storeRating: number;

  storeDetails
  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private commonService :  CommonService,
    private datePipe : DatePipe ,
    private dbService : DbService,
    private storeService : StoreService,
    private confirmationDialogService : ConfirmationService,


  ) { }

  ngOnInit(): void {

      this.storeRatingForm = 0;
      this.getCustomerRatingsForStore();
      this.getStoreDetails(this.storeId);
  }
  getStoreDetails(storeId) {
    this.storeService.getStoreDetailsbyId(storeId).subscribe((resdata) => {
      this.storeDetails = resdata;
    })
  }



  deleteRating(id , tp , index, productId ?){
    let body = {
      Id 
      : id
    }
    
    let head ;
    let message ;
    if(Config.lang == "0"){
       head ='Please Confirm'
       message  ='Are you sure to remove your review ?'
     
    }
    else{
      head ='يرجى تأكيد'
      message  ='هل أنت متأكد من إزالة رأيك؟'

    }
    this.confirmationDialogService
      .confirm(head, message)
      // this.confirmationDialogService.confirm('Please Confirm ','Are you sure to remove your review ?')
      .then((confirmed) =>
      {
        if(confirmed){
          this.dbService.methodPost("CustomerRatings/DeleteCustomerRating" ,body).subscribe(resdata =>{
            
            if(resdata.id > 0){
                this.toastr.success("Review removed successfully");
                if(tp == 0){
                  
                // this.storeRatingList.splice(index, 1);       
                this.getCustomerRatingsForStore();
                }
                if(tp == 1){
                  // this.getCustomerRatingsForProduct(productId);
                  // this.productRatingList.splice(index, 1);       
                }
            }
           })
        }

    })
  }
  review : string = ""
  onReviewFormSubmit(){
    if (localStorage.getItem("customer") !== null) {
    if(this.storeRatingForm == 0){
      this.toastr.error("Please add rating");
      return;
    }
    let body  = {
      Id : 0,
      StoreId : Number(this.storeId),
      CustomerId : this.commonService.customer.id,
      Rating : this.storeRatingForm ,
      Review :  this.review,
      SubmittedOn : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
      IsActive : true
    }
    
    this.dbService.methodPost("CustomerRatings" , body).subscribe(resdata =>{
      
      if(resdata.id > 0){
        this.toastr.success("Your review submitted successfully");
        this.review = ""
        this.getCustomerRatingsForStore();
      }
    })
  }
  else{ 
    const modalRef =  this.modalService.open(SignupPopupComponent).result.then(
      (result) => {
      if(result == 1){
           const modalRef2 =  this.modalService.open(SignInPopupComponent);
      }
      if(result == 2){
           const modalRef3 =  this.modalService.open(LoginAsGuestPopupComponent);
      }
      },
    );

  }

  }

  storeRatingList =[]
  getCustomerRatingsForStore(){
    let body  = {
      StoreId : Number(this.storeId) ,
      CustomerId : 0,
      ProductId : 0 ,
      VariantId : 0
    }
    
    this.dbService.methodPost("CustomerRatings/GetCustomerActiveRatings", body).subscribe(resdata =>{
      
      this.storeRatingList = resdata;
      this.storeRatingList.forEach((x) => {
        x.rating = Math.round(x.rating);
        x.rating =(parseFloat(x.rating.toString()).toFixed(1));
      });
    })
  }
}
