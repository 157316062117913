import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DbService } from 'src/app/services/db.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  homeUrl : string ;
  constructor(private http : HttpClient , private dbService : DbService) {
    this.homeUrl = dbService.getApiURL();

    
   }
 

  addCustomer(data): Observable<any>{
    return this.http.post( this.homeUrl + "Customers", data);
  }
  CustomerLogin(data): Observable<any>{
    return this.http.post( this.homeUrl + "Customers/CustomerLogin", data);
  }
  VerifyCustomer(data): Observable<any>{
    return this.http.post( this.homeUrl + "Customers/VerifyCustomer", data);
  }
  GuestLogin(data): Observable<any>{
    return this.http.post( this.homeUrl + "Customers/GuestLogin", data);
  }
}
