import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-offer-subscription-popup',
  templateUrl: './offer-subscription-popup.component.html',
  styleUrls: ['./offer-subscription-popup.component.scss']
})
export class OfferSubscriptionPopupComponent implements OnInit {



@Input() storeId : number =0;
  submitted : boolean = false;
  subscribeForm: FormGroup;
  constructor(public activeModal : NgbActiveModal , private fb : FormBuilder,
    private toastr: ToastrService,
    private dbService : DbService     ) { }

  ngOnInit(): void {
    this.buildForm();
  }


  buildForm() {
    this.subscribeForm = this.fb.group({
      StoreId : Number(this.storeId),
      CustomerName :['' , Validators.required],
      Phone: ['', [Validators.required , Validators.minLength(8)]],
    })
  }



  get form (){ return this.subscribeForm.controls };


  onSubmit(){
    this.submitted = true;

    if(this.subscribeForm.invalid){
      return;
    }


    

    this.form.Phone.setValue(this.form.Phone.value.toString())

   this.dbService.methodPost("Customers/PostUnRegCustomer", this.subscribeForm.value).subscribe(resdata => {
    if(resdata && resdata.id >0){
      this.toastr.success('Thanks for subscribing');
      // this.buildForm();
      this.activeModal.close();
    }
   })

  }
}
