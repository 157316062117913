<!-- <nav style="padding: 10px;">
	<a style="margin-right: 10px;" routerLink="/home">Home</a>
	<a style="margin-right: 10px;" routerLink="/restaurants">Restaurants</a>
	<a style="margin-right: 10px;" routerLink="/ordertype">Order Types</a>
	<a style="margin-right: 10px;" routerLink="/validationMessages">Home</a>
	<a style="margin-right: 10px;" routerLink="/registration">Restaurants</a>
	<a style="margin-right: 10px;" routerLink="/paymentPage">Payment Page</a>
	<a style="margin-right: 10px;" routerLink="/orderHistory">Order History</a>
</nav> -->


<div [dir]="'dir' | translate"  >
	<app-header [companyDetails]="companyDetails" *ngIf="themeLoaded && router.url != '/' && !router.url.includes('/creper-and-pepper')  &&!router.url.includes('/gazebo-landing')"   ></app-header>
	<app-splash-screen  [animationType]="'slide-left'" *ngIf="themeLoaded && !router.url.includes('/creper-and-pepper')" [duration]="2" [animationDuration]="0.5" [companyDetails]="companyDetails"></app-splash-screen>
	<router-outlet *ngIf="themeLoaded"></router-outlet>
	<app-footer *ngIf="themeLoaded && router.url != '/' && !router.url.includes('/creper-and-pepper') &&!router.url.includes('/gazebo-landing')"></app-footer>
	

</div>


<!-- {{router.url}} -->
 
<ngx-spinner *ngIf="!themeLoaded" ></ngx-spinner>
<!-- <app-popup></app-popup> -->

   