export class Order {
    StoreID: number;
    CustomerID: number;
    CustomerName: string;
    CustomerPhone:string;
    CustomerEmail:string;
    CustomerAddress:string;
    CustomerLocation: string;
    TableID: number;
    OrderedOn: string;
    ProductID: number;
    Quantity:  number;
    IsActive : true;
    SubmitBy:  string;
    SubmitOn: string;
    Price : number;
    ProductName : string;
    ProductNameAr : string;
    TotalPrice: number; 
    addTime : Date;
    VariantId : number;
    LinkId : string;

    IsAddOn : boolean
    IsCombo : boolean
    loyaltyMenuGroups : Array<any>=[];
    comboDetails 

}



export class OrderStatusData {
    Id: number;
    OrderStatus: number;    
    ExpectedDeliveryTime : string;
    SubmitOn: String;
    SubmitBy: string;
    IsCustomerCancelled : boolean

}
