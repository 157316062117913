

<div class="login d-flex align-items-center py-5">
    <div  class="container">
      <form [formGroup]="signInForm" >
        <ng-container >
            <div class="form-label-group">
                <input formControlName="Name" type="email" id="userName" class="login-text-reg" placeholder="Vigil">
                <label for="userName">Name <span class="required">*</span>
                </label>
             </div>
           <div class="row">
              <div class="col-3 pr-0">
                 <div class="form-label-group">
                    <input formControlName="CountryCode" type="text" id="countryCode" class="login-text-reg " placeholder="" disabled>
                    <label for="countryCode" class="login-text">ISD Code</label>
                 </div>
              </div>
              <div class="col-9">
                 <div class="form-label-group">
                    <input  formControlName="Phone" type="Phone" id="Phone" class="login-text-reg" placeholder="+123 456 7890">
                    <label for="Phone" class="login-text">Mobile</label>
                 </div>
              </div>
              <a   class=" btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2"  (click)="login()">Login</a>
           </div>
  
           
       
      
      </ng-container>
  
       
      </form>
  
    </div>
  
  </div>
  