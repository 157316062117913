import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '../models/stores'
import { DbService } from './db.service';
@Injectable({
  providedIn: 'root'
})
export class StoreService {
  homeUrl : string ;
  constructor(private http : HttpClient , private dbService : DbService) {
    this.homeUrl = dbService.getApiURL();

   }


  getStores():Observable<Store[]>{
    return this.http.get<Store[]>( this.homeUrl + "Stores/GetStores");
  }
  getOfficeStores(officeId):Observable<Store[]>{
    return this.http.get<Store[]>( this.homeUrl + "Offices/GetWebOffficeStores/" + officeId);
  }

  getTrendingStores():Observable<Store[]>{
    return this.http.get<Store[]>( this.homeUrl + "Stores/GetTrendingStores");
  }

  getAllMenusByStoreId(storeId){
    return this.http.get<any[]>(this.homeUrl + "Products/GetStoreProducts/" + storeId);
   }
   getPromotedProductes(storeId){
    return this.http.get<any[]>(this.homeUrl + "Products/GetTrendingProducts/" + storeId);
   }
   getProductsByStore(storeId){
    return this.http.get<any[]>(this.homeUrl + "Products/GetProductsinStore/" + storeId);
   }
   getProductDetailsById(productId){
    return this.http.get<any[]>(this.homeUrl + "Products/" + productId);
   }
   getOfficeDetailsById(officeId){
    return this.http.get<any[]>(this.homeUrl + "offices/" + officeId);
   }

   getStoreDetailsbyId(storeId : number):Observable<Store>{
    return this.http.get<Store>(this.homeUrl + "Stores/" + storeId);
  }

  confirmOrders(orders): Observable<any>{
    return this.http.post( this.homeUrl + "Orders",orders);
  }
  confirmNewOrders(orders): Observable<any>{
    return this.http.post( this.homeUrl + "Orders/InsertOrderData",orders);
  }


    getOrdersByCustomerId(customerId){
      return this.http.get<any>(this.homeUrl + "Orders/GetCustomerOrders/" + customerId);

    }
    GetCustomerOrdersStatus(orderId){
      return this.http.get<any>(this.homeUrl + "Orders/GetCustomerOrdersStatus/" + orderId);
    }

    getCategorylinked():Observable<any[]>{
      return this.http.get<any[]>( this.homeUrl + "StoreCategories/GetCategorylinked");
    }

    getOfficeCategorylinked(officeId):Observable<any[]>{
      return this.http.get<any[]>( this.homeUrl + "StoreCategories/GetOfficeCategorylinked/" + officeId);
    }

    getStoresinCategory(categroyid):Observable<any[]>{
      return this.http.get<any[]>( this.homeUrl + "StoreCategories/GetStoresinCategory/" +categroyid);
    }
    getStoresinCategoryAndOfficeId(categroyid , officeId) :Observable<any>{
      let body  = {
        OfficeId : officeId,
        CategoryId : categroyid
      }
      
      return this.http.post( this.homeUrl + "StoreCategories/GetOfficeStoresinCategory/",body);

    }
}
