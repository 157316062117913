import { Component, OnInit, Input  } from '@angular/core';
import { Config } from '../services/config';
import { SplashAnimationType } from './splash-animation-type';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  themeId = 0;
  lang=Number(Config.lang)
    windowWidth: string;
    splashTransition: string;
    opacityChange: number = 1;
    showSplash = true;
    @Input() companyDetails : any;
  
    @Input() animationDuration: number = 1;
    @Input() duration: number = 3;
    @Input() animationType: SplashAnimationType = SplashAnimationType.SlideLeft;
  
    ngOnInit(): void {
      if(localStorage.getItem('theme')){
        this.themeId = Number(atob(localStorage.getItem('theme')));
      }
      setTimeout(() => {
        let transitionStyle = "";
        switch (this.animationType) {
          case SplashAnimationType.SlideLeft:
            this.windowWidth = "-" + window.innerWidth + "px";
            transitionStyle = "left " + this.animationDuration + "s";
            break;
          case SplashAnimationType.SlideRight:
            this.windowWidth = window.innerWidth + "px";
            transitionStyle = "left " + this.animationDuration + "s";
            break;
          case SplashAnimationType.FadeOut:
            transitionStyle = "opacity " + this.animationDuration + "s";
            this.opacityChange = 0;
        }
  
        this.splashTransition = transitionStyle;
  
        setTimeout(() => {
          this.showSplash = !this.showSplash;
        }, this.animationDuration * 1000);
      }, this.duration * 1000);
    }
}
