<div
  class="app-splash-screen"
  [ngStyle]="{
    left: windowWidth,
    opacity: opacityChange,
    transition: splashTransition
  }"
  *ngIf="showSplash && companyDetails?.logoUrl"
>
  <div class="app-splash-inner">
    <div class="app-logo"><img *ngIf="themeId != 1005" [src]="companyDetails?.logoUrl">   </div>
    <div class="app-label"></div>
    <div class="app-loader"></div>
  </div>
</div>



<div
  class="app-splash-screen"
  [ngStyle]="{
    left: windowWidth,
    opacity: opacityChange,
    transition: splashTransition
  }"
  *ngIf="showSplash && !companyDetails?.logoUrl"
>
  <div class="app-splash-inner">
    <div class="app-logo">
      <img *ngIf="lang == 0 && themeId != 1005" src="../../assets/img/ic_launcher.png"> 
      <img  *ngIf="lang == 1 && themeId != 1005" src="../../assets/img/ic_launcher-ar.png"> 


      <!-- <img *ngIf="themeId == 1034" src="../../assets/img/ic_launcher-creper.png">  -->
      <!-- <img *ngIf="themeId == 1035" src="../../assets/img/ic_launcher-pepper.png">  -->

    
    
    </div>
    <div class="app-label"></div>
    <div class="app-loader"></div>
  </div>
</div>
