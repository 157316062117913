
<div class="modal-content modal-content-color">

    <div class="modal-header">
      <h5 class="modal-title-1">{{'label.ChooseLocation' | translate}}</h5>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true" class="pop-up-close-bt">×</span>
     </button>
       
      </div>
      <div class="modal-body" style="font-size: 16px;">
        <div class="container" >
    
          <div class="row">
          
    
              <div class="row">
                <div class="col-12 pr-0">
                  <ng-container *ngFor="let item of addressList">
                    <label for="" class="container popup-choose-7 " style="margin-bottom: 30px;">
                    <input type="radio"name="radio"  [(ngModel)]="customerSelectedAddressId" [value]="item.id" name="addressTypeId" width="20px" height="20px">
                   
                  

                    
                    {{item.addressType}} :-  <div class="clear"></div>

                 

                    <span *ngIf="item.building">
                     {{'label.Building' | translate}} : {{item.building}},
                    </span>
                    <div class="clear"></div>
                    <span *ngIf="item.floor">
                      {{'label.Floor' | translate}} : {{item.floor}} ,
                    </span> <div class="clear"></div>

                    <span *ngIf="item.addressTypeId == 1">
                      {{'label.AppartmentNo' | translate}} :
                  </span>
                  <!-- <div class="clear"></div> -->
                  <span *ngIf="item.addressTypeId == 2">
                    {{'label.HouseNo' | translate}} :
                </span>
                <!-- <div class="clear"></div> -->
                  <span *ngIf="item.addressTypeId == 3">
                    {{'label.OfficeNo' | translate}} :
                </span>


                  {{item.houseNo}}  ,
                  <div class="clear"></div>
                    <span *ngIf="item.location">
                      {{'label.Location' | translate}} : {{item.location}}

                    </span>


                </label>

                <div class="clear"></div>
                
                    <!-- <label class="container takeaway payment-mode-padd">{{item?.name}}
                        <span class="check-padd">
                            <input type="radio"[value]="item.id"  class=" checkmark" name="radio">
                            <span class="checkmark"></span>
                        </span>
                        
                      </label> -->
                </ng-container> 
                  </div>
                 
    
                
                        
    </div>
    
    
                    <div class="col-6 pr-0">
                   
                  </div>
          
      </div>
      </div>
      <div class="modal-footer" >
        <button type="button" class="btn btn-primary" (click)="addNewAddress()" >{{'button.AddNew' | translate}}</button>

        <button type="button" class="btn btn-primary" (click)="confirm()" >{{'button.Confirm' | translate}}</button>
    
      </div>
    </div>
    
       
          
      