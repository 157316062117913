import { DatePipe } from "@angular/common";
import { ThrowStmt } from "@angular/compiler";
import { DomSanitizer } from '@angular/platform-browser';

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { connect } from "http2";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { threadId } from "worker_threads";
import { AuthService } from "../auth/services/auth.service";
import { Order } from "../models/order";
import { CommonService } from "../services/common.service";
import { Config } from "../services/config";
import { DbService } from "../services/db.service";
import { StoreService } from "../services/store.service";
import { LoginAsGuestPopupComponent } from "../shared-popups/login-as-guest-popup/login-as-guest-popup.component";
import { SignInPopupComponent } from "../shared-popups/sign-in-popup/sign-in-popup.component";
import { SignupPopupComponent } from "../shared-popups/signup-popup/signup-popup.component";
import { ConfirmationService } from "../shared/confirmation.service";
import { utimes } from "fs";
import { OfferSubscriptionPopupComponent } from "../shared-popups/offer-subscription-popup/offer-subscription-popup.component";
import { InstagramFollowPopupComponent } from "./instagram-follow-popup/instagram-follow-popup.component";

@Component({
  selector: "app-restaurants-detail",
  templateUrl: "./restaurants-detail.component.html",
  styleUrls: ["./restaurants-detail.component.scss"],
})
export class RestaurantsDetailComponent implements OnInit, OnDestroy {
  menuDetails = [];
  // @ViewChild("signup", { static: true }) signUpPopup: ElementRef;
  // @ViewChild("signIn", { static: true }) signIn: ElementRef;
  @ViewChild("congrats", { static: true }) offerContent: ElementRef;
  @ViewChild("review1", { static: true }) review1: ElementRef;
  @ViewChild("review2", { static: true }) review2: ElementRef;
  @ViewChild("addons", { static: true }) addons: ElementRef;
  
  
  // @ViewChild("asGuest", { static: true }) asGuest: ElementRef;

  signUpForm: FormGroup;
  closeResult: string;
  selectedProductDetails: any;
  promotedProducts = [];
  productList = [];
  productSearchList = [];

  storeDetails;


  public forDine : boolean = false;




  addOnTotalPrice : number = 0.00;
  searchKey: string = "";
  storeId: number;
  tableId: number;
  viewState: string = "";
  searchMode: boolean = false;

  storeRating : any= 0;
  storeRatingForm : number = 0;

  queryParamsCateg : number =  0;
  /**
   * This is the toogle button elemenbt
   */

  constructor(
    private route: ActivatedRoute,
    private _sanitizer : DomSanitizer,
    private storeService: StoreService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private router: Router,
    private dbService : DbService,
    private authService: AuthService,
    private fb: FormBuilder,
    private confirmationDialogService : ConfirmationService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,

    private datePipe: DatePipe
  ) {}

  onClickedOutside(e: Event) {
    this.dishResult = false;
 
    // console.log('Clicked outside:', e);
  }

  fullscreen() {
    var elem = document.getElementById("vid");
    function openFullscreen() {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    }
  }



  addToCartAddOnsNew(selectedProduct){
    let uniqueId = Math.random().toString(36).substr(2, 9);
    this.selectedProductDetails.LinkId = uniqueId;
    this.addToCartAddOn(selectedProduct);
    let array = [];

    let singleArray= []
    if(this.selectedProductDetails.addOns.single){
    singleArray = this.selectedProductDetails.addOns.single;
    singleArray.forEach(x =>{
      if(x.selected == true){
        x.LinkId = uniqueId;
        array.push(x);
      }
    })
  }
    let multipleArray= []
    if(this.selectedProductDetails.addOns.multiple){
    multipleArray = this.selectedProductDetails.addOns.multiple;
    multipleArray.forEach(x =>{
      if(x.selected == true){
        x.LinkId = uniqueId;
        array.push(x);
      }
    })
  }
    
    array.forEach(x =>{
      this.addToCartAddOn(x, 2);
    })


        document.getElementById('close-pop').click();
         singleArray= []
        if(this.selectedProductDetails.addOns.single){
        singleArray = this.selectedProductDetails.addOns.single;
        singleArray.forEach(x =>{
          x.selected =false;
        })
      }
         multipleArray= []
        if(this.selectedProductDetails.addOns.multiple){
        multipleArray = this.selectedProductDetails.addOns.multiple;
        multipleArray.forEach(x =>{
          x.selected =false;

        })
      }
  }
  countForAddOn = 1
  countForCombo = 1

  
  onPlusQtyAddOnCount(){
    this.countForAddOn = this.countForAddOn + 1;
    this.findTotalOfAddOns();
  }
  onMinusQtyAddOnCount(){
    if(this.countForAddOn != 1){
      this.countForAddOn = this.countForAddOn - 1;
    }
    this.findTotalOfAddOns();

  }

  onPlusQtyComboCount(){
    this.countForCombo = this.countForCombo + 1;
    this.findTotalOfAddOnsCombo();
  }
  onMinusQtyComboCount(){
    if(this.countForCombo != 1){
      this.countForCombo = this.countForCombo - 1;
    }
    this.findTotalOfAddOnsCombo();

  }



  findTotalOfAddOns(){
    this.productDetailsTotalWithAddOn = this.countForAddOn * Number( this.selectedProductDetails.price);
    this.selectedProductDetails.addOns.single.forEach((x,i) =>{
      if(x.selected == true){
        this.productDetailsTotalWithAddOn =Number (this.productDetailsTotalWithAddOn) + (this.countForAddOn * Number(x.price))
      }
    })
    this.selectedProductDetails.addOns.multiple.forEach((x,i) =>{
      if(x.selected == true){
        this.productDetailsTotalWithAddOn =Number (this.productDetailsTotalWithAddOn) + (this.countForAddOn * Number(x.price))
      }
    })
  }

  findTotalOfAddOnsCombo(){
    this.productDetailsTotalWithAddOn = this.countForCombo * Number( this.selectedProductDetails.price);
    this.selectedProductDetails.addOns.single.forEach((x,i) =>{
      if(x.selected == true){
        this.productDetailsTotalWithAddOn =Number (this.productDetailsTotalWithAddOn) + (this.countForCombo * Number(x.price))
      }
    })
    this.selectedProductDetails.addOns.multiple.forEach((x,i) =>{
      if(x.selected == true){
        this.productDetailsTotalWithAddOn =Number (this.productDetailsTotalWithAddOn) + (this.countForCombo * Number(x.price))
      }
    })
  }

  productDetailsTotalWithAddOn : number = 0;



  comboChanges(item ,innerItem , index , event){
    if(event.target.checked == true){
      item.selected = true;
    }
    else{
      item.selected = false;
    }
    item.comboProducts.forEach((x,i) =>{
        if(index != i){
          x.selected = false;
            }
          }
      )


    }



   

   addOnChangesSingle(item , index , event){
    this.productDetailsTotalWithAddOn = Number( this.selectedProductDetails.price);
      this.selectedProductDetails.addOns.single.forEach((x,i) =>{
        if(index != i){
          x.selected = false;
            }
            else{
              if(event.target.checked == true){
                // this.productDetailsTotalWithAddOn =Number(this.productDetailsTotalWithAddOn) + Number(x.price);
              }
            }
          }
      )
 
      this.selectedProductDetails.addOns.multiple.forEach((x,i) =>{
        if(x.selected == true){
          // this.productDetailsTotalWithAddOn = Number(this.productDetailsTotalWithAddOn) + Number(x.price);
        }
      })
      this.findTotalOfAddOns();
    }
  addOnChangesMulty(item , event){
    this.productDetailsTotalWithAddOn = Number( this.selectedProductDetails.price);
         this.selectedProductDetails.addOns.multiple.forEach((x,i) =>{
            if(x.selected == true){
              // this.productDetailsTotalWithAddOn = Number(this.productDetailsTotalWithAddOn) + Number( x.price);
            }
          })
         this.selectedProductDetails.addOns.single.forEach((x,i) =>{
            if(x.selected == true){
              // this.productDetailsTotalWithAddOn = Number(this.productDetailsTotalWithAddOn) + Number(x.price) ;
            }
          }
    )
    this.findTotalOfAddOns();

  }

 
  // addOnChangesSingle(item , index , event){
  //   if(event.target.checked == false){ 
  //     if (this.commonService.cartList.some((x) => x.ProductID == item.id)) {
  //       let indexOf;
  //       let element = this.commonService.cartList.find((x, i) => {
  //         if (x.ProductID == item.id) {
  //           indexOf = i;
  //         }
  //       });
  //       if(indexOf != undefined){
  //         this.commonService.cartList.splice(indexOf, 1);
  //         localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));

  //       }
  //     }
  //    }
  //   else {
  //     this.selectedProductDetails.addOns.single.forEach((x,i) =>{
  //       console.log(index, i)
  //       if(index != i){
  //         console.log('gere not enter')
  //         x.selected = false;
  //           let indexOf;
  //           let element = this.commonService.cartList.find((y, i) => {
  //             if (y.ProductID == x.id) {
  //               indexOf = i;
  //             }
  //           });
  //           if(indexOf != undefined){
  //             this.commonService.cartList.splice(indexOf, 1);
  //             localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));

  //           }
  //       }
  //       else{
  //           this.addToCart(item);
  //       }
  //     }
  //     )
  //     // console.log(this.selectedProductDetails.addOns)
  //     // let indexOf;
  //     // let element = this.commonService.cartList.find((y, i) => {
  //     //   if (y.ProductID == item.id) {
  //     //     indexOf = i;
  //     //   }
  //     // });
  //     // this.commonService.cartList.splice(indexOf, 1);
  //     // localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));



  //     // this.addToCart(item);

      
  //   }
  //   // this.commonService.findTotal();
  //   // this.setCartItemsCount();
  //   // this.setCartItemsCountForProducts();
  //   // this.setCartItemsCountForPromotedProducts();
  //   // localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  // }



  // addOnChangesMulty(item , event){
  //   // this.addOnTotalPrice = 0;


  //   if(event.target.checked == true){
  //      this.addToCart(item);
  //   }
  //   else{
  //     if (this.commonService.cartList.some((x) => x.ProductID == item.id)) {
  //       let indexOf;
  //       let element = this.commonService.cartList.find((x, index) => {
  //         if (x.ProductID == item.id) {
  //           indexOf = index;
  //         }
  //       });
  //       this.commonService.cartList.splice(indexOf, 1);
  //     }
  //   }
    



  //   this.commonService.findTotal();
  //   this.setCartItemsCount();
  //   this.setCartItemsCountForProducts();
  //   this.setCartItemsCountForPromotedProducts();
  //   localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  // }

  

  lang = Number(Config.lang)
  
  menuSlider: any = {
    loop: false,
    autoplay: false,
    responsiveClass: true,
    margin: 10,
    rtlClass: 'owl-rtl',
    navText: [
      '<i class="fa fa-chevron-left">',
      '<i class="fa fa-chevron-right">',
    ],
    rtl: false,
    responsive: {
      0: {
        items: 3,
        nav: true,
      },
      480: {
        items: 3,
        nav: true,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
    dots: false,
  };

  ngOnDestroy() {
    this.commonService.isRestuaretDetailsPage = false;
    this.commonService.deliveryIconPropretiesShow = false;
    document.getElementById('close-button').click();

  }

  themeId: number=0;


  brandId : number= 0;
  // oneSecondDelay : boolean = false;
  ngOnInit(): void {
    // setTimeout( ()=>{
    //   this.oneSecondDelay = true;
    //   }, 5000)

    if(localStorage.getItem('theme')){
      this.themeId = Number(atob(localStorage.getItem('theme')));
    }
    if(Config.lang == "1"){
      this.menuSlider.rtl =true;
    }
    this.commonService.isRestuaretDetailsPage = true;
    this.buildSignUpForm();
    this.route.queryParams.subscribe((queryParams) => {

      if(queryParams.brandId!= undefined){
        this.brandId = Number(atob(queryParams.brandId));
      }
      if (queryParams.storeId != undefined) {
        this.commonService.storeId = Number(queryParams.storeId);
        localStorage.setItem("storeId", queryParams.storeId);
        this.storeId = queryParams.storeId;
        this.getStoreDetails(queryParams.storeId ,queryParams.viewstate );
        // this.getStoreRatings(queryParams.storeId);
      
        this.getMenuDetails(queryParams.storeId);
        this.getPromotedProductes(queryParams.storeId);
        this.getProductsByStore(queryParams.storeId);
      }

      if(queryParams.menuCategoryId != undefined){
        this.queryParamsCateg = queryParams.menuCategoryId ;
      }

      if (queryParams.tableid > 0) {
        this.commonService.orderModes = 2;
        localStorage.setItem("orderModes", "2");

        this.forDine = true;

        localStorage.setItem("tableId", queryParams.tableid);
        this.commonService.tableId = Number(queryParams.tableid);
        this.commonService.officeId = 0;
        localStorage.removeItem("officeId");
      } else if (queryParams.viewstate == "qrcode") {
        this.commonService.orderModes = 1;
        localStorage.setItem("orderModes", "1");

        this.commonService.officeId = 0;
        this.commonService.tableId = 0;
        localStorage.removeItem("officeId");
        localStorage.removeItem("tableId");
      } else if (
        queryParams.officeMode != undefined &&
        queryParams.officeMode == "true"
      ) {
        this.commonService.orderModes = 3;
        localStorage.setItem("orderModes", "3");

        this.commonService.tableId = 0;
        localStorage.removeItem("tableId");
      }
      else if(queryParams.companyMode != undefined &&
        queryParams.companyMode == "true"){
          this.commonService.orderModes = 4;
          localStorage.setItem("orderModes", "4");
          this.commonService.tableId = 0;
          this.commonService.officeId = 0;
          localStorage.removeItem("officeId");
          localStorage.removeItem("tableId");
        }
      else {
        this.commonService.orderModes = 0;
        localStorage.setItem("orderModes", "0");

        this.commonService.tableId = 0;
        this.commonService.officeId = 0;
        localStorage.removeItem("officeId");
        localStorage.removeItem("tableId");
      }

     
    });
  }

  dishResult = false;
  showDishResult() {
    this.dishResult = true;
  }



  // getStoreRatings(storeId){
  //   this.dbService.methodGet("CustomerRatings/GetStoreCustomerRatings/" + storeId).subscribe(resdata =>{
  //      
  //   })
  // }
 
 review : string = ""
 customerNameReview : string = ""
 customerMobileReview : string = ""

  onReviewFormSubmit(){
    // if (localStorage.getItem("customer") !== null) {

    if(this.storeDetails.enableRating == false){
      this.storeRatingForm = 5;
    }
    if(this.storeRatingForm == 0){
      this.toastr.error("Please add rating");
      return;
    }
    let customerId : number =0
    if(this.commonService?.customer?.id){
       customerId = this.commonService?.customer?.id;
    }

    if(customerId == 0 && this.customerNameReview == ""){
      this.toastr.error("Please Customer Name");
      return;
    }
    if(customerId == 0 && this.customerNameReview == ""){
      this.toastr.error("Please Customer Mobile");
      return;
    }
    let body  = {
      Id : 0,
      StoreId : Number(this.storeId),
      CustomerId : customerId,
      Rating : this.storeRatingForm ,
      Review :  this.review,
      CustomerName  :  this.customerNameReview,
      CustomerPhone  :  this.customerMobileReview,

      SubmittedOn : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
      IsActive : true
    }
    this.dbService.methodPost("CustomerRatings" , body).subscribe(resdata =>{
      
      if(resdata.id > 0){
        this.toastr.success("Your review submitted successfully");
        this.review = "";
        this.customerNameReview = "";
        this.customerMobileReview = "";
        this.getCustomerRatingsForStore();
      }
    })
  // }
  // else{ 
  //   const modalRef =  this.modalService.open(SignupPopupComponent).result.then(
  //     (result) => {
  //     if(result == 1){
  //          const modalRef2 =  this.modalService.open(SignInPopupComponent);
  //     }
  //     if(result == 2){
  //          const modalRef3 =  this.modalService.open(LoginAsGuestPopupComponent);
  //     }
  //     },
  //   );

  // }

  }
  onReviewFormSubmitForProdct(){


    if (localStorage.getItem("customer") !== null) {


      if(this.storeRatingForm == 0){
        this.toastr.error("Please add rating");
        return;
      }
    let body  = {
      Id : 0,
      StoreId : Number(this.storeId),
      ProductId : this.selectedProductReview.id,
      CustomerId : this.commonService.customer.id,
      Rating : this.storeRatingForm ,
      Review :  this.review,
      IsActive : true, 

      SubmittedOn : this.datePipe.transform(new Date , 'yyyy-MM-dd')
    }

    
    this.dbService.methodPost("CustomerRatings" , body).subscribe(resdata =>{
      
      if(resdata.id > 0){
        this.toastr.success("Your review submitted successfully");
        this.review = ""
        this.getCustomerRatingsForProduct(this.selectedProductReview.id);
      }
    })

  }
  else{
    const modalRef =  this.modalService.open(SignupPopupComponent).result.then(
      (result) => {
      if(result == 1){
           const modalRef2 =  this.modalService.open(SignInPopupComponent);
      }
      if(result == 2){
           const modalRef3 =  this.modalService.open(LoginAsGuestPopupComponent);
      }
      },
    );
  }
  
  }


  onChange(){
  }
  selectedProductReview ;
  selectedProductReviewRating : number = 0;;

  review2Open(item){
    // if (localStorage.getItem("customer") !== null) {
      this.storeRatingForm = 0;
    this.selectedProductReview = item;
    this.selectedProductReviewRating = item.rating;
    this.modalService.open(this.review2);
    this.getCustomerRatingsForProduct(item.id)
    // }

  }

  review1Open(key ? : number){

    // if (localStorage.getItem("customer") !== null) {


    this.customerMobileReview = "";
    this.review  ="";
    this.customerNameReview ="";
      this.storeRatingForm = 0;
      this.modalService.open(this.review1);
      this.getCustomerRatingsForStore();
    // }
  }

  getPromotedProductes(storeId) {

    let url = 'Products/GetTrendingProducts/' + storeId;
    if(this.brandId && this.brandId > 0) url = 'Products/GetTrendingProductsbyMenugroupId/' + this.brandId; 
    this.dbService.methodGet(url).subscribe((resdata) => {
      this.promotedProducts = resdata;
      this.promotedProducts.forEach((x) => {
        x.rating = Math.round(x.rating);
        x.rating =(parseFloat(x.rating.toString()).toFixed(1));


        this.menuDetails.forEach((x) => {
          x.categ = x.menuCategory.replace(" ", "");
          x.categ = "y" + x.id.toString();
            if(x.variants&& x.variants.length > 0){
              x.variants.forEach(z =>{
                 z.loyaltyMenuGroups = x.loyaltyMenuGroups;
              })
            }
        });



      });

      this.setCartItemsCountForPromotedProducts();
      if (this.promotedProducts.length == 0) {
      }
    });
  }
  getProductsByStore(storeId) {
    let url = 'Products/GetProductsinStore/' + storeId
    if(this.brandId && this.brandId > 0) url = 'Products/GetProductsinStorebyMenuGroupId/' + this.brandId; 
    this.dbService.methodGet(url).subscribe((resdata) => {
      this.productList = resdata;
      this.setCartItemsCountForProducts();
    });
  }
  onProductSearch() {
    if (this.searchKey.length > 0) {
      this.searchMode = true;
      this.productSearchList = this.productList.filter((x) =>
        x.productName
          .toLocaleLowerCase()
          .includes(this.searchKey.toLocaleLowerCase())
      );
    } else {
      this.productSearchList = [];
      this.searchMode = false;
    }
  }
  deleteRating(id , tp , index, productId ?){
    let body = {
      Id 
      : id
    }
    
   
    let head ;
    let message ;
    if(Config.lang == "0"){
       head ='Please Confirm'
       message  ='Are you sure to remove your review ?'
     
    }
    else{
      head ='يرجى تأكيد'
      message  ='هل أنت متأكد من إزالة رأيك؟'

    }
    this.confirmationDialogService
      .confirm(head, message)
      .then((confirmed) =>
      {
        if(confirmed){
          this.dbService.methodPost("CustomerRatings/DeleteCustomerRating" ,body).subscribe(resdata =>{
            
            if(resdata.id > 0){
                this.toastr.success("Review removed successfully");
                if(tp == 0){
                  
                // this.storeRatingList.splice(index, 1);       
                this.getCustomerRatingsForStore();
                }
                if(tp == 1){
                  this.getCustomerRatingsForProduct(productId);
                  // this.productRatingList.splice(index, 1);       
                }
            }
           })
        }

    })
  }

 storeRatingList = []
  getCustomerRatingsForStore(){
    let body  = {
      StoreId : Number(this.storeId) ,
      CustomerId : 0,
      ProductId : 0 ,
      VariantId : 0
    }
    
    this.dbService.methodPost("CustomerRatings/GetCustomerActiveRatings", body).subscribe(resdata =>{
      
      this.storeRatingList = resdata;
      this.storeRatingList.forEach((x) => {
        x.rating = Math.round(x.rating);
        x.rating =(parseFloat(x.rating.toString()).toFixed(1));
      });
    })
  }
 productRatingList = []
  getCustomerRatingsForProduct(productId){
    let body  = {
      StoreId : 0 ,
      CustomerId : 0,
      ProductId : Number(productId) ,
      VariantId : 0
    }
    
    this.dbService.methodPost("CustomerRatings/GetCustomerActiveRatings", body).subscribe(resdata =>{
      
      this.productRatingList = resdata;
      this.productRatingList.forEach((x) => {
        x.rating = Math.round(x.rating);
        x.rating =(parseFloat(x.rating.toString()).toFixed(1));
      });
    })
  }

  latitude : number ;
  longitude : number;
  getCurrentLocation(){
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
          this.commonService.deliveryIconPropretiesShow = true;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        //    this.latitude = 25.218575478176035;
        // this.longitude =51.524300;
          this.getLocationDeliverableOrNot();

      });
  }
  }


  getLocationDeliverableOrNot(){
    let body = {
      StoreId : Number(this.storeId),
      Latitude : Number(this.latitude),
      Longitude : Number(this.longitude)
    }
    this.dbService.methodPost("StoreSettings/CheckOrderDeliverable",body).subscribe(resdata =>{
      // resdata.isDeliverable = true;
      if(resdata.isDeliverable == true && this.storeDetails.isClosed == false){
        this.commonService.deliverable = true;
      }
      else{
        this.commonService.deliverable = false;
      }

    }, error =>{
      this.commonService.deliverable =  false;
    })

  }

  getStoreDetails(storeId, viewstate) {
    this.storeService.getStoreDetailsbyId(storeId).subscribe((resdata) => {
      
      


      if(resdata.expired == true){
        this.router.navigate(['/expired']);
      }
      this.storeDetails = resdata;


      if(this.themeId == 1034){
        this.storeDetails.bannerUrl = '../../assets/mall/css/creper/images/banner-creper.jpg';
        this.storeDetails.settings.mostPromotedUrl = '../../assets/mall/css/creper/images/most-promoted-creper.png';
      }
      
      if(this.themeId == 1035){
        this.storeDetails.bannerUrl = '../../assets/mall/css/pepper/images/banner-pepper.jpg';
        this.storeDetails.settings.mostPromotedUrl = '../../assets/mall/css/pepper/images/most-promoted-pepper.png';
      }




      // this.storeDetails.showTableStatus = true;
      // this.storeDetails.canOrder = false;
      // this.storeDetails.enableRating = false;
      // this.storeDetails.enableFeedback = true;
      // this.storeDetails.showVideoBanner = false;
      // this.storeDetails.settings.enableOfferCustomer = false;
        //  this.storeDetails.welcomeFlag = false;
      if(this.storeDetails?.settings?.enableOfferCustomer == true){
        const modalRef3 =  this.modalService.open(OfferSubscriptionPopupComponent);
        modalRef3.componentInstance.storeId = this.storeId;
        modalRef3.result.then((result: any) => {
          if(this.storeDetails.enableInstagramPopUp){
            const modalRef =  this.modalService.open(InstagramFollowPopupComponent, {size:'sm',centered:true});
            modalRef.componentInstance.storeDetails = this.storeDetails;
          }
          if (this.storeDetails.welcomeFlag == true) {
            document.getElementById("openModalButton").click();
          }
        }, (reason: any) => {
        });
      }
      else {
      if(this.storeDetails.enableInstagramPopUp){
        const modalRef =  this.modalService.open(InstagramFollowPopupComponent, {size:'sm',centered:true});
        modalRef.componentInstance.storeDetails = this.storeDetails;
      }
      if (this.storeDetails.welcomeFlag == true) {
        document.getElementById("openModalButton").click();
      }
      }


      if(this.storeDetails.isDelivery == true && this.storeDetails.enableGoogleMap == true){
        this.commonService.deliveryIconPropretiesShow = true;
        this.getCurrentLocation();
      }
      else{
        this.commonService.deliveryIconPropretiesShow = false;
    } 
      
      if(this.storeDetails.enableMultiLanguage != true){
        this.commonService.showMultyLang = false;
      }
     



      if(this.storeDetails.disableTableOrder == true && this.commonService.tableId > 0){
         this.storeDetails.canOrder = false;
      }
      // this.storeDetails.themeId = 2;

      this.storeRating = Math.round(this.storeDetails.rating);
      // this.storeDetails.settings.ShowImageMenu = true;
      this.storeRating = (parseFloat(this.storeRating.toString()).toFixed(1));


     
      if (
        // this.commonService.officeId > 0 &&
        // this.storeDetails.offers.length > 0
        this.storeDetails.storeOffer != null

      ) {
        if (!this.commonService.onceOrderPopupShowed) {
          this.commonService.onceOrderPopupShowed = true;
          this.modalService.open(this.offerContent);
        }
      }
    });
  }

  getMenuDetails(storeId) {
    this.spinner.show();

    let url = 'Products/GetStoreProducts/' + storeId;
    if(this.brandId && this.brandId > 0) url = 'Products/GetStoreProductsbyMenuGroupId/' + this.brandId;
    this.dbService.methodGet(url).subscribe((resdata) => {
      this.spinner.hide();
      this.menuDetails = resdata.filter(x =>  x.isAddOn == false);      
      this.menuDetails.forEach((x) => {
        x.categ = x.menuCategory.replace(" ", "");
        x.categ = "y" + x.id.toString();

        x.products.forEach( (y:any) =>{
          y.loyaltyMenuGroups = x.loyaltyMenuGroups;
          if(y.variants&& y.variants.length > 0){
            y.variants.forEach(z =>{
               z.loyaltyMenuGroups = x.loyaltyMenuGroups;
            })
          }
        })
      });

      
      if(this.queryParamsCateg > 0){
        setTimeout( ()=>{
          document.getElementById("menu-oney" + this.queryParamsCateg).click();
          }, 300)
      }

      this.setCartItemsCount();
    });
  }

  setCartItemsCount() {
    this.menuDetails.forEach((x) => {
      x.products.forEach((x) => {
        if (
          this.commonService.cartList.some(
            (y) => y.ProductID == x.id && y.VariantId == 0
          )
        ) {
          let element = this.commonService.cartList.find(
            (y) => y.ProductID == x.id
          );
          x.cartItem = element.Quantity;
        } else {
          x.cartItem = 0;
        }

        // let singleArray= []
        // if(x.addOns.single){
        //   singleArray = x.addOns.single;
        //   singleArray.forEach(z =>{
        //     if (
        //           this.commonService.cartList.some(
        //             (y) => y.ProductID == z.id 
        //           )
        //         ) {
        //           let element = this.commonService.cartList.find(
        //             (y) => y.ProductID == z.id
        //           );
        //           z.selected = true;
        //         } else {
        //           z.selected = false;
        //         }
        //   })
        // }


        // let multipleArray= []
        // if(x.addOns.multiple){
        //   multipleArray = x.addOns.multiple;
        //   multipleArray.forEach(z =>{
        //     if (
        //           this.commonService.cartList.some(
        //             (y) => y.ProductID == z.id 
        //           )
        //         ) {
        //           let element = this.commonService.cartList.find(
        //             (y) => y.ProductID == z.id
        //           );
        //           z.selected = true;
        //         } else {
        //           z.selected = false;
        //         }
        //   })
        // }

       

       
       
      });
    });
  
  }

  setCartItemsCountForProducts() {
    this.productList.forEach((x) => {
      if (
        this.commonService.cartList.some(
          (y) => y.ProductID == x.id && y.VariantId == 0
        )
      ) {
        let element = this.commonService.cartList.find(
          (y) => y.ProductID == x.id
        );
        x.cartItem = element.Quantity;
      } else {
        x.cartItem = 0;
      }
    });
  }
  setCartItemsCountForPromotedProducts() {
    this.promotedProducts.forEach((x) => {
      if (
        this.commonService.cartList.some(
          (y) => y.ProductID == x.id && y.VariantId == 0
        )
      ) {
        let element = this.commonService.cartList.find(
          (y) => y.ProductID == x.id
        );
        x.cartItem = element.Quantity;
      } else {
        x.cartItem = 0;
      }



      // let singleArray= []
      //   if(x.addOns.single){
      //     singleArray = x.addOns.single;
      //     singleArray.forEach(z =>{
      //       if (
      //             this.commonService.cartList.some(
      //               (y) => y.ProductID == z.id 
      //             )
      //           ) {
      //             let element = this.commonService.cartList.find(
      //               (y) => y.ProductID == z.id
      //             );
      //             z.selected = true;
      //           } else {
      //             z.selected = false;
      //           }
      //     })
      //   }


      //   let multipleArray= []
      //   if(x.addOns.multiple){
      //     multipleArray = x.addOns.multiple;
      //     multipleArray.forEach(z =>{
      //       if (
      //             this.commonService.cartList.some(
      //               (y) => y.ProductID == z.id 
      //             )
      //           ) {
      //             let element = this.commonService.cartList.find(
      //               (y) => y.ProductID == z.id
      //             );
      //             z.selected = true;
      //           } else {
      //             z.selected = false;
      //           }
      //     })
      //   }

    });



    
  }

  toggleMenuBook(event) {
    let allMenu = document.querySelectorAll(".nav-link");
    if(event.target.id != 'cat-image'){
      event.target.classList.add("active");
    }
    for (var i = 0; i < allMenu.length; i++) {
      allMenu[i].classList.remove("active");
    }

    // document.getElementById('cat-image').classList.remove("active"); 
  }

  addToCart(product , key ?) {
    if (localStorage.getItem("cart") != null) {
      this.commonService.cartList = JSON.parse(localStorage.getItem("cart"));
    }
    if (this.commonService.cartList.find((x) => x.StoreID != this.storeId)) {


      let head ;
      let message ;
      if(Config.lang == "0"){
         head ='Please Confirm'
         message  ='Are you sure to clear you existing cart with other restaurent ??'
       
      }
      else{
        head ='يرجى تأكيد'
        message  ='هل أنت متأكد من مسح عربتك الحالية مع مطعم آخر ؟؟'
  
      }
      this.confirmationDialogService
        .confirm(head, message)


      // this.confirmationDialogService.confirm('Please Confirm..', 'Are you sure to clear you existing cart with other restaurent ??')
      .then((confirmed) =>
      {
        if(confirmed){
          this.commonService.cartList = [];
          this.toCart(product);        
        }
        else{
          this.toastr.error("Not Added, Please Clear you existing cart");
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



    } else {
      this.toCart(product);
    }
    // }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }
  addToCartAddOn(product , key ?) {
    if (localStorage.getItem("cart") != null) {
      this.commonService.cartList = JSON.parse(localStorage.getItem("cart"));
    }
    if (this.commonService.cartList.find((x) => x.StoreID != this.storeId)) {
      let head ;
      let message ;
      if(Config.lang == "0"){
         head ='Please Confirm'
         message  ='Are you sure to clear you existing cart with other restaurent ??'
       
      }
      else{
        head ='يرجى تأكيد'
        message  ='هل أنت متأكد من مسح عربتك الحالية مع مطعم آخر ؟؟'
  
      }
      this.confirmationDialogService
        .confirm(head, message)
      // this.confirmationDialogService.confirm('Please Confirm..', 'Are you sure to clear you existing cart with other restaurent ??')
      .then((confirmed) =>
      {
        if(confirmed){
          this.commonService.cartList = [];
          this.toCartAddOn(product,key);        
        }
        else{
          this.toastr.error("Not Added, Please Clear you existing cart");
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



    } else {
      this.toCartAddOn(product,key);
    }
    // }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }


  addToCartCombo(product , key ?) {




    let allSelected : boolean = false;
    if((this.selectedProductDetails.comboMenus.find(x => x.selected != true && x.isActive == true))){
      allSelected = false;
    }
    else{
      allSelected = true;
    }


    if(!allSelected){
      this.toastr.error(
        "Choose any one from each combo menu"
      );
      return;
    }


    if (localStorage.getItem("cart") != null) {
      this.commonService.cartList = JSON.parse(localStorage.getItem("cart"));
    }
    if (this.commonService.cartList.find((x) => x.StoreID != this.storeId)) {
      let head ;
      let message ;
      if(Config.lang == "0"){
         head ='Please Confirm'
         message  ='Are you sure to clear you existing cart with other restaurent ??'
       
      }
      else{
        head ='يرجى تأكيد'
        message  ='هل أنت متأكد من مسح عربتك الحالية مع مطعم آخر ؟؟'
  
      }
      this.confirmationDialogService
        .confirm(head, message)
      // this.confirmationDialogService.confirm('Please Confirm..', 'Are you sure to clear you existing cart with other restaurent ??')
      .then((confirmed) =>
      {
        if(confirmed){
          this.commonService.cartList = [];
          this.toCartCombo(product,key);        
        }
        else{
          this.toastr.error("Not Added, Please Clear you existing cart");
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



    } else {
      this.toCartCombo(product,key);
    }
    // }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }


  addToCartAddComboMulty(product , key ?) {

    let uniqueId = Math.random().toString(36).substr(2, 9);
    this.selectedProductDetails.LinkId = uniqueId;
    // this.addToCartAddOn(selectedProduct);
    let array = [];

    let singleArray= []
    if(this.selectedProductDetails.addOns.single){
    singleArray = this.selectedProductDetails.addOns.single;
    singleArray.forEach(x =>{
      if(x.selected == true){
        x.LinkId = uniqueId;
        array.push(x);
      }
    })
  }
    let multipleArray= []
    if(this.selectedProductDetails.addOns.multiple){
    multipleArray = this.selectedProductDetails.addOns.multiple;
    multipleArray.forEach(x =>{
      if(x.selected == true){
        x.LinkId = uniqueId;
        array.push(x);
      }
    })
  }
    
    array.forEach(x =>{
      this.addToCartAddOn(x, 2);
    })


        document.getElementById('close-pop').click();
         singleArray= []
        if(this.selectedProductDetails.addOns.single){
        singleArray = this.selectedProductDetails.addOns.single;
        singleArray.forEach(x =>{
          x.selected =false;
        })
      }
         multipleArray= []
        if(this.selectedProductDetails.addOns.multiple){
        multipleArray = this.selectedProductDetails.addOns.multiple;
        multipleArray.forEach(x =>{
          x.selected =false;

        })
      }





    let allSelected : boolean = false;
    if((this.selectedProductDetails.comboMenus.find(x => x.selected != true && x.isActive == true))){
      allSelected = false;
    }
    else{
     
      allSelected = true;
    }


    if(!allSelected){
      this.toastr.error(
        "Choose any one from each combo menu"
      );
      return;
    }


    if (localStorage.getItem("cart") != null) {
      this.commonService.cartList = JSON.parse(localStorage.getItem("cart"));
    }
    if (this.commonService.cartList.find((x) => x.StoreID != this.storeId)) {
      let head ;
      let message ;
      if(Config.lang == "0"){
         head ='Please Confirm'
         message  ='Are you sure to clear you existing cart with other restaurent ??'
       
      }
      else{
        head ='يرجى تأكيد'
        message  ='هل أنت متأكد من مسح عربتك الحالية مع مطعم آخر ؟؟'
  
      }
      this.confirmationDialogService
        .confirm(head, message)
      // this.confirmationDialogService.confirm('Please Confirm..', 'Are you sure to clear you existing cart with other restaurent ??')
      .then((confirmed) =>
      {
        if(confirmed){
          this.commonService.cartList = [];
          this.toCartAddOnComboMulty(product,key);        
        }
        else{
          this.toastr.error("Not Added, Please Clear you existing cart");
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



    } else {
      this.toCartAddOnComboMulty(product,key);
    }
    // }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }


  addToCartVariant(product) {
    console.log(product);
    if (localStorage.getItem("cart") != null) {
      this.commonService.cartList = JSON.parse(localStorage.getItem("cart"));
    }
    if (this.commonService.cartList.find((x) => x.StoreID != this.storeId)) {
      // if (confirm("Are you sure to clear you existing cart with other restaurent ?") ) {
      //   this.commonService.cartList = [];
      //   this.toCartVariant(product);
      // } else {
      //   this.toastr.error("Not Added, Please Clear you existing cart");
      // }
      let head ;
      let message ;
      if(Config.lang == "0"){
         head ='Please Confirm'
         message  ='Are you sure to clear you existing cart with other restaurent ??'
       
      }
      else{
        head ='يرجى تأكيد'
        message  ='هل أنت متأكد من مسح عربتك الحالية مع مطعم آخر ؟؟'
  
      }
      this.confirmationDialogService
        .confirm(head, message)
      // this.confirmationDialogService.confirm('Please Confirm..', 'Are you sure to clear you existing cart with other restaurent ?"')
      .then((confirmed) =>
      {
        if(confirmed){
          this.commonService.cartList = [];
          this.toCartVariant(product);        
        }
        else{
          this.toastr.error("Not Added, Please Clear you existing cart");
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));


    } else {
      this.toCartVariant(product);
    }
    // }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }

  toCartVariant(product) {
    
    var date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    var todayISOString = new Date().toISOString();
    if (
      this.commonService.cartList.some(
        (x) => x.ProductID == product.productId && x.VariantId == product.id
      )
    ) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == product.productId && x.VariantId == product.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
      product.cartItem = element.Quantity;
    } else {
      const order = new Order();
      order.ProductID = product.productId;
      order.Quantity = 1;
      order.SubmitBy = "1";
      order.SubmitOn = latest_date;
      order.Price = Number(product.price);
      order.VariantId = product.id;
      order.loyaltyMenuGroups = product.loyaltyMenuGroups;
      order.ProductName =
        this.productList.find((x) => x.id === product.productId).productName +
        " / " +
        product.variant;
      order.ProductNameAr =
        this.productList.find((x) => x.id === product.productId).productNameAr +
        " / " +
        product.variantAr;
      order.TotalPrice = order.Quantity * order.Price;
      product.cartItem = 1;
      order.StoreID =Number(this.storeId);
      order.addTime = new Date();
      this.commonService.cartList.push(order);
      this.commonService.storeDetails = this.storeDetails;
      localStorage.setItem("storeDetails", JSON.stringify(this.storeDetails));
      this.toastr.success("Added to cart");
      this.commonService.findTotal();
      localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      this.setCartItemsCount();
      this.setCartItemsCountForProducts();
      this.setCartItemsCountForPromotedProducts();
    }
  }
  toCart(product) {
    console.log('produc t')
    var date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    var todayISOString = new Date().toISOString();
    if (this.commonService.cartList.some((x) => x.ProductID == product.id && x.LinkId == product.LinkId)) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == product.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
      product.cartItem = element.Quantity;
    } else {
      const order = new Order();
      order.ProductID = product.id;
      order.Quantity = 1;
      order.SubmitBy = "1";
      order.SubmitOn = latest_date;
      order.Price = Number(product.price);
      order.VariantId = 0;
      order.LinkId = product.LinkId;
      order.IsAddOn = product.isAddOn
      order.loyaltyMenuGroups = product.loyaltyMenuGroups;
      order.ProductName = product.productName;
      order.ProductNameAr = product.productNameAr;
      order.TotalPrice = order.Quantity * order.Price;
      product.cartItem = 1;
      order.StoreID = Number(this.storeId);
      order.addTime = new Date();
      this.commonService.cartList.push(order);
      this.commonService.storeDetails = this.storeDetails;
      localStorage.setItem("storeDetails", JSON.stringify(this.storeDetails));
        this.toastr.success("Added to cart");
      this.commonService.findTotal();
      localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      this.setCartItemsCount();
      this.setCartItemsCountForProducts();
      this.setCartItemsCountForPromotedProducts();
    }
  }
  toCartAddOnComboMulty(product , key ?) {
    let comboDetails =[];
    (product.comboMenus).forEach(x =>{
      (x.comboProducts).forEach(y =>{
        if(y.selected == true){
          let element= {
            ProductId : y.productId,
            ComboProductId : y.id,
            Quantity : 1,
            ComboProduct : y.comboProduct,
            ComboProductAr : y.comboProductAr,

          } 
          comboDetails.push(element);
        }
      })
    })
  
    var date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    var todayISOString = new Date().toISOString();
    if (this.commonService.cartList.some((x) => x.ProductID == product.id && x.LinkId == product.LinkId)) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == product.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
      product.cartItem = element.Quantity;
    } else {
      const order = new Order();
      order.ProductID = product.id;
      order.Quantity = this.countForCombo;
      order.SubmitBy = "1";
      order.SubmitOn = latest_date;
      order.Price = Number(product.price);
      order.VariantId = 0;
      order.LinkId = product.LinkId;
      order.IsAddOn = product.isAddOn;
      order.loyaltyMenuGroups = product.loyaltyMenuGroups;
      order.IsCombo = true;
      order.comboDetails = comboDetails;
      order.ProductName = product.productName;
      order.ProductNameAr = product.productNameAr;
      order.TotalPrice = order.Quantity * order.Price;
      order.TotalPrice = order.Quantity * order.Price;

      product.cartItem = 1;
      order.StoreID = Number(this.storeId);
      order.addTime = new Date();
      this.commonService.cartList.push(order);
      this.commonService.storeDetails = this.storeDetails;
      localStorage.setItem("storeDetails", JSON.stringify(this.storeDetails));
      if(key == undefined){
        this.toastr.success("Added to cart");
        document.getElementById('close-pop-combo-addon-multy').click();

      }
      this.commonService.findTotal();
      localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      this.setCartItemsCount();
      this.setCartItemsCountForProducts();
      this.setCartItemsCountForPromotedProducts();
    }
  }


  toCartAddOn(product , key ?) {
    console.log(product);
    var date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    var todayISOString = new Date().toISOString();
    if (this.commonService.cartList.some((x) => x.ProductID == product.id && x.LinkId == product.LinkId)) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == product.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
      product.cartItem = element.Quantity;
    } else {
      const order = new Order();
      order.ProductID = product.id;
      order.Quantity = this.countForAddOn;
      if(key != undefined){
      order.Quantity = this.countForCombo;
      }
      order.SubmitBy = "1";
      order.SubmitOn = latest_date;
      order.Price = Number(product.price);
      order.VariantId = 0;
      order.LinkId = product.LinkId;
      order.IsAddOn = product.isAddOn
      order.ProductName = product.productName;
      order.ProductNameAr = product.productNameAr;
      if(order.IsAddOn == false){
        order.loyaltyMenuGroups = product.loyaltyMenuGroups;
      }
      else {
        order.loyaltyMenuGroups = [];

      }

      order.TotalPrice = order.Quantity * order.Price;
      product.cartItem = 1;
      order.StoreID = Number(this.storeId);
      order.addTime = new Date();
      this.commonService.cartList.push(order);
      this.commonService.storeDetails = this.storeDetails;
      localStorage.setItem("storeDetails", JSON.stringify(this.storeDetails));
      if(key == undefined){
        this.toastr.success("Added to cart");
      }
      this.commonService.findTotal();
      localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      this.setCartItemsCount();
      this.setCartItemsCountForProducts();
      this.setCartItemsCountForPromotedProducts();
    }
  }


  toCartCombo(product , key ?) {
    let comboDetails =[];
    (product.comboMenus).forEach(x =>{
      (x.comboProducts).forEach(y =>{
        if(y.selected == true){
          let element= {
            ProductId : y.productId,
            ComboProductId : y.id,
            Quantity : 1,
            ComboProduct : y.comboProduct,
            ComboProductAr : y.comboProductAr,
          } 
          comboDetails.push(element);
        }
      })
    })
  
    var date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    var todayISOString = new Date().toISOString();
    if (this.commonService.cartList.some((x) => x.ProductID == product.id && x.LinkId == product.LinkId)) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == product.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
      product.cartItem = element.Quantity;
    } else {
      const order = new Order();
      order.ProductID = product.id;
      order.Quantity = this.countForCombo;
      order.SubmitBy = "1";
      order.SubmitOn = latest_date;
      order.Price = Number(product.price);
      order.VariantId = 0;
      order.LinkId =  '';
      order.IsAddOn = false;
      order.IsCombo = true;
      order.comboDetails = comboDetails;
      order.ProductName = product.productName;
      order.ProductNameAr = product.productNameAr;
      order.loyaltyMenuGroups = product.loyaltyMenuGroups;

      order.TotalPrice = order.Quantity * order.Price;
      order.TotalPrice = order.Quantity * order.Price;

      product.cartItem = 1;
      order.StoreID = Number(this.storeId);
      order.addTime = new Date();
      this.commonService.cartList.push(order);
      this.commonService.storeDetails = this.storeDetails;
      localStorage.setItem("storeDetails", JSON.stringify(this.storeDetails));
      if(key == undefined){
        this.toastr.success("Added to cart");
        document.getElementById('close-pop-combo').click();

      }
      this.commonService.findTotal();
      localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      this.setCartItemsCount();
      this.setCartItemsCountForProducts();
      this.setCartItemsCountForPromotedProducts();
    }
  }


  

  onMinusQty(item) {
    if (item.cartItem != 1) {
      item.cartItem = item.cartItem - 1;
      if (this.commonService.cartList.some((x) => x.ProductID == item.id)) {
        let element = this.commonService.cartList.find(
          (x) => x.ProductID == item.id
        );
        element.Quantity = element.Quantity - 1;
        element.TotalPrice = element.Quantity * element.Price;
      }
    } else {
      item.cartItem = 0;
      if (this.commonService.cartList.some((x) => x.ProductID == item.id)) {
        let indexOf;
        let element = this.commonService.cartList.find((x, index) => {
          if (x.ProductID == item.id) {
            indexOf = index;
          }
        });
        this.commonService.cartList.splice(indexOf, 1);
      }
    }
    this.commonService.findTotal();
    this.setCartItemsCount();
    this.setCartItemsCountForProducts();
    this.setCartItemsCountForPromotedProducts();
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }
  onMinusQtyVariant(item) {
    if (item.cartItem != 1) {
      item.cartItem = item.cartItem - 1;
      if (
        this.commonService.cartList.some(
          (x) => x.ProductID == item.productId && x.VariantId == item.id
        )
      ) {
        let element = this.commonService.cartList.find(
          (x) => x.ProductID == item.productId && x.VariantId == item.id
        );
        element.Quantity = element.Quantity - 1;
        element.TotalPrice = element.Quantity * element.Price;
      }
    } else {
      item.cartItem = 0;
      if (
        this.commonService.cartList.some(
          (x) => x.ProductID == item.productId && x.VariantId == item.id
        )
      ) {
        let indexOf;
        let element = this.commonService.cartList.find((x, index) => {
          if (x.ProductID == item.productId && x.VariantId == item.id) {
            indexOf = index;
          }
        });
        this.commonService.cartList.splice(indexOf, 1);
      }
    }
    this.commonService.findTotal();

    this.setCartItemsCount();
    this.setCartItemsCountForProducts();
    this.setCartItemsCountForPromotedProducts();

    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }

  onPlusQty(item) {
    item.cartItem = item.cartItem + 1;
    if (this.commonService.cartList.some((x) => x.ProductID == item.id)) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == item.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
    }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
    this.commonService.findTotal();
    this.setCartItemsCount();
    this.setCartItemsCountForProducts();
    this.setCartItemsCountForPromotedProducts();
  }
  onPlusQtyVariant(item) {
    item.cartItem = item.cartItem + 1;
    if (
      this.commonService.cartList.some(
        (x) => x.ProductID == item.productId && x.VariantId == item.id
      )
    ) {
      let element = this.commonService.cartList.find(
        (x) => x.ProductID == item.productId && x.VariantId == item.id
      );
      element.Quantity = element.Quantity + 1;
      element.TotalPrice = element.Quantity * element.Price;
    }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
    this.commonService.findTotal();
    this.setCartItemsCount();
    this.setCartItemsCountForProducts();
    this.setCartItemsCountForPromotedProducts();
  }

  // open(content) {
  //   this.modalService
  //     .open(content, { ariaLabelledBy: "modal-basic-title" })
  //     .result.then(
  //       (result) => {
  //         this.closeResult = `Closed with: ${result}`;
  //       },
  //       (reason) => {
  //         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //         if (this.closeResult == "Dismissed with: 1") {
  //           this.modalService.open(this.signIn);
  //         }
  //         if (this.closeResult == "Dismissed with: 2") {
  //           this.modalService.open(this.asGuest);
  //         }
  //       }
  //     );
  // }
  // signUp() {
  //   this.authService.addCustomer(this.signUpForm.value).subscribe((resdata) => {
  //     if (resdata.id > 0) {
  //       this.toastr.success("Succesfully Registered . You Can Login");
  //       this.modalService.dismissAll();
  //       this.modalService.open(this.signIn);
  //       this.commonService.tempMob = this.signUpForm.controls.Phone.value;
  //     }
  //   });
  // }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  get f() {
    return this.signUpForm.controls;
  }

  buildSignUpForm() {
    this.signUpForm = this.fb.group({
      StoreID: [0],
      Name: ["", Validators.required],
      Phone: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.maxLength(11),
        ],
      ],
      CountryCode: ["974"],
      Email: [""],
      Address: [""],
      Location: [""],
      SubmitBy: [""],
      SubmitOn: [new Date()],
      CustomerName: [""],
    });
  }
  onCartClick() {
    if (this.commonService.cartList.length > 0) {
      this.router.navigate(["/cart"]);
    } else {
      this.toastr.error(
        "Sorry ! No Items in your cart ! please add atleast one"
      );
    }
  }
  safeURL
  description =''
  descriptionAr =''
  
  getProductDetailsForPopup(item) {
  // item.videoUrl ='https://www.youtube.com/embed/Q7qWsp7oBsQ';
  if(item.videoUrl != null && item.videoUrl != undefined && item.videoUrl.length > 0){
    item.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(item.videoUrl);
  }

    this.dishResult = false;
    this.countForAddOn =1;
    this.countForCombo = 1;
    this.selectedProductDetails = item;
  
   

    
    // this.description = "<div >YOUR CHOICE OF 1 SALAD <p>(Avocado Salad, Burrata Salad) </p></div><div >YOUR CHOICE OF 1 MAIN COURSE<p>(Grilled Salmon, Stuffed Chicken Supreme, Truffle Mushroom Risotto)</p> </div> <div >YOUR CHOICE OF 1 DESSERT <p>(Red Velvet, Chocolate Hazelnut) </p></div><div >1 SOFT BEVERAGE INCLUDED<p >(Mirinda, 7Up, Diet 7Up, Pepsi, Diet Pepsi)</p></div>";
    // this.descriptionAr = "<div >اختيارك من 1 سلطة <p> (سلطة الأفوكادو ، سلطة بوراتا) </p></div><div >اختيارك من طبق رئيسي واحد<p> (سلمون مشوي ، دجاج سوبريم محشي ، كمأة مشروم ريزوتو)</p> </div> <div > اختيارك من 1 حلوى <p>(ريد فيلفيت ، شوكولاتة بالبندق) </p></div><div >1 مشروب غازي متضمن<p >(ميراندا ، دايت سفن اب، سفن اب، دايت بيبسي، بيبسي)</p></div>";




    this.description = this.selectedProductDetails.description;
    this.descriptionAr = this.selectedProductDetails.descriptionAr;

    // console.log(This.description)
    // // (this.description).replace("\n", "<br>");

    if(this.selectedProductDetails?.comboMenus){
      (this.selectedProductDetails?.comboMenus).forEach(x =>{
        x.selected = false;
        if(x.comboProducts.length > 0){
           (x.comboProducts).forEach(y =>{
             y.selected = false;
           })
        }

        if((x.comboProducts).length == 1){
           x.comboProducts[0].selected = true;
           x.selected=true;
        }
      })
    }
    

    this.productDetailsTotalWithAddOn = item.price;
    if (this.selectedProductDetails.variants.length > 0) {
      this.selectedProductDetails.variants.forEach((x) => {
        if (
          this.commonService.cartList.some(
            (y) => y.ProductID == x.productId && y.VariantId == x.id
          )
        ) {
          let element = this.commonService.cartList.find(
            (y) => y.ProductID == x.productId && y.VariantId == x.id
          );
          x.cartItem = element.Quantity;
        } else {
          x.cartItem = 0;
        }
      });
    }
    // this.storeService.getProductDetailsById(productId).subscribe((resdata) => {
    //   this.selectedProductDetails = resdata;
    //   
    // });
  }

  
}
