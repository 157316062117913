import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxStarRatingModule } from 'ngx-star-rating';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RestaurantsDetailComponent } from './restaurants-detail/restaurants-detail.component';
import { CartComponent } from './cart/cart.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormBuilder , FormsModule , ReactiveFormsModule } from '@angular/forms';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ThumbnailSliderComponent } from './thumbnail-slider/thumbnail-slider.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { SignupComponent } from './auth/signup/signup.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { PopupsComponent } from './popups/popups.component';
import { ToastrModule } from 'ngx-toastr';
import { ClickOutsideModule } from 'ng-click-outside';
import { GuestLoginComponent } from './auth/guest-login/guest-login.component';
import { RestaurentWelcomePageComponent } from './restaurants-detail/restaurent-welcome-page/restaurent-welcome-page.component';
import { RestaurentServicesComponent } from './views/restaurent-services/restaurent-services.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from './shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SignInPopupComponent } from './shared-popups/sign-in-popup/sign-in-popup.component';
import { SignupPopupComponent } from './shared-popups/signup-popup/signup-popup.component';
import { LoginAsGuestPopupComponent } from './shared-popups/login-as-guest-popup/login-as-guest-popup.component';
import { WishlistComponent } from './views/wishlist/wishlist.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { StoreRatingPopupComponent } from './shared-popups/store-rating-popup/store-rating-popup.component';
import { ProductRatingPopupComponent } from './shared-popups/product-rating-popup/product-rating-popup.component';
import { AddSuggestionPopupComponent } from './shared-popups/add-suggestion-popup/add-suggestion-popup.component';
import { AgmCoreModule } from '@agm/core';
import { DeliveryMapPopupComponent } from './cart/delivery-map-popup/delivery-map-popup.component';
import { ChooseSavedAddressPopupComponent } from './cart/choose-saved-address-popup/choose-saved-address-popup.component';
import { OfferSubscriptionPopupComponent } from './shared-popups/offer-subscription-popup/offer-subscription-popup.component';
import { ChooseBranchPopupComponent } from './cart/choose-branch-popup/choose-branch-popup.component';
import { InstagramFollowPopupComponent } from './restaurants-detail/instagram-follow-popup/instagram-follow-popup.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RestaurantsDetailComponent,
    CartComponent,
    SearchBoxComponent,
    ThumbnailSliderComponent,
    OrderHistoryComponent,
    SplashScreenComponent,
    SignupComponent,
    SignInComponent,
    PopupsComponent,
    GuestLoginComponent,
    RestaurentWelcomePageComponent,
    RestaurentServicesComponent,
    SignInPopupComponent,
    SignupPopupComponent,
    LoginAsGuestPopupComponent,
    WishlistComponent,
    StoreRatingPopupComponent,
    ProductRatingPopupComponent,
    AddSuggestionPopupComponent,
    DeliveryMapPopupComponent,
    ChooseSavedAddressPopupComponent,
    OfferSubscriptionPopupComponent,
    ChooseBranchPopupComponent,
    InstagramFollowPopupComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule, 
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    NgxStarRatingModule,
    SharedModule,
    NgxSpinnerModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  }),
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyAYPDqM-M0rbygbicD-HccXQHe71oHt3TA',
    libraries: ['places']
  }),

    ToastrModule.forRoot(
      {
        positionClass: 'toast-bottom-center',
        progressAnimation : 'increasing',
        timeOut : 3000
      }
    ), // ToastrModule added
  ],     
  exports:[SignInComponent, SignInPopupComponent, ThumbnailSliderComponent , SearchBoxComponent],
  providers: [DatePipe,FormBuilder, NgbActiveModal],
  entryComponents:[SignInPopupComponent],
  bootstrap: [AppComponent]
}) 
export class AppModule { }
 