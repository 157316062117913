<div class="modal-header">
    <h4 class="modal-title cart-popup-txt">{{ title }}</h4>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true" class="pop-up-close-bt">&times;</span>
      </button>
    </div>
    <div class="modal-body cart-popup-txt" style="font-size: 16px;">
      {{ message }}
    </div>
    <div class="clear"></div>
    <div class="modal-footer" >
      <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
      <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
    </div>