
<div *ngIf="isLoaded">

   <!-- <div class="page-banner p-relative smoothscroll" id="menu">
      <img  [src]="storeDetails?.bannerUrl" class="img-fluid full-width" alt="banner">
      <div class="restaurent-logo">
                  <img  [src]="storeDetails?.logoUrl" width="150" height="150"></div>
      
  </div> -->
   
<section class="restaurant-detailed-banner" >
    <div class="text-center">
       <div class="container" style="position: relative;">
          
   
       </div>
       <div  *ngIf="!storeDetails?.bannerUrl" style="background-image: url(../assets/img/restaurant-landing.jpg); position: relative;" class="img-fluid cover">
          <div class="restaurant-detailed-header__docked">
             <div class="text-white mb-0 restaurant-detailed-ratings">
                
             </div>
          </div>
       </div>
       <div *ngIf="storeDetails?.bannerUrl" style="background-image: url({{storeDetails?.bannerUrl}}); position: relative;" class="img-fluid cover">
          <div class="restaurant-detailed-header__docked">
             <div class="text-white mb-0 restaurant-detailed-ratings">
                
             </div>
          </div>
       </div>
    </div>
    <div [class.restaurant-detailed-header] = "storeDetails?.themeId == null || storeDetails?.themeId == 0">
       
       <div class="container">
          <div class="row d-flex align-items-end">
             <div class="col-md-12">
                <div class="restaurant-detailed-header-left">

                  <div class="col-md-2 menu_header" *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0"><img class="img-fluid mr-3 float-left mb-0 d-none d-sm-block " alt="Spice Hut Indian Restaurant"
                     [src]="storeDetails?.logoUrl"></div>

                  <div class="col-md-10 menu_header" *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0">
                     <h2 class="text-white">
                       
                         
                        <span *ngIf="lang == 0">    {{storeDetails?.storeName}}</span>
                        <span *ngIf="lang == 1">    {{storeDetails?.storeNameAr}}</span>
                     </h2>
                     <p class="text-white mb-1 d-none d-sm-block"><i class="fa fa-location-pin"></i>
                        <span *ngIf="lang == 0">    {{storeDetails?.description}}</span>
                        <span *ngIf="lang == 1">    {{storeDetails?.descriptionAr}}</span>
                     </p>
                  </div>
                   

                  
                  
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
 <!-- <section class="restaurant-detailed-banner" >
     <div class="text-center">
        <div class="container" style="position: relative;">          
  
        </div>
        <div style="background-image: url(../assets/img/restaurant-landing.jpg); position: relative;" class="img-fluid cover">
          <div class="restaurant-detailed-header__docked">
             <div class="text-white mb-0 restaurant-detailed-ratings">               
                 <img style="cursor: pointer;" src="../assets/img/back.png" (click)="navigateToRestuarentList()">
                 <a  (click)="navigateToRestuarentList()"> BACK</a>
              </div>
           </div>
        </div>
     </div>
     <div class="restaurant-detailed-header">
        <div class="container">
           <div class="row d-flex align-items-end">
              <div class="col-md-12">
                 <div class="restaurant-detailed-header-left">
                    <img class="img-fluid mr-3 float-left mb-0 d-none d-sm-block" alt="Spice Hut Indian Restaurant"
                       src="../assets/img/211212.png">
                    <h2 class="text-white">
                     Welcome to   {{storeDetails?.storeName}}
                    </h2>
                    <p class="text-white mb-1 d-none d-sm-block"><i class="fa fa-location-pin"></i>
                      {{storeDetails?.description}}
                    </p>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </section> -->
 
  <section class="offer-dedicated-nav bg-white border-top-0 shadow-sm sticky-top">
     <div class="container">
        <div class="row">
           <div class="col-md-12 ">
             <a >
 
             
  
 
          </a>
               </div>
               </div>
     </div>
 </section>
 
 
 
 <div class="">
    <div class="container"><br/>
       <div class= "row ">
          <!-- <div class=" col-lg-3  md-3 col-sm-6 col-xs-12" (click)="onServiceClick(item.id)" *ngFor="let item of storeServiceList">
             <div class="hovereffect">
                <img class="img-responsive" [src]="item?.imagePath" alt="">
                   <div class="overlay">                     
                   </div>
                   <div class="heading-menus">						
                             <a style="cursor: pointer;" (click)="onServiceSelection(item.id)"><h2> {{item?.serviceName}}</h2></a>						
                         </div>
             </div>
          </div> -->
          <!-- <div class=" col-lg-3  md-3 col-sm-6 col-xs-12" (click)="onMenuClick()" >
             <div class="hovereffect">
                <img class="img-responsive" src="../../../assets/img/tablemenu.jpg" alt="">
                   <div class="overlay">                     
                   </div>
           
           
           
           
                   <div class="heading-menus">						
                             <a style="cursor: pointer;" ><h2> Menu</h2></a>						
                         </div>
             </div>
          </div>
          <div class=" col-lg-3  md-3 col-sm-6 col-xs-12" (click)="onTableBooking()">
             <div class="hovereffect">
                <img class="img-responsive" src="../../../assets/img/book-a-table.jpg" alt="">
                   <div class="overlay">                     
                   </div>
                   <div class="heading-menus">						
                             <a style="cursor: pointer;" ><h2>  Book a Table</h2></a>						
                         </div>
             </div>
          </div> -->
          <div class=" col-lg-3  md-3 col-sm-6 col-xs-12" (click)="onServiceClick(item.id)" *ngFor="let item of storeServiceList">
            <div class="hovereffect">
               <img class="img-responsive"  [src]="item?.imagePath" alt="">
                  <div class="overlay">                     
                  </div>
                  <div class="heading-menus" (click)="onServiceClick(item.id)">						
                            <a style="cursor: pointer;" ><h2> 
                               
                           
                              <span *ngIf="lang == 0">    {{item?.serviceName}}</span>
                                        <span *ngIf="lang == 1">    {{item?.serviceNameAr}}</span>
                           </h2></a>						
                        </div>
            </div>
         </div>


        
          <!-- <div class=" col-lg-3  md-3 col-sm-6 col-xs-12 ">
             <div class="hovereffect">
                <img class="img-responsive" src="../assets/img/tablemenu.jpg" alt="">
                   <div class="overlay">
                      
                      <p>
                         <a href="#"><h2> Table Menu</h2></a>
                      </p>
                   </div>
             </div>
          </div>
          <div class=" col-lg-3  md-3 col-sm-6 col-xs-12 ">
             <div class="hovereffect">
                <img class="img-responsive" src="../assets/img/room-service.jpg" alt="">
                   <div class="overlay">
                      
                      <p>
                         <a href="#"><h2> Room-service</h2></a>
                      </p>
                   </div>
             </div>
          </div>
          <div class="col-lg-3 md-3 col-sm-6 col-xs-12 mobile-box">
             <div class="hovereffect">
                <img class="img-responsive" src="../assets/img/book-a-table.jpg" alt="">
                   <div class="overlay">
                      
                      <p>
                         <a href="#"><h2> Book a Table</h2></a>
                      </p>
                   </div>
             </div>
          </div> -->        
       </div> 
       </div>
 
 
      <div class = "row topping">
         <div class="col-12 md-6 text-center ">
 
         <h4>
            <!-- Example page header  -->           
         </h4>
         
       </div>
      </div>
        <div class="row">
          <!-- <div class="col-lg-3  md-4 text-center " *ngFor="let item of storeServiceList">
             <div class="box">
                     
                <div class="box-title">
                   
                </div>
                <div class="box-text" (click)="onServiceClick(item.id)">
                          <h4>{{item?.serviceName}}</h4>
                          <span>خدمة الغرف</span>
                </div>
                
              </div>
          </div>	 -->
 
         
             <!-- <div class="col-lg-3  md-4 text-center ">
               <div class="box">
                       
                  <div class="box-title">
                     
                  </div>
                  <div class="box-text" (click)="routeMenu()">
                            <h4>Table Menu</h4>
                            <span>خدمة الغرف</span>
                  </div>
                  
                </div>
            </div>	 
            
            <div class="col-lg-3  md-4 text-center " (click)="routeRoomServiceMenu()">
               <div class="box">
                        
                  <div class="box-title">
                     
                  </div>
                  <div class="box-text">
                            <h4>Room Service</h4>
                            <span>خدمة الغرف</span>
                  </div>
                  
                </div>
            </div>	 
            
            <div class="col-lg-3  md-4 text-center ">
               <div class="box">
                        
                  <div class="box-title">
                     
                  </div>
                  <div class="box-text" (click)="onTableBooking()">
                            <h4>Table Booking</h4>
                            <span>خدمة الغرف</span>
                  </div>
                  
                </div>
            </div>	  -->
  
            
             
            
      
      </div>		
    </div>
 
 
 
 <section >
    
     <div class="container">
 <!--        
        <div class="row vivira">
           
           <div class="col-md-12 col-12 ">
 <h3>Grand Hyatt</h3>
 <hr>
                         </div>
                         <div class="col-md-12 col-12 md-4 ">
                            <p>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 
                               1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?
                               </p>
                                                    </div>
                         </div> -->
                         <!-- <div class="row vivira">
                            <div class="col-md-12 col-12 ">
                  <h3>Hours</h3>
                  <hr>
                                          </div>
                                          <div class="col-md-12 col-12 md-4 ">
                                             <p>What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 
                                                1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?
                                                </p>
                                                                     </div>
                                          </div> -->
     </div>
 </section>
                          

 </div>