import { Component, OnInit } from "@angular/core";
import { $ } from "protractor";
import { OwlOptions } from "ngx-owl-carousel-o";
import { CommonService } from "./services/common.service";
import { SplashScreenComponent } from "./splash-screen/splash-screen.component";
import { ConfirmationService } from "./shared/confirmation.service";
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { from } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { Config } from "./services/config";
import { TranslateService } from "@ngx-translate/core";
import { DbService } from "./services/db.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {


  themeLoaded : boolean = false;
  constructor(private commonService: CommonService,
    private translate : TranslateService ,
     public router: Router,
     private db : DbService,
     private confirmationDialogService : ConfirmationService,
     private route : ActivatedRoute,
     private spinner: NgxSpinnerService) {
    // console.log(this.getPosition())


    
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.theme != undefined) {
        localStorage.setItem('theme', queryParams.theme);
        this.setTheme(Number(atob(localStorage.getItem('theme'))) , 0)
      }
    })

    if (localStorage.getItem("cart")) {
      this.commonService.cartList = JSON.parse(localStorage.getItem("cart"));
      if(this.commonService.cartList.length > 0){
        if(this.commonService.cartList.find(x=> ((new Date().getTime() - new Date(x.addTime).getTime())/3.6e+6) > 24))
         {
           this.commonService.cartList = []
         }
      }
    } else {
      this.commonService.cartList = [];
    }
    if (localStorage.getItem("tablecart")) {
      this.commonService.tableBookingCartList = JSON.parse(localStorage.getItem("tablecart"));
    } else {
      this.commonService.tableBookingCartList = [];
    }

    if (localStorage.getItem("storeDetails")) {
      this.commonService.storeDetails = JSON.parse(
        localStorage.getItem("storeDetails")
      );
    } else {
      this.commonService.storeDetails = {};
    }
    if (localStorage.getItem("storeId")) {
      this.commonService.storeId = JSON.parse(localStorage.getItem("storeId"));
    } else {
      this.commonService.storeId = 0;
    }
    if (localStorage.getItem("tableId")) {
      this.commonService.tableId = JSON.parse(localStorage.getItem("tableId"));
    } else {
      this.commonService.tableId = 0;
    }
    if (
      localStorage.getItem("viewState") &&
      localStorage.getItem("viewState") != undefined
    ) {
      this.commonService.viewState = localStorage.getItem("viewState");
    }

    if (
      localStorage.getItem("isLoggedIn") &&
      localStorage.getItem("isLoggedIn") != undefined
    ) {
      this.commonService.isLoggedIn = Boolean(
        localStorage.getItem("isLoggedIn")
      );
    }
    if (
      localStorage.getItem("customer") &&
      localStorage.getItem("customer") != undefined
    ) {
      this.commonService.customer = JSON.parse(
        localStorage.getItem("customer")
      );
    }

    if (localStorage.getItem("officeId")) {
      this.commonService.officeId = Number(localStorage.getItem("officeId"))
      
    } else {
      this.commonService.officeId = 0;
    }
    if (localStorage.getItem("orderModes")) {
      this.commonService.orderModes = Number(localStorage.getItem("orderModes")
      );
    } 
  this.langSet();
  
  }



  checkTheme(){

    if(localStorage.getItem('theme') != null){
      // theme from localostorage or subdomain;
      // console.log(atob(localStorage.getItem('theme')))
      this.setTheme(Number(atob(localStorage.getItem('theme'))))  
    }
    else{
       // theme from company
       if(this.companyDetails != undefined){
        this.setTheme(this.companyDetails.themeId , 1);
       }
       else{
        this.setTheme(1,1)
       }
      }
  }

  setTheme(themeId , mode?  ) {  //mode 0 for local 1 for company


      // themeId = 1; // for white

      // themeId = 2; // for red

      // themeId = 3; // for black 

      // themeId = 4; // for blue

      // themeId = 1002; // for brown

      // themeId = 1003; // for dark blue 

        //  themeId = 1004; // for light blue 

        //  themeId = 1005; // for artistic

          // themeId = 1006; // for brown-white

        //  themeId = 1007; // for pink

        //  themeId = 1008; // for grey

        //  themeId = 1009; // for white and grey


        // themeId = 1010; // for rose

        // themeId = 1011; // for rose-2

        // themeId = 1012; // for black 1

        // themeId = 1013; // for white-red

        // themeId = 1014; // for bd
        // themeId = 1015; // for yellow
        // themeId = 1016; // for aimees

      //  themeId = 1017; // for matafandtea

      //  themeId = 1018; // for kasibeyaz

      // themeId = 1019; // for fromage

      //  themeId = 1020; // grey-black

      //  themeId = 1021; // JAR
      //  themeId = 1022; // dessert-rose-cafe
      //  themeId = 1023; // vamos

      //  themeId = 1024; // deqada

      //  themeId = 1025; // lapcoffee

      //  themeId = 1026; // golden-oryx

      //  themeId = 1027; // lose and gain

      //  themeId = 1028; // solo

      // themeId = 1029; // white sugar

      //  themeId = 1030; // novikov

      //  themeId = 1031; // karak-mudeer

      //  themeId = 1032; // kabalen

      //  themeId = 1033; // gazebo

      //  themeId = 1034; // creper
      //  themeId = 1035; // pepper

      //  themeId = 1036; // gazebo-black-white-sugar-copy

       //themeId = 1037; // lala restuarent

      //  themeId = 1038; // c house

      //  themeId = 1039; // ice cream plaza
     
      //  themeId = 1040; // kuala-tea

      //  themeId = 1041; // zaitoon

      //  themeId = 1042; // for gaimer







       








       











 


      if(Config.lang == "0"){
        if(themeId == 2){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/red/style.css')
        }

        else if(themeId == 1){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white/style.css')
        }

        else if(themeId == 4){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/blue/style.css')
        }

        else if(themeId == 1002){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/brown/style.css')
        }
        else if(themeId == 3){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/theme/style.css')
        }
        else if(themeId == 1003){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/dark-blue/style.css')
        }
        else if(themeId == 1004){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/light-blue/style.css')
        }
        else if(themeId == 1005){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/artistic/style.css')
        }

        else if(themeId == 1006){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/brown-white/style.css')
        }
        else if(themeId == 1007){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/pink/style.css')
        }
        else if(themeId == 1008){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/grey/style.css')
        }
        else if(themeId == 1009){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white-grey/style.css')
        }
        else if(themeId == 1010){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/rose/style.css')
        }
        else if(themeId == 1011){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/rose-2/style.css')
        }
        else if(themeId == 1012){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/black-1/style.css')
        }
        else if(themeId == 1013){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white-red/style.css')
        }
        else if(themeId == 1014){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/bd/style.css')
        }
        else if(themeId == 1015){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/yellow/style.css')
        }
        else if(themeId == 1016){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/aimees/style.css')
        }
        else if(themeId == 1017){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/matafandtea/style.css')
        }
        else if(themeId == 1018){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/kasibeyaz/style.css')
        }
        else if(themeId == 1019){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/fromage/style.css')
        }
        else if(themeId == 1020){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/grey-black/style.css')
        }
        else if(themeId == 1021){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/jar/style.css')
        }
        else if(themeId == 1022){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/dessert-rose-cafe/style.css')
        }
        else if(themeId == 1023){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/vamos/style.css')
        }
        else if(themeId == 1024){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/deqada/style.css')
        }
        else if(themeId == 1025){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/lapcoffee/style.css')
        }
        else if(themeId == 1026){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/golden-oryx/style.css')
        }
        else if(themeId == 1027){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/lose-and-gain/style.css')
        }
        else if(themeId == 1028){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/solo/style.css')
        }
        else if(themeId == 1029){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white-sugar/style.css')
        }
        else if(themeId == 1030){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/novikov/style.css')
        }
        else if(themeId == 1031){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/karak-mudeer/style.css')
        }
        else if(themeId == 1032){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/kabalen/style.css')
        }
        else if(themeId == 1033){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/gazebo/style.css')
        }
        else if(themeId == 1034){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/creper/style.css')
        }
        else if(themeId == 1035){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/pepper/style.css')
        }
        else if(themeId == 1036){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/gazebo-black/style.css')
        }
        else if(themeId == 1037){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/lala/style.css')
        }
        else if(themeId == 1038){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/c-house/style.css')
        }
        else if(themeId == 1039){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/ice-cream-plaza/style.css')
        }
        else if(themeId == 1040){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/kuala-tea/style.css')
        }

        else if(themeId == 1041){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/zaitoon/style.css')
        }


         else if(themeId == 1042){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/gaimer/style.css')
        }



        else{
          document.getElementById('link8').setAttribute('href', './assets/mall/css/theme/style.css')
        }
      }
      else{
        if(themeId == 2){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/red/ar/style.css')
        }
        else if(themeId == 1){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white/ar/style.css')
        }
        else if(themeId == 4){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/blue/ar/style.css')
        }

        else if(themeId == 1002){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/brown/ar/style.css')
        }
        else if(themeId == 3){
          document.getElementById('link8').setAttribute('href', './assets/mall/css/theme/ar/style.css')
        }
        else if(themeId == 1003){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/dark-blue/ar/style.css')
        }
        else if(themeId == 1004){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/light-blue/ar/style.css')
        }
        else if(themeId == 1005){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/artistic/ar/style.css')
        }

        else if(themeId == 1006){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/brown-white/ar/style.css')
        }
        else if(themeId == 1007){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/pink/ar/style.css')
        }
        else if(themeId == 1008){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/grey/ar/style.css')
        }
        else if(themeId == 1009){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white-grey/ar/style.css')
        }
        else if(themeId == 1010){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/rose/ar/style.css')
        }
        else if(themeId == 1011){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/rose-2/ar/style.css')
        }
        else if(themeId == 1012){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/black-1/ar/style.css')
        }

        else if(themeId == 1013){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white-red/ar/style.css')
        }
        else if(themeId == 1014){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/bd/ar/style.css')
        }
        else if(themeId == 1015){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/yellow/ar/style.css')
        }
        else if(themeId == 1016){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/aimees/ar/style.css')
        }
        else if(themeId == 1017){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/matafandtea/ar/style.css')
        }
        else if(themeId == 1018){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/kasibeyaz/ar/style.css')
        }
        else if(themeId == 1019){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/fromage/ar/style.css')
        }
        else if(themeId == 1020){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/grey-black/ar/style.css')
        }
        else if(themeId == 1021){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/jar/ar/style.css')
        }
        else if(themeId == 1022){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/dessert-rose-cafe/ar/style.css')
        }
        else if(themeId == 1023){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/vamos/ar/style.css')
        }
        else if(themeId == 1024){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/deqada/ar/style.css')
        }
        else if(themeId == 1025){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/lapcoffee/ar/style.css')
        }
        else if(themeId == 1026){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/golden-oryx/ar/style.css')
        }
        else if(themeId == 1027){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/lose-and-gain/ar/style.css')
        }
        else if(themeId == 1028){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/solo/ar/style.css')
        }
        else if(themeId == 1029){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/white-sugar/ar/style.css')
        }
        else if(themeId == 1030){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/novikov/ar/style.css')
        }
        else if(themeId == 1031){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/karak-mudeer/ar/style.css')
        }
        else if(themeId == 1032){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/kabalen/ar/style.css')
        }
        else if(themeId == 1033){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/gazebo/ar/style.css')
        }
        else if(themeId == 1034){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/creper/ar/style.css')
        }
        else if(themeId == 1035){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/pepper/ar/style.css')
        }
        else if(themeId == 1036){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/gazebo-black/ar/style.css')
        }
        else if(themeId == 1037){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/lala/ar/style.css')
        }
        else if(themeId == 1038){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/c-house/ar/style.css')
        }
        else if(themeId == 1039){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/ice-cream-plaza/ar/style.css')
        }
        else if(themeId == 1040){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/kuala-tea/ar/style.css')
        }

        else if(themeId == 1041){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/zaitoon/ar/style.css')
        }

        else if(themeId == 1042){ 
          document.getElementById('link8').setAttribute('href', './assets/mall/css/gaimer/ar/style.css')
        }



        else{
          document.getElementById('link8').setAttribute('href', './assets/mall/css/theme/ar/style.css')
        }
      }




      setTimeout(() => {
        this.themeLoaded = true;
     }, 1000); 
  }



  pushNotificationPending(){
    this.db.methodGet("PushNotifications/GetCustomerPushNotifications/" + this.commonService.customer.id).subscribe(resdata =>{
      
      if(resdata.length > 0){

        let message ='';
        if(Config.lang == "0"){
          message = resdata[0].pushMessage
        }
        else{
          message = resdata[0].pushMessageAr
        }
         

        this.confirmationDialogService.notification(message,resdata[0].id)
        .then((confirmed) =>
        {
          this.pushNotificationPending();
        })
      }
    })
  }



  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }
  ngOnInit() {
    this.commonService.findTotal();
    this.getCompanyDetails();
    if(this.commonService.isLoggedIn){
      this.commonService.push();
      this.pushNotificationPending();
    }
  }

  companyDetails;

  getCompanyDetails(){
	  this.spinner.show();
    this.db.methodGet("Company/GetMallDetails").subscribe(resdata =>{
    
	  this.spinner.hide();
      this.companyDetails = resdata;
 

      this.checkTheme();
      
      

    },
    error =>{
      this.checkTheme();

      this.spinner.hide();
    }
     )
  }



  langSet(){
    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == '0'){
        Config.lang = "0";
        this.translate.use('en')
      }
      else if(localStorage.getItem('lang') == '1'){
        Config.lang = "1";
        this.translate.use('ar')
      }
    }
    else{
      this.translate.use('en');
      Config.lang = "0";
      localStorage.setItem('lang','0')
    }

    this.addStyleSheet(Config.lang);

  }
  addStyleSheet(lang) {
    if(lang == "0"){
      document.getElementById('link1').setAttribute('href', './assets/style/style.css');
      // document.getElementById('link2').setAttribute('href', './assets/mall/css/bootstrap.min.css');
      // document.getElementById('link3').setAttribute('href', './assets/mall/css/font-awesome.css');
      // document.getElementById('link4').setAttribute('href', './assets/mall/css/font/flaticon.css');
      document.getElementById('link5').setAttribute('href', './assets/mall/css/swiper.min.css');
      document.getElementById('link6').setAttribute('href', './assets/mall/css/style.css')
      document.getElementById('link7').setAttribute('href', './assets/mall/css/responsive.css')
      document.getElementById('link8').setAttribute('href', './assets/mall/css/style.css')
   

     

    //  this.isShow = true;
    }
    else{
      document.getElementById('link1').setAttribute('href', './assets/style/ar/style.css');
      document.getElementById('link2').setAttribute('href', './assets/mall/css/ar/bootstrap.min.css');
      // document.getElementById('link3').setAttribute('href', './assets/mall/css/ar/font-awesome.css');
      // document.getElementById('link4').setAttribute('href', './assets/mall/css/ar/font/flaticon.css');
      document.getElementById('link5').setAttribute('href', './assets/mall/css/ar/swiper.min.css');
      document.getElementById('link6').setAttribute('href', './assets/mall/css/ar/style.css')
      document.getElementById('link7').setAttribute('href', './assets/mall/css/ar/responsive.css')
      document.getElementById('link8').setAttribute('href', './assets/mall/css/red/ar/style.css')
      
   // this.isShow = true;
   setTimeout(() => {
    // this.isShow = true;
 }, 600);
  }
 

   }
}
