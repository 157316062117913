import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { map} from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { add } from "ngx-bootstrap/chronos";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { GuestLoginComponent } from "../auth/guest-login/guest-login.component";
import { AuthService } from "../auth/services/auth.service";
import { CommonService } from "../services/common.service";
import { Config } from "../services/config";
import { DbService } from "../services/db.service";
import { StoreService } from "../services/store.service";
import { AddSuggestionPopupComponent } from "../shared-popups/add-suggestion-popup/add-suggestion-popup.component";
import { LoginAsGuestPopupComponent } from "../shared-popups/login-as-guest-popup/login-as-guest-popup.component";
import { SignInPopupComponent } from "../shared-popups/sign-in-popup/sign-in-popup.component";
import { SignupPopupComponent } from "../shared-popups/signup-popup/signup-popup.component";
import { ConfirmationService } from "../shared/confirmation.service";
import { DeliveryMapPopupComponent } from "./delivery-map-popup/delivery-map-popup.component";
import { ChooseSavedAddressPopupComponent } from "./choose-saved-address-popup/choose-saved-address-popup.component";
import { ChooseBranchPopupComponent } from "./choose-branch-popup/choose-branch-popup.component";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit, OnDestroy {
  itemTotal: number = 0;
  signUpForm: FormGroup;
  flatDiscount: number = 0;

  lang = Number(Config.lang);
  saveAddress : boolean = true    ;
  customerSelectedAddressId : number= 0;
  closeResult = "";
  @ViewChild("mymodal2", { static: true }) signInPopup: ElementRef;

  @ViewChild("signup", { static: true }) signUpPopup: ElementRef;
  @ViewChild("signIn1", { static: true }) signIn1: ElementRef;
  @ViewChild("congrats", { static: true }) offerContent: ElementRef;
  @ViewChild("asGuest", { static: true }) asGuest: ElementRef;

  deliveryfee: number = 0;
  netTotal: number = 0;
  locationCoOrdinates : any ;
  latitude : number ;
  longitude : number;
  mapAddress : string;
  isDeliveryAvailableForStore: boolean = false;
  deliveryAddressForm: FormGroup;
  discountAppliedPerc: number = 0;
  discountAmount: number = 0;
  suggestions: string = "";
  officeDetails: any = "";
  storeDetails;
  orderType: number = 0; //0 for take away : 1 for delivery : 2 for pre order;
  takeAwayMinTime: number = 0;
  takeAwayTimeDuration: number = 0;
  deliverableResponse : any;
  isDeliveryMode: boolean = false;
  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastr: ToastrService,
    private dbService : DbService,

    private storeService: StoreService
  ) {}

  ngOnDestroy() {
    this.commonService.isCartPage = false;
  }
  ngOnInit(): void {
    this.commonService.isCartPage = true;

 


    if (this.commonService.officeId > 0) {
      this.storeService
        .getOfficeDetailsById(this.commonService.officeId)
        .subscribe((resdata) => {
          this.officeDetails = resdata;
        });
    }

    this.getStoreDetails(this.commonService.storeId);
   
    this.buildDeliveryDetailsForm();

    this.commonService.cartList.forEach((x) => {
      if (x.LinkId && x.IsAddOn == false) {
        let addOns = this.commonService.cartList.filter(
          (y) => x.LinkId == y.LinkId && y.IsAddOn == true
        );
        x.addOns = addOns;
      }
    });


    if(localStorage.getItem('customer')){
    
     this.getCustomerAddresses();
     this.getLoyaltyDetails();
    }

    //   this.calculateTotal();
  }


  loyaltyDetails: Array<any>= [];

  getLoyaltyDetails(){
    let body = {
      customerId :   this.commonService.customer.id,
      storeId : this.commonService.storeId
    }
    this.dbService.methodPost("Customers/GetCustomerLoyaltyDetails",body).subscribe(resdata =>{
      this.loyaltyDetails = resdata ;
      // this.loyaltyDetails[0].discountCount =2;
      // this.loyaltyDetails[0].salesCount = 3;

      this.applyLoyalty();
     })
  }

  applyLoyalty(){
    // console.log(this.commonService.cartList);
    this.loyaltyDetails.forEach(x =>{
      // if(x.totalCount == undefined )
       x.totalCount = x.count;
      // if(x.appliedDiscQuantity == undefined )
       x.appliedDiscQuantity = 0;

      //  console.log(this.commonService.cartList , 'cartlist');
      //  console.log(this.loyaltyDetails, 'loyalty details');
      this.commonService.cartList.forEach(y =>{
        if(y.loyaltyMenuGroups)
        (y.loyaltyMenuGroups).forEach(z =>{
          if(x.menuGroupId == z.menuGroupId) {
            x.totalCount = Number(x.totalCount)  + Number(y.Quantity);
          }
        })
      })
    })

    this.loyaltyDetails.forEach(x =>{


    
    
    
      if(x.totalCount > 0 &&  (x.totalCount/(x.salesCount + 1)) >= 1 ) {
        x.appliedDiscQuantity = (Math.trunc(Number(x.totalCount) / Number(x.salesCount + 1)));
      }
      // if(x.totalCount > 0 &&  (x.totalCount/x.salesCount) > 1 ) {
      //   x.appliedDiscQuantity = (Math.trunc(Number(x.totalCount) / Number(x.salesCount))) * x.discountCount;
      // }
    })

   this.appliedLoyaltyDetails = this.loyaltyDetails.filter (x => x.appliedDiscQuantity > 0)



    this.appliedLoyaltyDetails.forEach(x =>{
      let appliedPrice : Array<any> = [];
      this.commonService.cartList.forEach(y =>{
        (y.loyaltyMenuGroups).forEach(z =>{
          if(x.menuGroupId == z.menuGroupId) {
            appliedPrice.push(y.Price);
          }
        })
      })
      x.discountPrice = Math.min(...appliedPrice);
    })


    this.calculateTotal();
    // console.log('appliedLoyalty' , this.appliedLoyaltyDetails);
    

  }




  appliedLoyaltyDetails :Array <any>= [];


  getCustomerAddresses(){
      this.dbService.methodGet("Customers/GetCustomerAddress/" + this.commonService.customer.id).subscribe(resdata =>{
           this.commonService.customerAddresses = resdata;
      })
  }

  timeValid(start, end) {
    let startTime = start + ":00";
    let endTime = end + ":00";

    let currentDate = new Date();

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(Number(startTime.split(":")[0]));
    startDate.setMinutes(Number(startTime.split(":")[1]));
    startDate.setSeconds(Number(startTime.split(":")[2]));

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(Number(endTime.split(":")[0]));
    endDate.setMinutes(Number(endTime.split(":")[1]));
    endDate.setSeconds(Number(endTime.split(":")[2]));

    if (startDate < currentDate && endDate > currentDate) {
      return true;
    } else {
      return false;
    }
  }



  selectedPayMode : number = 0;
  cbPay4digit = "";
  cbPayType =''
  payModes = []

  getStorePaymentModes(){
    this.dbService.methodGet("PaymentModes/GetActiveStorePaymentModes/" + this.storeDetails.id).subscribe(resdata =>{
      this.payModes = resdata;
      if(this.payModes.length > 0){
        this.selectedPayMode = this.payModes[0].id;
        this.payModes[0].selected = true;
      }
    })
  }


  onPayModeChange(event , index , item){
    if(event.target.checked == true){
      item.selected = true;
      this.selectedPayMode = item.id;
    }
    
    this.payModes.forEach((x,i) =>{
      if(i != index){
        item.selected = false;
      }
    })

  }
  getStoreDetails(storeId) {
    this.spinner.show();

    this.storeService.getStoreDetailsbyId(storeId).subscribe((resdata) => {
      this.spinner.hide();


      this.storeDetails = resdata;
      // this.storeDetails.enableGoogleMap = true;
      // this.storeDetails.enablePickUp =true
      if(this.storeDetails.enableMultiLanguage != true){
        this.commonService.showMultyLang = false;
      }
      if(this.storeDetails.enablePayment == true){
        this.getStorePaymentModes();
      }
      if (this.storeDetails.settings != null) {
        this.takeAwayMinTime = this.storeDetails.settings.pickupMinTime;
        this.takeAwayTimeDuration = this.storeDetails.settings.pickupDuration;
      }
      this.isDeliveryAvailableForStore = this.storeDetails.isDelivery;
      if (
        !this.isDeliveryAvailableForStore ||
        this.commonService.orderModes == 2 ||
        this.commonService.orderModes == 3
      ) {
        this.isDeliveryMode = false;
        
        if (this.commonService.orderModes != 2) {
          this.onOrderTypeChange(0);
          setTimeout(() => {
            document.getElementById("takeaway").click();
         }, 1000); 
        

        }
      } else {
        if (
          this.timeValid(
            this.storeDetails.deliveryStartTime,
            this.storeDetails.deliveryEndTime
          )
        ) {
          this.isDeliveryMode = true;
          this.onOrderTypeChange(1);
        } else {
          this.isDeliveryMode = false;
          this.isDeliveryAvailableForStore = false;

          this.onOrderTypeChange(0);
          setTimeout(() => {
            document.getElementById("takeaway").click();
         }, 1000); 

          // alert('delivery closed')
        }
      }
      if (
        //  this.storeDetails.offers.length > 0
        this.storeDetails.storeOffer != null
        //  &&this.commonService.officeId > 0
      ) {
        this.discountAppliedPerc = this.storeDetails.storeOffer.discountPercent;
        this.flatDiscount = this.storeDetails.storeOffer.flatDiscount;
      }

      //  this.buildDeliveryDetailsForm();
      this.calculateTotal();
    });
  }

  isImmediateTakeAway: boolean = false;
  onPickUpTimeCounter(key) {
    if (key == 1) {
      if (this.takeAwayMinTime != this.storeDetails.settings.pickupMinTime) {
        this.takeAwayMinTime = this.takeAwayMinTime - this.takeAwayTimeDuration;
      }
    }
    if (key == 2) {
      this.takeAwayMinTime = this.takeAwayMinTime + this.takeAwayTimeDuration;
    }
  }

  onImmediateChange() {
    if (this.isImmediateTakeAway) {
      this.takeAwayMinTime = this.storeDetails.settings.pickupMinTime;
    }
  }

  onPlusQty(item) {
    item.Quantity = item.Quantity + 1;
    item.TotalPrice = item.Quantity * item.Price;
    if (item.LinkId && item.IsAddOn == false && item.addOns.length > 0) {
      item.addOns.forEach((y) => {
        y.Quantity = y.Quantity + 1;
        y.TotalPrice = y.Quantity * y.Price;
      });
    }

    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
    this.applyLoyalty();

    // this.calculateTotal();
  }
  onMinusQty(item, index) {
    if (item.Quantity != 1) {
      item.Quantity = item.Quantity - 1;
      item.TotalPrice = item.Quantity * item.Price;

      if (item.LinkId && item.IsAddOn == false && item.addOns.length > 0) {
        item.addOns.forEach((y) => {
          y.Quantity = y.Quantity - 1;
          y.TotalPrice = y.Quantity * y.Price;
        });
      }
    } else {

      let head ;
      let message ;
      if(Config.lang == "0"){
         head ='Please Confirm'
         message  ='are you sure remove this item from cart ?'
       
      }
      else{
        head ='يرجى تأكيد'
        message  ='هل أنت متأكد من إزالة هذا العنصر من سلة التسوق؟'

      }
      this.confirmationDialogService
        .confirm(head, message)
        .then((confirmed) => {
          if (confirmed) {
            this.commonService.cartList.splice(index, 1);
            if (item.LinkId) {
              this.commonService.cartList = this.commonService.cartList.filter(
                (x) => x.LinkId !== item.LinkId
              );
            }

            this.applyLoyalty();

            localStorage.setItem(
              "cart",
              JSON.stringify(this.commonService.cartList)
            );
          }
        })
        .catch(() =>
          console.log(
            "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
          )
        );
    }
    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
    this.applyLoyalty();

    // this.calculateTotal();
  }

  flatDiscApplicable: boolean = false;

  calculateTotal() {
    if (
      this.isDeliveryAvailableForStore == false ||
      this.isDeliveryMode == false
    ) {
      this.deliveryfee = 0;
    }
    this.itemTotal = 0;
    this.commonService.itemTotal = 0;
    this.commonService.cartList.forEach((x) => {
      this.itemTotal = +this.itemTotal + x.TotalPrice;
      this.commonService.itemTotal =
        this.commonService.itemTotal + x.TotalPrice;
    });
    if (
      this.flatDiscount > 0 &&
      this.itemTotal > this.storeDetails?.storeOffer?.minBillAmount
    ) {
      this.flatDiscApplicable = true;
    } else {
      this.flatDiscApplicable = false;
    }
    this.discountAmount = (this.discountAppliedPerc * this.itemTotal) / 100;
    this.netTotal = this.itemTotal + this.deliveryfee - this.discountAmount;
    if (this.flatDiscApplicable) {
      this.netTotal = this.netTotal - this.flatDiscount;
    }

    let loyaltyTotalDiscount : number = 0;
    if(this.appliedLoyaltyDetails.length > 0){
       this.appliedLoyaltyDetails.forEach(x =>{
        loyaltyTotalDiscount = loyaltyTotalDiscount + (x.discountPrice * x.appliedDiscQuantity)
       })
    }
    this.netTotal = this.netTotal - loyaltyTotalDiscount;

    localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
  }

  buildDeliveryDetailsForm() {
    this.deliveryAddressForm = this.fb.group({
      name: [""],
      phoneNo: [""],
      fullAddress: [""],
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  

  openSignUpPopup() {
    const modalRef = this.modalService
      .open(SignupPopupComponent)
      .result.then((result) => {
        if (result == 1) {
          const modalRef2 = this.modalService.open(SignInPopupComponent).result.then(
            (result2) =>{
                     if(this.commonService.isLoggedIn){
                       this.getCustomerAddresses();
                       this.getLoyaltyDetails();
                     }
            });;
        }
        if (result == 2) {
          const modalRef3 = this.modalService.open(LoginAsGuestPopupComponent).result.then(
            (result2) =>{
                     if(this.commonService.isLoggedIn){
                       this.getCustomerAddresses();
                       this.getLoyaltyDetails();
                     }
            });;;
        }
      });
  }
  openMap() {
    const modalRef = this.modalService
      .open(DeliveryMapPopupComponent)
      modalRef.componentInstance.storeId = this.commonService.storeId;

      modalRef.componentInstance.latitude =  (this.latitude);
      modalRef.componentInstance.longitude = (this.longitude);

       modalRef.result.then(
      (result) => {
        
        this.latitude = result.lat;
        this.longitude = result.lng;
        this.mapAddress = result.location;
        this.deliverableResponse = result.deliveryResponse;
        this.deliveryfee = this.deliverableResponse.deliveryCharge;



        this.toastr.success('delivery charges updated !')
        this.calculateTotal();


      //  item.Note = result; 
      //  localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      }
    )
  }
  chooseSavedAddresses() {
    const modalRef = this.modalService
      .open(ChooseSavedAddressPopupComponent)
      modalRef.componentInstance.addressList = this.commonService.customerAddresses;
      modalRef.componentInstance.storeId =  (this.commonService.storeId);
      modalRef.componentInstance.addressData =  (this.addressData);

      // modalRef.componentInstance.longitude = (this.longitude);
       modalRef.result.then(
      (result) => {
        
        if(result == 1){
        this.addNewLocation();
        }
        else{
        this.customerSelectedAddressId = result.addressId;
        this.deliverableResponse = result.deliveryResponse;
        this.deliveryfee = this.deliverableResponse.deliveryCharge;
        this.toastr.success('delivery charges updated !')
        this.addressData =this.commonService.customerAddresses.find(x => x.id == this.customerSelectedAddressId);
        this.latitude = Number(this.addressData.latitude)
        this.longitude = Number(this.addressData.longitude)

        this.calculateTotal();
        }
      })
    
  }
  addNoteForProduct(item){
    const modalRef = this.modalService.open(AddSuggestionPopupComponent)
    modalRef.componentInstance.suggestion = item.Note;
    modalRef.result.then(
      (result) => {
       item.Note = result;
       localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      }
    )
    modalRef.componentInstance.suggestion = item.Note;
  }

  confirmOrders() {
    if (localStorage.getItem("customer") == null) {
      if (this.commonService.orderModes == 2) {
        this.modalService.open(LoginAsGuestPopupComponent);
      } else {
        this.openSignUpPopup();
      }
    } else {
     
      
      if (
        this.commonService.customer.id > 0 &&
        this.commonService.customer.tempLogin == true &&
        this.commonService.orderModes != 2
      ) {
        this.commonService.tempMob = this.commonService.customer.phone;
        this.modalService.open(this.signInPopup);
        return;
      }
      if (this.isDeliveryMode == true) {
        if (
          Number(this.storeDetails.minDeliveryAmt) > 0 &&
          Number(this.storeDetails.minDeliveryAmt) >
            this.commonService.itemTotal
        ) {
          this.toastr.error(
            "Minimum Delivery Amount is - " + this.storeDetails.minDeliveryAmt
          );
          return;
        }
      }

      const modalRef2 = this.modalService.open(ChooseBranchPopupComponent).result.then(
        (result) =>{
          this.suggestions = result;
          this.placeOrder();
        });;


   
   
    }
  }


  placeOrder(){
    var date = new Date();
    let latest_date = this.datePipe.transform(date, "yyyy-MM-dd HH:mm:ss");
    let body: any = {};
    let head ;
    let message ;
    if(Config.lang == "0"){
       head ='Please Confirm'
       message  ='Are you Sure to Continue ?'
     
    }
    else{
      head ='يرجى تأكيد'
      message  ='هل أنت متأكد من الاستمرار؟'

    }
    this.confirmationDialogService
    .confirm(head, message)

    // this.confirmationDialogService
    //   .confirm("Please Confirm..", "Are you Sure to Continue?")
      .then((confirmed) => {
        if (confirmed) {
          if (this.commonService.cartList.length > 0) {
            
            body.StoreId = Number(this.commonService.storeId);
            body.CustomerID = this.commonService.customer.id;
            body.TableID = this.commonService.tableId;
            body.OfficeID = this.commonService.officeId;


           
            body.Address =
              this.deliveryAddressForm.controls.fullAddress.value;
            if (
              this.isDeliveryMode && this.storeDetails.enableGoogleMap != true &&
              (body.Address == "" ||
                body.Address == null ||
                body.Address == undefined)
            ) {
              this.toastr.error("Enter valid address");
              return;
            }


            
            if(this.storeDetails.enableGoogleMap == true && this.isDeliveryMode && this.latitude == undefined && this.longitude == undefined){
              this.toastr.error("Choose Location in Map");
              return;
            }
            
            body.SpecialRequest = this.suggestions;
            if (this.commonService.orderModes == 3) {
              body.IsDelivery = true;
            } else {
              body.IsDelivery = this.isDeliveryMode;
            }
            body.CusAddressID = this.customerSelectedAddressId;
            body.SaveAddress = this.saveAddress;
            body.Location = this.mapAddress;
            // if(this.storeDetails.enableGoogleMap == true){
            //   body.Address = 
            // }
            if(this.latitude != undefined && this.longitude != undefined){
              body.Latitude = this.latitude.toString();
              body.Longitude = this.longitude.toString();
            }
            else{
              body.Latitude = "";
              body.Longitude = "";
            }
           


            body.OrderedOn = latest_date;
            body.DiscountPercent = this.discountAppliedPerc;
            body.DeliveryCharge = this.deliveryfee;
            body.TotalAmount = this.netTotal;
            body.SubmitBy = "1";

            body.SubmitOn = latest_date;
            if(this.addressData){
              body.AddressTypeId = this.addressData.addressTypeId;
              body.Building = this.addressData.building;
              body.Floor = this.addressData.floor;
              body.HouseNo = this.addressData.houseNo;
              body.AdditionalDirection = this.addressData.additionalDirection;
              body.Location = this.addressData.location;

            }
          
          

            if(this.isDeliveryMode && this.storeDetails.enableGoogleMap == true ){

              if(body.AddressTypeId == 1){
                body.Address ='Build No :-' +  this.addressData.building + ',' + ' Floor :- ' + this.addressData.floor + ',' +
                ' Appt No :-' + this.addressData.houseNo + ',' + 'Dir :-'
                  + this.addressData.additionalDirection + ',Loc :-' + this.mapAddress
              }
              if(body.AddressTypeId == 2){
                body.Address =
                ' House No :-' + this.addressData.houseNo + ',' + 'Dir :-'
                 + this.addressData.additionalDirection + ',Loc :-' + this.mapAddress
              }
              if(body.AddressTypeId == 3){
                body.Address ='Build No :-' +  this.addressData.building + ',' + ' Floor :- ' + this.addressData.floor + ',' +
                ' Off No :-' + this.addressData.houseNo + ',' + 'Dir :-'
                 + this.addressData.additionalDirection + ',Loc :-' + this.mapAddress
              }


              // body.Address = this.addressData.building + ',' + this.addressData.floor + ',',
              // this.addressData.houseNo + ',' + this.addressData.additionalDirection ,',' + this.mapAddress
            }

            // 

            // this.commonService.cartList.forEach(x => x.Price = Number(x.Price))


            body.IsPosOrder = false;
            if (this.commonService.tableId == 0 && body.IsDelivery == false) {
              body.IsPickup = true;
              body.PickupDuration = this.takeAwayMinTime;
            } else {
              body.IsPickup = false;
              body.PickupDuration = 0;
            }



            body.PaymentModeID = this.selectedPayMode ;
            if(this.selectedPayMode == 3 && this.cbPay4digit == ''){
              this.toastr.error("Please Enter Last 4 digit of CB Pay");
              return;
            }
            if(this.selectedPayMode == 3 && this.cbPayType == ''){
              this.toastr.error("Please Select CB Pay Card Type");
              return;
            }
            if(this.selectedPayMode == 3){
              let cbPayDetails = {
                Last4digits: this.cbPay4digit,
                CardType : this.cbPayType
              }
              body.cardDetails = cbPayDetails
            }

            body.details = this.commonService.cartList;



            


            this.storeService.confirmNewOrders(body).subscribe(
              (resdata) => {
                var succ = resdata;
                if (resdata.id) {
                  this.toastr.success("Ordered Succesfully");
                  const url = resdata.paymentLink;
                  window.location.href = url;
                  localStorage.removeItem("cart"); 
                  this.commonService.itemTotal = 0;
                  this.commonService.cartList = [];
                  // this.onAddMore();
                } else {
                  this.toastr.error(resdata.message);
                }
              },
              (error) => {
                console.log(error);
                this.toastr.error("Failed");
              }
            );
          } else {
            this.toastr.error("Cart Empty Please add atleast one item");
          }
        }
      })
      .catch(() =>
        console.log(
          "User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)"
        )
      );
  }


  onCustomerAddressChange(){
   let selectedAddress = this.commonService.customerAddresses.find(x => x.id == this.customerSelectedAddressId);

    let body = {
      StoreId : Number(this.commonService.storeId),
      Latitude : Number(selectedAddress.latitude),
      Longitude : Number(selectedAddress.longitude)
    }
    
    this.dbService.methodPost("StoreSettings/CheckOrderDeliverable",body).subscribe(resdata =>{
      if(resdata.isDeliverable ==  true){
        this.deliveryAddressForm.controls.fullAddress.setValue(selectedAddress.address);
        this.mapAddress = selectedAddress.location;
        this.latitude = Number(selectedAddress.latitude);
        this.longitude = Number(selectedAddress.longitude);
        this.deliveryfee = resdata.deliveryCharge;
      }
      else{
        this.toastr.error(
          "Location is not deliverable"
        );
      }

    },error =>{
      this.toastr.error(
        "Location is not deliverable"
      );
      this.customerSelectedAddressId = 0;
    }
    )


 


  }
  
  onOrderTypeChange(key) {
    this.orderType = key;
    if (key == 0) {
      this.isDeliveryMode = false;
      this.deliveryfee = 0;
      this.calculateTotal();
    } else {
      this.isDeliveryMode = true;
      this.deliveryfee = Number(this.storeDetails.deliveryCost);
      this.calculateTotal();
    }
  }



  addressData ;
  addNewLocation(){
    if (localStorage.getItem("customer") == null) {
      if (this.commonService.orderModes == 2) {
        this.modalService.open(LoginAsGuestPopupComponent);
      } else {
        this.openSignUpPopup();
      }
    } else {


      const modalRef = this.modalService
      .open(DeliveryMapPopupComponent)
      modalRef.componentInstance.storeId = this.commonService.storeId;
      modalRef.componentInstance.latitude =  (this.latitude);
      modalRef.componentInstance.longitude = (this.longitude);
       modalRef.result.then(
      (result) => {

        
        this.latitude = result.lat;
        this.longitude = result.lng;
        this.mapAddress = result.location;
        this.deliverableResponse = result.deliveryResponse;
        this.deliveryfee = this.deliverableResponse.deliveryCharge;
        this.addressData = result.form;
        this.addressData.location = this.mapAddress;

        this.toastr.success('delivery charges updated !')
        this.calculateTotal();


      //  item.Note = result;
      //  localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      }
    )
    }
    
  } 
  editLocation(){
    const modalRef = this.modalService
      .open(DeliveryMapPopupComponent)
      modalRef.componentInstance.storeId = this.commonService.storeId;
      modalRef.componentInstance.addressData = this.addressData;

      modalRef.componentInstance.latitude =  (this.latitude);
      modalRef.componentInstance.longitude = (this.longitude);

       modalRef.result.then(
      (result) => {

        
        this.latitude = result.lat;
        this.longitude = result.lng;
        this.mapAddress = result.location;
        this.deliverableResponse = result.deliveryResponse;
        this.deliveryfee = this.deliverableResponse.deliveryCharge;
        this.addressData = result.form;
        this.addressData.location = this.mapAddress;

        this.toastr.success('delivery charges updated !')
        this.calculateTotal();


      //  item.Note = result;
      //  localStorage.setItem("cart", JSON.stringify(this.commonService.cartList));
      }
    )
  } 

  onAddMore() {
    if (this.commonService.orderModes == 2) {
      this.router.navigate(["/menus"], {
        queryParams: {
          storeId: this.storeDetails.id,
          tableid: this.commonService.tableId,
          viewstate: "qrcode",
        },
      });
    } else if (this.commonService.orderModes == 1) {
      this.router.navigate(["/menus"], {
        queryParams: { storeId: this.storeDetails.id, viewstate: "qrcode" },
      });
    } else if (this.commonService.orderModes == 0) {
      this.router.navigate(["/menus"], {
        queryParams: { storeId: this.storeDetails.id },
      });
      
    }
    else if (this.commonService.orderModes == 4) {
      this.router.navigate(["/menus"], {
        queryParams: { storeId: this.storeDetails.id , companyMode : true},
      });
      
    }
    else if (this.commonService.orderModes == 3) {
      this.router.navigate(["/menus"], {
        queryParams: { storeId: this.storeDetails.id, officeMode: true },
      });
    }
  }
 


}


