<owl-carousel-o [options]="thumbnailSlider">
    <ng-container>
        <ng-container *ngFor="let item of categoryList">
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="theme-category-item">
                        <a  (click)="routCategory(item.id)">
                            <img class="img-fluid" [src]="item.imageUrl" alt="">
                            <h6>{{item.category}}</h6>
                            <!-- <p>156</p> -->
                         </a>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        
        <!-- <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/food-715542_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/pancake-1984716_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/pancakes-2291908_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/restaurant-690569_1280.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/spaghetti-2931846_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/food-712665_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/food-715542_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/pancake-1984716_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/pancakes-2291908_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/restaurant-690569_1280.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide">
                <div class="theme-category-item">
                    <a href="#">
                        <img class="img-fluid" src="../../assets/img/foods/spaghetti-2931846_640.jpg" alt="">
                        <h6>American</h6>
                        <p>156</p>
                     </a>
                </div>
            </div>
        </ng-template> -->
    </ng-container>
</owl-carousel-o>
