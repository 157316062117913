<ng-template #congrats let-modals>
   <div class="modal-header">
      <h4 class="modal-title-1" id="modal-basic-title">Congratulations</h4>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="modals.dismiss('Cross click')">
         <span aria-hidden="true" class="pop-up-close-bt">×</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="row d-flex justify-content-center align-items-center">

         <p class="pt-3 pr-2" *ngIf="storeDetails?.storeOffer?.discountPercent > 0">You will get
            {{storeDetails?.storeOffer?.discountPercent}} % offer on your order </p>
         <p class="pt-3 pr-2" *ngIf="storeDetails?.storeOffer?.flatDiscount > 0">You will get flat QR
            {{storeDetails?.storeOffer?.flatDiscount}} offer on your minimum of QR {{
            storeDetails?.storeOffer?.minBillAmount}} order </p>
         <a type="button" (click)="modals.dismiss('Cross click')" class="btn btn-outline-primary waves-effect"
            data-dismiss="modal">Ok, thanks</a>
      </div>
   </div>
</ng-template>


<!-- congrats discount popup ends -->


<ng-template #review1 let-modals>
   <div class="modal-content-color">
   <div class="modal-header ">
      <div class="col-12 ">
         <div>
            <h5 class="modal-title-1  pop_up_header-1" id="modal-basic-title"
               style="font-size: 21px; font-weight: 600;">
             

               <span *ngIf="storeDetails?.enableRating == true">
                  {{'label.OurRating' | translate}}
               </span>

               <span *ngIf="storeDetails?.enableRating == false">
                  {{'label.Feedbacks' | translate}}
               </span>
            
            </h5>
         </div>
         <div class="clear"></div>
         <div class="col-12" *ngIf="storeDetails?.enableRating == true">
            <ngb-rating [max]="5" [(rate)]="storeRating" [readonly]="true" style="font-size: 35px;"
               class="pop_up_header-rest"></ngb-rating>
         </div>
      </div>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="modals.dismiss('Cross click')">
         <span aria-hidden="true" class="pop-up-close-bt">×</span>
      </button>
   </div>
   <div class="modal-body">

      <span *ngIf="storeDetails?.canOrder == false">


         <div class="col-12" *ngIf="storeDetails?.enableRating == true">
            <div>
               <h5 class="modal-title-1 pop_up_header-1" id="modal-basic-title"
                  style="font-size: 16px; font-weight: 600;">{{'label.RateUs' | translate}}</h5>
            </div>
            <div class="col-12">

               <ngb-rating [max]="5" [(rate)]="storeRatingForm" [readonly]="false" [id]="'ab'" style="font-size: 35px;"
                  class="pop_up_header-rest"></ngb-rating>
            </div>


            <div class="col-md-12 float-left">

            </div>



         </div>

         <!--Body-->
         <div class="col-12 menu_header" style="margin-top:15px;">

            <h5 style="font-size: 14px;font-family: Verdana, Geneva, Tahoma, sans-serif;">{{'label.Writeareview' |
               translate}}</h5>
            <div *ngIf="!commonService.isLoggedIn" style="margin-bottom: 15px;" class="menu-header-name">
               <label for="" >Name</label>
               <input type="text" style="border:dashed 1px rgb(102, 102, 102); width: 190px; height: 30px; margin: 0px 15px;" [(ngModel)]="customerNameReview" [ngModelOptions]="{standalone: true}">
            </div>
            <div *ngIf="!commonService.isLoggedIn" class="menu-header-name">
               <label for="">Mobile</label>
               <input type="text" style="border:dashed 1px rgb(102, 102, 102); width: 190px; height: 30px; margin: 0px 10px;" [(ngModel)]="customerMobileReview" [ngModelOptions]="{standalone: true}"> 
            </div>
            
              
             



         
            <div class="review-view">

               <textarea cols="80" rows="5" [(ngModel)]="review" [ngModelOptions]="{standalone: true}"
                  class="pop_up_header" style="border:dashed 1px rgb(102, 102, 102);">
               {{'label.Writeareview' | translate}}
         </textarea>

            </div>

            <a type="button" (click)="onReviewFormSubmit()"
               class="btn btn-outline-primary-pop waves-effect">{{'button.Submit' | translate}}</a>
         </div>
      </span>
      <div class="col-12 menu_header" style="margin-top: 30px;" *ngIf="storeDetails?.settings?.showReviews == true">
         <h5 style="font-size: 14px; font-weight: 700; ">{{'label.Reviews'| translate}}</h5>



         <div class="scroll">
            <div *ngFor="let item of storeRatingList; let i = index;">
               <div class="review-view-inner">
                  <div class="col-12">

                     <div class="col-8  rating-pop-1">
                        <h5>{{item?.customerName}}</h5>
                     </div>

                     <span *ngIf="commonService.isLoggedIn && commonService.customer.id == item.customerId"
                        (click)="deleteRating(item.id ,0 , index)">
                        <div class="col-2 rating-pop"><img src="../../../assets/mall/img/delete.png" class="img-width">
                        </div>
                     </span>


                     <div class="col-2  rating-pop" *ngIf="storeDetails?.enableRating">
                        <span class="text-custom-white rectangle-tag bg-gradient-green">
                           {{item?.rating}}
                        </span>
                     </div>



                  </div>
               </div>


               <div class="review-view-inner-text" style="margin-bottom: 15px; padding: 10px;">
                  <div class="col-12">
                     {{item?.review}}
                     <div class="rating-pop">{{item?.submittedOn | date :'dd-MM-yyyy'}}</div>
                  </div>
                  <div class="clear"></div>
               </div>
            </div>
         </div>


      </div>




   </div>
</div>

</ng-template>





<ng-template #review2 let-modals>
   <div class="modal-header">
      <div class="col-12">
         <div>
            <h5 class="modal-title-1 pop_up_header-1" id="modal-basic-title" style="font-size: 21px; font-weight: 600;">
               <span *ngIf="lang == 0"> {{selectedProductReview?.productName}}</span>
               <span *ngIf="lang == 1"> {{selectedProductReview?.productNameAr}}</span>
            </h5>
         </div>
         <div class="clear"></div>
         <div class="col-12">
            <div class="product-list-rating">
               <ngb-rating [max]="5" [(rate)]="selectedProductReviewRating" [readonly]="true" style="font-size: 35px;"
                  class="pop_up_header-rest"></ngb-rating>
            </div>
         </div>
      </div>

      <button type="button" class="pop-up-close" aria-label="Close" (click)="modals.dismiss('Cross click')">
         <span aria-hidden="true" class="pop-up-close-bt">×</span>
      </button>
   </div>
   <div class="modal-body">
      <span *ngIf="storeDetails?.canOrder == false">
         <div class="col-12">
            <div>
               <h5 class="modal-title-1 pop_up_header-1" id="modal-basic-title"
                  style="font-size: 16px; font-weight: 600;">Rate Product</h5>
            </div>
            <div class="col-12">
               <ngb-rating [max]="5" [(rate)]="storeRatingForm" [readonly]="false" style="font-size: 35px;"
                  class="pop_up_header-rest"></ngb-rating>
            </div>


            <div class="col-md-12 float-left">

            </div>



         </div>

         <!--Body-->
         <div class="col-12 menu_header" style="margin-top:15px;">

            <h5 style="font-size: 14px;font-family: Verdana, Geneva, Tahoma, sans-serif;">{{'label.Writeareview' |
               translate}}</h5>


           
            <div class="review-view">

               <textarea cols="80" rows="5" [(ngModel)]="review" [ngModelOptions]="{standalone: true}"
                  style="text-align: left; margin-left: -15px;border:dashed 1px rgb(102, 102, 102);">
                  {{'label.Writeareview' | translate}}
            </textarea>
            </div>
            <a type="button" (click)="onReviewFormSubmitForProdct()"
               class="btn btn-outline-primary waves-effect">{{'button.Submit' | translate}} </a>
         </div>

      </span>


      <div class="col-12 menu_header" style="margin-top: 30px;" *ngIf="storeDetails?.settings?.showReviews == true">
         <h5 style="font-size: 14px; font-weight: 700; font-family: Verdana, Geneva, Tahoma, sans-serif;">
            {{'label.Reviews' | translate}}</h5>


         <div class="scroll">
            <div *ngFor="let item of productRatingList; let i = index;">
               <div class="review-view-inner">
                  <div class="col-12">
                     <div class="col-8 rating-pop-1">
                        <h5>{{item?.customerName}}</h5>
                     </div>


                     <span *ngIf="commonService.isLoggedIn && commonService.customer.id == item.customerId"
                        (click)="deleteRating(item.id ,1 , index , item.productId)">
                        <div class="col-2 rating-pop"><img src="../../../assets/mall/img/delete.png" class="img-width">
                        </div>
                     </span>


                     <div class="col-2 rating-pop">
                        <span class="text-custom-white rectangle-tag bg-gradient-green">
                           {{item?.rating}}
                        </span>
                     </div>



                  </div>
               </div>
               <div class="review-view-inner-text" style="margin-bottom: 15px; padding: 10px;">
                  <div class="col-12">
                     {{item?.review}}
                     <div class=" rating-pop">{{item?.submittedOn | date :'dd-MM-yyyy'}}</div>
                  </div>
                  <div class="clear"></div>
               </div>
            </div>

         </div>




      </div>
   </div>
</ng-template>






<div class="modal fade modal-mobile-dish-search-sm show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg">
      <div class="modal-content mobile-search-box-form">
         <div class="dish-search p-3">
            <div id="#menu">
               <form class="explore-outlets-search">
                  <div class="input-group">
                     <input type="text" [(ngModel)]="searchKey" [ngModelOptions]="{standalone: true}"
                        (keyup)="onProductSearch()" (focus)="showDishResult()" placeholder="Search for dishes..."
                        class="form-control">
                     <div class="input-group-append">
                        <button type="button" class="btn btn-link">
                           <i class="fa fa-search"></i>
                        </button>
                     </div>
                  </div>
               </form>
               <div class="p-0" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="dishResult">
                  <div class="row">
                     <div class="col-12">
                        <ng-container *ngFor="let item of productSearchList; let i = index;">
                           <div class="container">
                              <div class="row border-bottom" *ngIf="i < 4">
                                 <div class="col-4 col-sm-2 ">
                                    <div class="product-image embed-responsive embed-responsive-1by1 ml-0">
                                       <a (click)="getProductDetailsForPopup(item)" #popbuttonProductDetails
                                          data-toggle="modal" data-target=".modal-product-details">
                                          <!--  -->
                                          <img [src]="item.imageUrl"
                                             class="card-img-top embed-responsive-item image-border-radius"
                                             alt="{{item.productName}}">
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-8 col-sm-10 pr-2 p-0">
                                    <div class="pt-2 pb-2">
                                       <h6 class="mb-1"><a (click)="getProductDetailsForPopup(item)"
                                             #popbuttonProductDetails data-toggle="modal"
                                             data-target=".modal-product-details">
                                             <span *ngIf="lang == 0"> {{item?.productName}}</span>
                                             <span *ngIf="lang == 1"> {{item?.productNameAr}}</span>

                                          </a></h6>
                                       <p class="text-gray mb-0">{{item.type}}
                                          <span *ngIf="item?.variants.length == 0">
                                             <del
                                                *ngIf="item?.discountPercent || item?.flatDiscount">{{commonService.currency}}
                                                {{item?.orginalPrice}} </del>
                                             | {{commonService.currency}}{{item.price | number:'1.2-2'}}
                                          </span>
                                       </p>


                                       <button *ngIf="
                                          item.cartItem == 0 &&
                                          storeDetails?.isClosed == false && 
                                          item.isAvailable == true &&  item?.variants.length > 0
                                          " class=" btn-add-to-cart-1" #popbuttonProductDetails data-toggle="modal"
                                          data-target=".modal-product-details"
                                          (click)="getProductDetailsForPopup(item)">+
                                          Choose</button>
                                       <button *ngIf="
                                          item.cartItem == 0 &&
                                          storeDetails?.isClosed == false && 
                                          storeDetails?.canOrder == true && 
                                          item.isAvailable == true &&  item?.variants.length == 0
                                          " class=" btn-add-to-cart-1" (click)="addToCart(item)">+
                                          Add</button>
                                       <span *ngIf="item.cartItem != 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true && 
                                          item.isAvailable == true &&  item?.variants.length == 0"
                                          class="count-number ">
                                          <button class="btn btn-outline-secondary  btn-sm left dec"
                                             (click)="onMinusQty(item)"> - </button>
                                          <input class="count-number-input" type="text" [value]="item.cartItem"
                                             readonly="">
                                          <button class="btn btn-outline-secondary btn-sm right inc"
                                             (click)="onPlusQty(item)"> + </button>
                                       </span>
                                       <span *ngIf="item.isAvailable == false" class="badge badge-danger-bt">Out Of
                                          Stock</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-container>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </div>
</div>

<section class="restaurant-detailed-banner" *ngIf="storeDetails?.showVideoBanner">
   <div class="img-fluid cover">
      <!-- <iframe  width="100%" height="100%" src="https://www.youtube.com/embed/JhMZ7zO_eiE?autoplay=1&loop=1" frameborder="0" allowfullscreen></iframe> -->
      <video class="video" width="100%" height="auto"  playsinline autoplay fullscreen muted loop controls id="vid" oncanplay="this.play()"  >
         <!-- <source src="http://clips.vorwaerts-gmbh.de/big_buck_bunny.webm" type="video/webm">
         <source src="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" type="video/mp4"> -->

         <source [src]="storeDetails?.videoBannerUrl" type="video/mp4">
      </video>


      <div class="col-12" style="z-index:99 ;">



         <div class="col-10 menu_header">
            <ngb-rating [max]="5" *ngIf="storeDetails?.enableRating == true" (click)="review1Open()"
               [(rate)]="storeRating" [readonly]="true" style="font-size: 25px;"></ngb-rating>
            <!-- <span class="text-custom-white rectangle-tag bg-gradient-green"
                  style="padding: 5px;" (click)="review1Open()">
                 {{storeRating}}
               </span> -->

         </div>

         <div class="col-2 menu_header">

            <!-- <a  style="cursor: pointer" (click)="review1Open(0)" class="btn-info-round ml-0"><i class="fa fa-address-card"
                  aria-hidden="true"></i></a> -->
         </div>


      </div>
      <!-- <div class="restaurant-detailed-header__docked">

         <div class="text-white mb-0 restaurant-detailed-ratings">

            <a href="#restaurant-info" class="btn-info-round ml-0"><i class="fa fa-address-card"
                  aria-hidden="true"></i></a>
         </div>
      </div> -->
   </div>
   <div [class.restaurant-detailed-header]="storeDetails?.themeId == null || storeDetails?.themeId == 0">
      <div class="container">
         <div class="row d-flex align-items-end">
            <div class="col-md-12">
               <div class="restaurant-detailed-header-left"
                  *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0">

                  <!-- <img class="img-fluid mr-3 float-left mb-0 d-none d-sm-block" alt="Spice Hut Indian Restaurant"
                     src="{{storeDetails?.logoUrl}}"> -->
                  <h2 class="text-white">


                     <span *ngIf="lang == 0"> {{storeDetails?.storeName}}</span>
                     <span *ngIf="lang == 1"> {{storeDetails?.storeNameAr}}</span>
                  </h2>
                  <p class="text-white mb-1 d-none d-sm-block"><i class="fa fa-location-pin"></i>

                     <span *ngIf="lang == 0"> {{storeDetails?.address}}</span>
                     <span *ngIf="lang == 1"> {{storeDetails?.addressAr}}</span>
                     <span *ngIf="storeDetails?.isClosed == false" class="badge badge-success-1">{{'tag.OPEN' |
                        translate}}</span>
                     <span *ngIf="storeDetails?.isClosed == true" class="badge badge-danger-bt">{{'tag.CLOSE' |
                        translate}}</span>

                  </p>

                  <!-- <p class="text-white mb-0 d-none d-sm-block"><i class="fa fa-food-cart"></i>
                     {{storeDetails?.description}}
                  </p> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!--Banner -->
<section class="restaurant-detailed-banner" *ngIf="!storeDetails?.showVideoBanner">
   <div class="text-center">
      <div class="container" style="position: relative;">


      </div>
      <div style="background-image: url({{storeDetails?.bannerUrl}}); position: relative;" class="img-fluid cover">
         <div class="restaurant-detailed-header__docked-inner">
            <div class="text-white mb-0 restaurant-detailed-ratings">

               <div class="col-12">



                  <div class="col-10 menu_header">
                     <ngb-rating [max]="5" *ngIf="storeDetails?.enableRating == true" (click)="review1Open()"
                        [(rate)]="storeRating" [readonly]="true" style="font-size: 25px;"></ngb-rating>
                     <!-- <span class="text-custom-white rectangle-tag bg-gradient-green"
                           style="padding: 5px;" (click)="review1Open()">
                          {{storeRating}}
                        </span> -->

                  </div>

                  <!-- <div class="col-2 menu_header feed-back">

                     <a style="cursor: pointer; font-size: 21px; height: 40px; width: 40px;"  *ngIf="storeDetails?.enableFeedback == true && storeDetails?.enableRating == false" (click)="review1Open(0)" class="btn-info-round ml-0 "><img src="../../assets/img/feed-back.png" title="Feedback">   </a> 

                    
                  </div> -->


               </div>

            </div>
         </div>
      </div>
   </div>
   <div [class.restaurant-detailed-header]="storeDetails?.themeId == null || storeDetails?.themeId == 0">
      <div class="container">
         <div class="row d-flex align-items-end">
            <div class="col-md-12">
               <div class="restaurant-detailed-header-left"
                  *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0">
                  <div class="col-md-12 menu_header-text">
                     <h2 class="text-white">

                        <span *ngIf="lang == 0"> {{storeDetails?.storeName}}</span>
                        <span *ngIf="lang == 1"> {{storeDetails?.storeNameAr}}</span>
                     </h2>
                  </div>

                  <div class="col-md-12 menu_header">

                     <p class="text-white mb-1 d-none d-sm-block"><i class="fa fa-location-pin"></i>
                        <span *ngIf="lang == 0"> {{storeDetails?.address}}</span>
                        <span *ngIf="lang == 1"> {{storeDetails?.addressAr}}</span>

                        <!-- <a (click)="review1Open()"> review 1 </a> -->

                        <span *ngIf="storeDetails?.isClosed == false" class="badge badge-success-1">{{'tag.OPEN' |
                           translate}}</span>
                        <span *ngIf="storeDetails?.isClosed == true" class="badge badge-danger-bt">{{'tag.CLOSE' |
                           translate}}</span>

                     </p>
                  </div>




               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!--Banner ends-->





<!-- section for menu header -->
<section class="offer-dedicated-nav bg-white-menu border-top-0 shadow-sm sticky-top">
   <div class="container">
      <div class="row">
         <div class=" transperent"></div>
         <div class="col-12 ">

            <!-- for image menu  -->

            <span *ngIf="storeDetails?.showImageMenu == true;">
               <owl-carousel-o [options]="menuSlider" [class]="'nav custom-tab1'">
                  <ng-template carouselSlide *ngIf="promotedProducts.length > 0">
                     <span class="padd">

                        <a (click)="toggleMenuBook($event)" class="nav-link tabs-menu active active-1" id="most-promoted"
                           data-toggle="pill" href="#most-promoted-dishes" role="tab"
                           aria-controls="most-promoted-dishes" aria-selected="true">

                           <div class="custom-tab-inner">
                              <div class="custom-tab-inner-box1">
                                 <img [src]="storeDetails?.settings?.mostPromotedUrl" id="cat-image">
                              </div>
                           </div>
                            {{'label.MostPromoted' | translate}} 
                        </a>

                     </span>



                  </ng-template>



                  <ng-container *ngFor="let item of menuDetails; let i=index;">
                     <ng-template carouselSlide *ngIf="item.isAddOn == false && ((forDine == true && item.dineItemCount > 0) || (forDine == false && item.deliveryItemCount > 0))">

                        <span class="padd">
                           <a (click)="toggleMenuBook($event)" class="nav-link tabs-menu menu-txt-color"
                              [class.active]="promotedProducts.length === 0 && i ==0" id="menu-one{{item?.categ}}"
                              data-toggle="pill" href="#{{item?.categ}}" role="tab" aria-controls="menu-one-book"
                              aria-selected="false">

                              <div class="custom-tab-inner">
                                 <div class="custom-tab-inner-box1"><img [src]="item?.imageUrl" id="cat-image"></div>

                              </div>
                              
                              <span *ngIf="lang == 0" style="border-bottom: none;"> {{item?.menuCategory}}</span>
                              <span *ngIf="lang == 1"> {{item?.menuCategoryAr}}</span>

                           </a></span>
                     </ng-template>
                  </ng-container>

               </owl-carousel-o>

            </span>

            <!-- end image menu -->


            <!-- for non image menu -->

            <span *ngIf="storeDetails?.showImageMenu != true;">

               <owl-carousel-o [options]="menuSlider" [class]="'nav custom-tab'">
                  <ng-template carouselSlide *ngIf="promotedProducts.length > 0">



                     <a (click)="toggleMenuBook($event)" class="nav-link active tabs-menu" id="most-promoted"
                        data-toggle="pill" href="#most-promoted-dishes" role="tab" aria-controls="most-promoted-dishes"
                        aria-selected="true">

                        {{'label.MostPromoted' | translate}}


                     </a>

                  </ng-template>

                  <ng-container *ngFor="let item of menuDetails; let i=index;">
                     <ng-template carouselSlide *ngIf="item.isAddOn == false  && ((forDine == true && item.dineItemCount > 0) || (forDine == false && item.deliveryItemCount > 0))">
                        <a (click)="toggleMenuBook($event)" class="nav-link tabs-menu"
                           [class.active]="promotedProducts.length === 0 && i ==0" id="menu-one{{item?.categ}}"
                           data-toggle="pill" href="#{{item?.categ}}" role="tab" aria-controls="menu-one-book"
                           aria-selected="false">
                           <span *ngIf="lang == 0"> {{item?.menuCategory}}</span>
                           <span *ngIf="lang == 1"> {{item?.menuCategoryAr}}</span>
                        </a>
                     </ng-template>
                  </ng-container>
               </owl-carousel-o>
            </span>

            <!-- end non image menu -->
         </div>
      </div>
   </div>
</section>

<!-- end menu header section -->


<section class="offer-dedicated-body pt-2 pb-2 mb-4">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class=" bg-white-menu  offer-dedicated-body-left">
               <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="most-promoted-dishes" role="tabpanel"
                     *ngIf="promotedProducts.length > 0" aria-labelledby="most-promoted">

                     <div class="row">
                        <h5 class="mb-2 mt-2 col-md-12 menu-header-txt">{{'label.MostPromoted' | translate}}</h5>
                        <div class="col-md-12">
                           <div class="shadow-sm mb-4 ">
                              
                              <ng-container *ngFor="let item of promotedProducts">
                                 <div class="border-bottom-1 media-width" *ngIf="(forDine == true && item.forDine == true) || (forDine == false && item.forDeliveryPickup == true)">
                                    <div class="row">
                                       <div class="col-4">
                                          <!-- embed-responsive embed-responsive-1by1 -->
                                          <div class="product-image embed-responsive embed-responsive-1by1">
                                             <a (click)="getProductDetailsForPopup(item)" #popbuttonProductDetails
                                                data-toggle="modal" data-target=".modal-product-details">
                                                <!-- card-img-top embed-responsive-item  -->
                                                <img src="{{item?.imageUrl}}"
                                                   class="image-border-radius card-img-top embed-responsive-item"
                                                   alt="{{item?.productName}}">
                                             </a>
                                          </div>
                                       </div>
                                       <div class="col-8 pr-2 p-0 menu_header">



                                          <div class="pt-2 pb-2 pl-2">
                                             <span class="mb-1 product-name product-name-menu"><a
                                                   (click)="getProductDetailsForPopup(item)" #popbuttonProductDetails
                                                   data-toggle="modal" data-target=".modal-product-details">

                                                   <span *ngIf="lang == 0"> {{item?.productName}}</span>
                                                   <span *ngIf="lang == 1"> {{item?.productNameAr}}</span>
                                                </a>
                                                <span *ngIf="item.isBestSeller == true"
                                                   class="badge-1 badge-danger-bt">{{'tag.BESTSELLER' |
                                                   translate}}</span>
                                             </span>
                                             <div class="clear"></div>
                                             <p class="text-black-1 mb-0 phone-number">

                                                <span *ngIf="item?.variants.length == 0">
                                                   <div class="product-list-title menu_header">
                                                      <del *ngIf="item?.discountPercent || item?.flatDiscount">
                                                         {{commonService.currency}}
                                                         {{item?.orginalPrice}} </del>
                                                   </div>

                                                   <div class="product-list-title menu_header ">

                                                     <span class="deqada-display">{{commonService.currency}}</span> 
                                                      {{item?.price | number:'1.2-2'}} </div>
                                                </span>
                                             </p>

                                             <div class="clear"></div>
                                             <span>


                                                
                                                <ngb-rating *ngIf="storeDetails?.enableRating == true && storeDetails?.enableProductRating == true" [max]="5"
                                                   (click)="review2Open(item)" [(rate)]="item.rating" [readonly]="true" style="margin-bottom: 5px;">
                                                </ngb-rating>



                                             </span>

                                             <div class="clear"></div>


                                             <a *ngIf="storeDetails?.isClosed == false  && item?.isAvailable == true && (item.addOns.single.length > 0 || item.addOns.multiple.length > 0 )"
                                                (click)="getProductDetailsForPopup(item)" #popbuttonProductAddOns
                                                data-toggle="modal" data-target=".modal-product-details-addons"
                                                class="btn-add-to-cart-1">++ {{'label.Add' | translate}}</a>

                                             <div class="clear"></div>

                                             <span
                                                *ngIf=" (item.addOns.single.length == 0 && item.addOns.multiple.length == 0 )">

                                                <a *ngIf="item.cartItem == 0 && storeDetails?.isClosed == false  && item?.isAvailable == true && item?.variants.length > 0 "
                                                   class=" btn-add-to-cart-1" (click)="getProductDetailsForPopup(item)"
                                                   #popbuttonProductDetails data-toggle="modal"
                                                   data-target=".modal-product-details">++ {{'label.Choose' |
                                                   translate}}</a>
                                                <a *ngIf="item.cartItem == 0 && storeDetails?.isClosed == false && !storeDetails?.showDesc &&  storeDetails?.canOrder == true && item?.isAvailable == true  && item?.variants.length == 0"
                                                   class=" btn-add-to-cart-1" (click)="addToCart(item)">+ {{'label.Add'
                                                   | translate}}</a>
                                                <a *ngIf="item.cartItem == 0 && storeDetails?.isClosed == false && storeDetails?.showDesc && storeDetails?.canOrder == true && item?.isAvailable == true  && item?.variants.length == 0"
                                                   class=" btn-add-to-cart-1" (click)="getProductDetailsForPopup(item)"
                                                   #popbuttonProductDetails data-toggle="modal"
                                                   data-target=".modal-product-details">+ {{'label.Add'
                                                   | translate}}</a>
                                                <span
                                                   *ngIf="item.cartItem != 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true && item?.variants.length == 0"
                                                   class="count-number">
                                                   <button class="btn btn-outline-secondary  btn-sm left dec"
                                                      (click)="onMinusQty(item)"> - </button>
                                                   <input class="count-number-input" type="text" [value]="item.cartItem"
                                                      readonly="">
                                                   <button class="btn btn-outline-secondary btn-sm right inc"
                                                      (click)="onPlusQty(item)"> + </button>
                                                </span>
                                             </span>

                                             <span *ngIf="item?.isAvailable == false" class="badge badge-danger-bt">Out
                                                Of
                                                Stock</span>

                                             <!-- <span style="cursor: pointer;" (click)="review2Open()">Review 2</span> -->
                                          </div>
                                       </div>
                                    </div>

                                    <!-- <i [ngClass]="{'text-danger': innerItem.type == 'Non-Veg' , 'text-success' : innerItem.type == 'Veg'}" class="fa fa-circle text-success food-item"></i> -->

                                 </div>

                              </ng-container>
                           </div>
                        </div>
                     </div>
                  </div>
                  <ng-container *ngFor="let item of menuDetails; let i = index;">
                     <div class="tab-pane fade" id="{{item.categ}}" role="tabpanel" 
                        [class.active]="promotedProducts.length === 0 && i ==0"
                        [class.show]="promotedProducts.length === 0 && i ==0" aria-labelledby="menu-one">
                        <div class="row">
                           <h5 class="mb-2 mt-2 col-md-12 menu-header-txt">
                              <span *ngIf="lang == 0"> {{item?.menuCategory}}</span>
                              <span *ngIf="lang == 1"> {{item?.menuCategoryAr}}</span>
                              <small class=" details-title-text-1">
                                 <span *ngIf="forDine == true">{{item.dineItemCount}}</span>
                                 <span *ngIf="forDine != true">{{item.deliveryItemCount}}</span>

                                 {{'label.ITEMS' |
                                 translate}}</small>
                           </h5>
                           <div class="col-md-12">
                              <div class="bg-white-menu shadow-sm mb-4">
                                 <ng-container *ngFor="let innerItem of item?.products">
                                    <div class="border-bottom-1 media-width" *ngIf="(forDine == true && innerItem.forDine == true) || (forDine == false && innerItem.forDeliveryPickup == true)">

                                       <!-- category -->
                                       <div class="row">
                                          <div class="col-4 menu_header">
                                             <!-- embed-responsive embed-responsive-1by1 -->
                                             <div class="product-image embed-responsive embed-responsive-1by1">
                                                <a (click)="getProductDetailsForPopup(innerItem)"
                                                   #popbuttonProductDetails data-toggle="modal"
                                                   data-target=".modal-product-details">
                                                   <!-- card-img-top embed-responsive-item  -->
                                                   <img src="{{innerItem?.imageUrl}}"
                                                      class="image-border-radius card-img-top embed-responsive-item"
                                                      alt="{{innerItem?.productName}}">
                                                </a>
                                             </div>
                                          </div>
                                          <div class="col-8 pr-2 p-0 menu_header">
                                             <div class="pt-2 pb-2 pl-2">
                                                <span class="mb-1 product-name product-name-menu"><a
                                                      (click)="getProductDetailsForPopup(innerItem)"
                                                      #popbuttonProductDetails data-toggle="modal"
                                                      data-target=".modal-product-details">

                                                      <span *ngIf="lang == 0"> {{innerItem?.productName}}</span>
                                                      <span *ngIf="lang == 1"> {{innerItem?.productNameAr}}</span>
                                                   </a>
                                                   <span *ngIf="innerItem.isBestSeller == true"
                                                      class="badge-1 badge-danger-bt">{{'tag.BESTSELLER' |
                                                      translate}}</span>
                                                </span>
                                                <div class="clear"></div>
                                                <p class="text-black-1 mb-0 phone-number">


                                                   <span *ngIf="innerItem?.variants.length == 0">
                                                      <del
                                                         *ngIf="item?.discountPercent || item?.flatDiscount">{{commonService.currency}}
                                                         {{innerItem?.orginalPrice}} </del>
                                                         <span class="deqada-display"> {{commonService.currency}}</span>
                                                         <span> {{innerItem?.price | number:'1.2-2'}} </span>
                                                   </span>
                                                </p>
                                                <div class="clear"></div>
                                                <span>


                                                   <ngb-rating *ngIf="storeDetails?.enableRating == true && storeDetails?.enableProductRating == true" [max]="5"
                                                      (click)="review2Open(innerItem)" [(rate)]="innerItem.rating"
                                                      [readonly]="true" style="margin-bottom:5px;"></ngb-rating>

                                                   <!-- <ngx-star-rating (click)="review2Open(innerItem)" [(ngModel)]="innerItem.rating" [ngModelOptions]="{standalone: true}" [id]="'rating'"
                                                      [disabled]="'true'" style="font-size: 6px;"></ngx-star-rating> -->


                                                </span>


                                                <div class="clear" ></div>



                                                <!-- for add ons -->
                                                <a *ngIf="storeDetails?.isClosed == false  && innerItem?.isAvailable == true && (innerItem.addOns.single.length > 0 || innerItem.addOns.multiple.length > 0 ) && innerItem.isCombo != true "
                                                   #popbuttonProductAddOns data-toggle="modal"
                                                   data-target=".modal-product-details-addons"
                                                   (click)="getProductDetailsForPopup(innerItem)"
                                                   class=" btn-add-to-cart-1">+
                                                   + {{'label.Add' | translate}}</a>
                                                <!-- for add ons end -->


                                                <!-- for combo -->
                                                <a *ngIf="storeDetails?.isClosed == false  && innerItem?.isAvailable == true && innerItem.isCombo == true    && !(innerItem.addOns.single.length > 0 || innerItem.addOns.multiple.length > 0 )"
                                                   #popbuttonProductCombo data-toggle="modal"
                                                   data-target=".modal-product-details-combo"
                                                   (click)="getProductDetailsForPopup(innerItem)"
                                                   class=" btn-add-to-cart-1">+
                                                   + {{'label.Choose' | translate}} </a>

                                                <!-- combo end  -->



                                                <!-- for both combo and addons -->
                                                <a *ngIf="storeDetails?.isClosed == false  && innerItem?.isAvailable == true && innerItem.isCombo == true    && (innerItem.addOns.single.length > 0 || innerItem.addOns.multiple.length > 0 )"
                                                   #popbuttonProductCombo data-toggle="modal"
                                                   data-target=".modal-product-details-addon-combo-multy"
                                                   (click)="getProductDetailsForPopup(innerItem)"
                                                   class=" btn-add-to-cart-1">+
                                                   + {{'label.Choose' | translate}} </a>

                                                <!-- both combo addons end  -->

                                                <div class="clear"></div>
                                                <span
                                                   *ngIf=" (innerItem.addOns.single.length == 0 && innerItem.addOns.multiple.length == 0 && innerItem.isCombo == false )">



                                                   <a *ngIf="innerItem.cartItem == 0 && storeDetails?.isClosed == false  && innerItem?.isAvailable == true && innerItem?.variants.length > 0"
                                                      class=" btn-add-to-cart-1" #popbuttonProductDetails
                                                      data-toggle="modal" data-target=".modal-product-details"
                                                      (click)="getProductDetailsForPopup(innerItem)">+
                                                      + {{'label.Choose' | translate}}</a>

                                                   <a *ngIf="innerItem.cartItem == 0 && storeDetails?.isClosed == false && !storeDetails?.showDesc && storeDetails?.canOrder == true && innerItem?.isAvailable == true && innerItem?.variants.length == 0"
                                                      class=" btn-add-to-cart-1" (click)="addToCart(innerItem)">+
                                                      {{'label.Add' | translate}}</a>

                                                   <a *ngIf="innerItem.cartItem == 0 && storeDetails?.isClosed == false && storeDetails?.showDesc && storeDetails?.canOrder == true && innerItem?.isAvailable == true && innerItem?.variants.length == 0"
                                                      class=" btn-add-to-cart-1" #popbuttonProductDetails
                                                      data-toggle="modal" data-target=".modal-product-details"
                                                      (click)="getProductDetailsForPopup(innerItem)">+
                                                      {{'label.Add' | translate}}</a>

                                                   <span
                                                      *ngIf="innerItem.cartItem != 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true && innerItem?.variants.length == 0"
                                                      class="count-number add-det">
                                                      <button class="btn btn-outline-secondary  btn-sm left dec"
                                                         (click)="onMinusQty(innerItem)"> - </button>
                                                      <input class="count-number-input" type="text"
                                                         [value]="innerItem.cartItem" readonly="">
                                                      <button class="btn btn-outline-secondary btn-sm right inc"
                                                         (click)="onPlusQty(innerItem)"> + </button>
                                                   </span>


                                                </span>
                                                <span *ngIf="innerItem?.isAvailable == false"
                                                   class="badge badge-danger-bt">Out Of Stock</span>
                                             </div>
                                          </div>
                                       </div>


                                       <!-- <i [ngClass]="{'text-danger': innerItem.type == 'Non-Veg' , 'text-success' : innerItem.type == 'Veg'}" class="fa fa-circle text-success food-item"></i> -->

                                    </div>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                     </div>
                  </ng-container>
               </div>
            </div>
         </div>
      </div>



      <div class="row">
         <div class="col-md-12 menu_header">
            <div id="restaurant-info" class="bg-white-menu rounded shadow-sm p-4 mb-4 ">
               <span class="mb-4 bg-white-menu-txt footer-add-txt">

                  <span *ngIf="lang == 0"> {{storeDetails?.storeName}}</span>
                  <span *ngIf="lang == 1"> {{storeDetails?.storeNameAr}}</span>

               </span>
               <div class="clear"></div>
               <span class="mb-2 footer-add-txt"><i class="fa fa-food-cart"></i>
                  <span *ngIf="lang == 0"> {{storeDetails?.description}}</span>
                  <span *ngIf="lang == 1"> {{storeDetails?.descriptionAr}}</span> </span>

               <div class="clear"></div>

               <span class="mb-2 footer-add-txt">
                  <span *ngIf="lang == 0"> {{storeDetails?.address}}</span>
                  <span *ngIf="lang == 1"> {{storeDetails?.addressAr}}</span>
               </span>
               <div class="clear"></div>
               <span class="mb-2 footer-add-txt phone-number"><i class="fa fa-phone text-primary-1 mr-2"></i>
                  {{storeDetails?.phone1}}
                  <span *ngIf="storeDetails?.phone2">,</span>
                  {{storeDetails?.phone2}}
               </span>
               <div class="clear"></div>
               <span class="mb-2 footer-add-txt"><i class="fa fa-envelope text-primary-1 mr-2"></i>
                  {{storeDetails?.email1}}
                  <span *ngIf="storeDetails?.email2">,</span>
                  {{storeDetails?.email2}}
               </span>

               <div class="clear"></div>
               <span *ngIf="storeDetails?.isDelivery == true" class="mb-2  footer-add-txt"><i class="fa fa-clock-o text-primary-1 mr-2"></i>
                  <span >
                     {{'label.Deliveryon' | translate}} {{storeDetails?.deliveryStartTime}} -
                     {{storeDetails?.deliveryEndTime}}
                  </span>
                  <!-- <span *ngIf="storeDetails?.isDelivery == false" class="badge badge-danger-bt">{{'tag.NODELIVERY' |
                     translate}}</span> -->

                  &nbsp;
                  <!-- <span *ngIf="storeDetails?.isClosed == false" class="badge badge-success-1">
                     
                     {{'tag.OPEN' |
                     translate}}</span>

                  <span *ngIf="storeDetails?.isClosed == true" class="badge badge-danger-bt">{{'tag.CLOSE' |
                     translate}}</span> -->

               </span>
               <div class="clear"></div>

               <span class="mb-2  footer-add-txt"><i class="fa fa-clock-o text-primary-1 mr-2"></i>
                  <!-- <span >
                     {{'label.Daily' | translate}} 5.00 AM - 11.00 PM
                    ( {{'label.Friday' | translate}} 1.00 PM - 11.00 PM )
                  </span> -->
                  <span >
                     {{'label.OpenOn' | translate}} {{storeDetails?.openTime}} -
                     {{storeDetails?.closeTime}}
                  </span>
                  &nbsp;
                  <span *ngIf="storeDetails?.isClosed == false" class="badge badge-success-1">
                     
                     {{'tag.OPEN' |
                     translate}}</span>

                  <span *ngIf="storeDetails?.isClosed == true" class="badge badge-danger-bt">{{'tag.CLOSE' |
                     translate}}</span>

               </span>

               <div class="clear"></div>
               <span *ngIf="storeDetails?.enableFeedback == true && storeDetails?.enableRating == false" (click)="review1Open(0)" class="mb-2 footer-add-txt"><i class="fa fa-edit text-primary-1 mr-2"></i>
                 <a  class="footer-add-txt"> Please share your experience </a>
               </span>




               <!-- <p>
                  <a *ngIf="storeDetails?.facebookURL" target="blank" href="{{storeDetails?.facebookURL}}"><i
                        class="fa fa-facebook    social-icons mr-2"></i></a>
                  <a *ngIf="storeDetails?.twitterURL" target="blank" href="{{storeDetails?.twitterURL}}"><i
                        class="fa fa-twitter social-icons mr-2"></i></a>
                  <a *ngIf="storeDetails?.instagramURL" target="blank" href="{{storeDetails?.instagramURL}}"><i
                        class="fa fa-instagram social-icons mr-2"></i></a>
               </p> -->
               <p class="mb-2 text-black" *ngIf="storeDetails?.showTableStatus">Table Count :
                  {{storeDetails?.tableCount}}
               </p>
               <p class="mb-2 text-black" *ngIf="storeDetails?.showTableStatus">Available Table Count :
                  {{storeDetails?.emptyTableCount}}
               </p>
               <p>
                  <a class="vivira" href="{{storeDetails?.facebookURL}}" target="blank"
                     *ngIf="storeDetails?.facebookURL"><i class="fa fa-facebook-official"></i></a>
                  <a class="vivira" href="{{storeDetails?.twitterURL}}" target="blank"
                     *ngIf="storeDetails?.twitterURL"><i class="fa fa-tumblr-square"></i></a>
                  <a class="vivira" href="{{storeDetails?.instagramURL}}" target="blank"
                     *ngIf="storeDetails?.instagramURL"><i class="fa fa-instagram"></i></a>
               </p>
               <hr class="clearfix">
               <p *ngIf="storeDetails?.menuUrl" class="text-black mb-0">You can also view by our complete menu by
                  clicking here &nbsp;&nbsp;&nbsp; <a class="text-info font-weight-bold"
                     href="{{storeDetails?.menuUrl}}">View Menu</a></p>

               <hr class="clearfix">
               <!-- <p class="mb-0 promote promote-2">
                  <img src="../assets/img/caution.png" style="width:20px !important; height:20px !important;">
                  {{'desc.AlertAllergies' | translate}}
               </p> -->

               <!-- <p *ngIf="storeDetails?.enableFeedback == true && storeDetails?.enableRating == false" (click)="review1Open(0)" style="cursor: pointer">{{'label.Feedbacks' | translate}}</p> -->
               <!-- <hr class="clearfix"> -->
               <!-- <h5 class="mt-4 mb-4">More Info</h5> -->
               <!-- <p class="mb-3">{{storeDetails?.description}}</p> -->
               <!-- <div class="border-btn-main mb-4" style="cursor: pointer;">
                  <a class="border-btn text-success mr-2"><i class="fa fa-check"></i> Breakfast</a>
                  <a class="border-btn text-danger mr-2"><i class="fa fa-close"></i> No Alcohol Available</a>
                  <a class="border-btn text-success mr-2"><i class="fa fa-check"></i> Vegetarian Only</a>
                  <a class="border-btn text-success mr-2"><i class="fa fa-check"></i> Indoor Seating</a>
                  <a class="border-btn text-success mr-2" ><i class="fa fa-check"></i> Breakfast</a> 
                  <a class="border-btn text-danger mr-2" ><i class="fa fa-close"></i> No Alcohol Available</a> 
                  <a class="border-btn text-success mr-2" ><i class="fa fa-check"></i> Vegetarian Only</a> 
               </div> -->
               <div class="address-map float-right ml-5" *ngIf="storeDetails?.mapUrl">
                  <div class="mapouter">
                     <div class="gmap_canvas">
                        <iframe width="300" id="gmap_canvas" src="{{storeDetails?.mapUrl}}" frameborder="0"
                           scrolling="no" marginheight="0" marginwidth="0">
                        </iframe>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Tab ends -->
<!-- <span *ngIf="storeDetails?.canOrder == true" (click)="onCartClick()" class="btn-add-to-cart-icon">
   <div class="col-6">Checkout</div>
   <div class="col-4">Rates</div>

   </span> -->

  <a *ngIf="storeDetails?.canOrder == true" (click)="onCartClick()" class="btn-add-to-cart-icon fa fa-cart-plus"
   style="font-size: 21px;"><!--  <div class="round" >1</div>--> </a> 


<!---add button-->


<!-- Product description -->

<div class="modal fade show modal-product-details" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg ">

      <div class="modal-content modal-content-color">
         <div class="modal-header">
            <h5 class="modal-title-1" id="modal-basic-title">


               <span *ngIf="lang == 0"> {{selectedProductDetails?.productName}}</span>
               <span *ngIf="lang == 1"> {{selectedProductDetails?.productNameAr}}</span>


            </h5>
            <button type="button" id="close-button" class="pop-up-close " aria-label="Close" data-dismiss="modal">
               <span aria-hidden="true" class="pop-up-close-bt">×</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="container">

               <div >
                  <div class="col-xs-12 col-sm-6 col-lg-6 product_img">
                     <div class="geeks">
                        <!-- <iframe *ngIf="safeURL" width="360" height="315" [src]="safeURL" frameborder="0" allowfullscreen></iframe> -->
                        <iframe *ngIf="selectedProductDetails?.videoUrl" width="100%" height="100%" [src]="selectedProductDetails.videoUrl" frameborder="0" allowfullscreen></iframe>
   
                        <!-- <video playsinline autoplay muted loop controls id="vid">
                           <source [src]="safeURL" type="video/webm">
                           <source [src]="safeURL" type="video/mp4">
                        </video> -->
                        <img  *ngIf="!selectedProductDetails?.videoUrl" [src]="selectedProductDetails?.imageUrl" class=" img img-responsive card-img-top alt=">
                     </div>
   
   
                  </div>
                  <div class="col-xs-12 col-sm-6 col-lg-6 menu_header menu-header-name" style="margin-top: 10px; margin-top: 10px;">
                     <span *ngIf="selectedProductDetails?.hotLevel === 1" class="product_content">
                        <img src="../assets/img/pepper.png" style="height: 20px; width: 20px;" class=" product_content">
                        <img src="../assets/img/pepper-d.png" style="height: 20px; width: 20px;">
                        <img src="../assets/img/pepper-d.png" style="height: 20px; width: 20px;">
                     </span>
                     <span *ngIf="selectedProductDetails?.hotLevel === 2" class="product_content">
                        <img src="../assets/img/pepper.png" style="height: 20px; width: 20px;">
                        <img src="../assets/img/pepper.png" style="height: 20px; width: 20px;">
                        <img src="../assets/img/pepper-d.png" style="height: 20px; width: 20px;">
                     </span>
                     <span *ngIf="selectedProductDetails?.hotLevel === 3" class="product_content">
                        <img src="../assets/img/pepper.png" style="height: 20px; width: 20px;">
                        <img src="../assets/img/pepper.png" style="height: 20px; width: 20px;">
                        <img src="../assets/img/pepper.png" style="height: 20px; width: 20px;">
   
   
   
                     </span>
                     <div class="row" style="margin-top: 10px;">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                           <span *ngIf="selectedProductDetails?.isBestSeller == true"
                              class="badge badge-dark-1">Promoted</span>
                           <span *ngIf="selectedProductDetails?.isAvailable == false"
                              class="badge badge-danger-bt menu_header">Out Of
                              Stock </span>
                        </div>
   
                     </div>
   
                     <div *ngIf="lang ==0"  [innerHtml]="description"></div>
                     <div *ngIf="lang ==1"  [innerHtml]="descriptionAr"></div>
   
                    
   
                   
   
                     <!-- <p class="menu_header-pop padd-popup" *ngIf="lang ==0">{{selectedProductDetails?.description}}
   
   
                     </p>
                     <p class="menu_header-pop padd-popup" *ngIf="lang ==1">{{selectedProductDetails?.descriptionAr}}</p>
    -->
   
   
   
                     <div class="row" *ngIf="selectedProductDetails?.variants.length > 0">
                        <ng-container *ngFor="let item of selectedProductDetails?.variants">
   
   
                           <div class="col-12 col-sm-12">
                              <div style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin: 5px !important;">
                                 <div class="row">
                                    <div class="col-12 ">
   
   
                                       <a (click)="getProductDetailsForPopup(item)" data-toggle="modal"
                                          data-target=".modal-product-details"> <i class="fa fa-circle"
                                             style="color:red !important;" aria-hidden="true"></i>
                                          <span *ngIf="lang == 0" class="promote-1"> {{item?.variant}}</span>
                                          <span *ngIf="lang == 1" class="promote-1"> {{item?.variantAr}}</span>
   
   
   
                                       </a><br>
   
                                    </div>
                                    <div class="col-5">
                                       <del *ngIf="item?.discountPercent || item?.flatDiscount">{{commonService.currency}}
                                          {{item?.orginalPrice}} </del>
                                       <span class="price-txt"><span class="deqada-display">{{commonService.currency}} </span> {{item?.price | number:'1.2-2'}}
                                       </span>
                                    </div>
                                    <div class="col-3">
                                       <span *ngIf="item.isBestSeller == true"
                                          class="badge badge-danger-bt">{{'tag.BESTSELLER' | translate}}</span>
   
                                    </div>
   
                                    <div class="col-4">
                                       <div style="margin-bottom:5px !important;">
                                          <a *ngIf="item.cartItem == 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true && item?.isAvailable == true"
                                             class=" btn-add-to-cart-1" (click)="addToCartVariant(item)">+ {{'label.Add' |
                                             translate}}</a>
                                          <span
                                             *ngIf="item.cartItem != 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true"
                                             class="count-number">
                                             <button class="btn btn-outline-secondary  btn-sm left dec"
                                                (click)="onMinusQtyVariant(item)"> - </button>
                                             <input class="count-number-input" type="text" [value]="item.cartItem"
                                                readonly="">
                                             <button class="btn btn-outline-secondary btn-sm right inc"
                                                (click)="onPlusQtyVariant(item)"> + </button>
                                          </span>
                                          <span *ngIf="item?.isAvailable == false" class="badge badge-danger-bt">Out Of
                                             Stock</span>
                                       </div>
                                    </div>
   
                                 </div>
                              </div>
                           </div>
                        </ng-container>
   
   
   
   
   
   
   
   
                     </div>
   
                  </div>
               </div>


            </div>
            
         </div>


         <div class="modal-footer">
            <div class="modal-footer__button-container" *ngIf="selectedProductDetails?.variants.length === 0">
               <button
                  *ngIf="selectedProductDetails?.cartItem == 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true && selectedProductDetails?.isAvailable == true && selectedProductDetails?.isCombo != true"
                  type="button" class="btn btn-primary popup-close-bt-1" (click)="addToCart(selectedProductDetails)">+
                  {{'label.Add' | translate}}</button>
               <span
                  *ngIf="selectedProductDetails?.cartItem != 0 && storeDetails?.isClosed == false && storeDetails?.canOrder == true"
                  class="count-number ">
                  <button class="btn btn-outline-secondary  btn-sm left dec mr-0 mt-0"
                     (click)="onMinusQty(selectedProductDetails)"> - </button>
                  <input class="count-number-input mt-0 " type="text" [value]="selectedProductDetails?.cartItem"
                     readonly="">
                  <button class="btn btn-outline-secondary btn-sm right inc mt-0"
                     (click)="onPlusQty(selectedProductDetails)"> + </button>
               </span>
               <button type="button" class="btn btn-secondary popup-close-bt " data-dismiss="modal">{{'button.Close' |
                  translate}}</button>
            </div>
         </div>
      </div>
   </div>
</div>


<div class="modal fade show modal-product-details-addons" tabindex="-1" role="dialog"
   aria-labelledby="mySmallModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-lg">

      <div class="modal-content modal-content-color">
         <div class="modal-header">
            <h5 class="modal-title-1" id="modal-basic-title">

               <span *ngIf="lang == 0"> {{selectedProductDetails?.productName}}</span>
               <span *ngIf="lang == 1"> {{selectedProductDetails?.productNameAr}}</span>
            </h5>
            <button type="button" class="pop-up-close" aria-label="Close" data-dismiss="modal">
               <span aria-hidden="true" class="pop-up-close-bt">×</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row">
               <div class="col-md-5 product_img">
                  <div class="geeks">
                     <img [src]="selectedProductDetails?.imageUrl" class=" img img-responsive card-img-top alt=">
                  </div>


               </div>
               <div class="col-md-7 product_content" style="padding-top: 8px; padding-bottom: 8px;">


                  <div class="row">

                     <div class="col-12" style="margin-left: -12px;">
                        <div class="col-6 pop_up_header-4">
                           <span *ngIf="storeDetails?.isClosed == false && storeDetails?.canOrder == true"
                              class="count-number ">
                              <button class="btn btn-outline-secondary  btn-sm left dec mr-0 mt-0"
                                 (click)="onMinusQtyAddOnCount()"> - </button>
                              <input class="count-number-input mt-0" type="text" [value]="countForAddOn" readonly="">
                              <button class="btn btn-outline-secondary btn-sm right inc mt-0"
                                 (click)="onPlusQtyAddOnCount()"> + </button>
                           </span>

                        </div>
                        <div class="col-6 pop_up_header-5" style="font-size: 17px; font-weight: bold;">QR :
                           {{selectedProductDetails?.price}}</div>
                     </div>




                     <div class="scroll">

                        <div class="col-12" *ngIf="selectedProductDetails?.addOns.single.length > 0">
                           <div
                              style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin-top: 5px !important; padding-bottom: 10px;">
                              <strong style="font-size: 15px;" class="popup-choose">

                                 <span *ngIf="lang == 0 && selectedProductDetails?.singleAddonHeader">
                                    {{selectedProductDetails?.singleAddonHeader}}
                              </span>
                              <span *ngIf="lang == 1 && selectedProductDetails?.singleAddonHeader">
                                    {{selectedProductDetails?.singleAddonHeaderAr}}
                              </span>

                              <span *ngIf="(lang == 0 && !selectedProductDetails?.singleAddonHeader ) || (lang == 1 && !selectedProductDetails?.singleAddonHeader )">
                                 {{'label.AddOn' | translate}} - {{'label.ChooseOne' | translate}} 
                              </span>
                         
                              
                              </strong>
                              <div class="row" style="margin-top: 10px;">
                                 <div class="choose-option">
                                    <span style="margin-bottom: 5px;">
                                       <div class="row"
                                          *ngFor="let item of selectedProductDetails?.addOns.single; let i =index;">

                                          <div class="col-6 popup-choose-4">

                                             <span *ngIf="lang == 0">{{item?.productName}}</span>
                                             <span *ngIf="lang == 1">{{item?.productNameAr}}</span>

                                          </div>
                                          <div class="col-3 menu-popup-1">
                                             QR : {{item?.price}}</div>

                                          <div class="col-1">
                                             <label class="custom-checkbox menu-popup-2">
                                                <input type="checkbox" [(ngModel)]="item.selected"
                                                   (change)="addOnChangesSingle(item , i , $event)"
                                                  >
                                                <span class="checkmark check-padd-poup-1"></span></label>
                                          </div>
                                       </div>

                                    </span>
                                 </div>
                              </div>
                           </div>
                        </div>



                        <div class="col-12" *ngIf="selectedProductDetails?.addOns.multiple.length > 0">
                           <div style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin-top: 5px !important; padding-bottom: 10px;">
                              <strong class="menu-popup" style="font-size: 15px;">

                                 <span *ngIf="lang == 0 && selectedProductDetails?.multiAddonHeader">
                                       {{selectedProductDetails?.multiAddonHeader}}
                                 </span>
                                 <span *ngIf="lang == 1 && selectedProductDetails?.multiAddonHeader">
                                       {{selectedProductDetails?.multiAddonHeaderAr}}
                                 </span>
                            
                                 

                                 <span *ngIf="(lang == 0 && !selectedProductDetails?.multiAddonHeader ) || (lang == 1 && !selectedProductDetails?.multiAddonHeader )">
                                    {{'label.AddOn' | translate}} - {{'label.Optional' | translate}}
                                 </span>
                                
                              
                              
                              </strong>
                              <div class="row" style="margin-top: 10px; margin-bottom: 15px;">
                                 <div class="choose-option">
                                    <span style="margin-bottom: 5px;">
                                       <div class="row"
                                          *ngFor="let item of selectedProductDetails?.addOns.multiple; let i =index;">

                                          <div class="col-6 menu-popup-4" style="font-size: 13px;">
                                             <span *ngIf="lang == 0">{{item?.productName}} </span>
                                             <span *ngIf="lang == 1"> {{item?.productNameAr}}</span>

                                          </div>
                                          <div class="col-3 menu-popup-1" style="font-size: 13px; ">
                                             QR : {{item?.price}}</div>

                                          <div class="col-1" *ngIf="storeDetails?.canOrder == true">
                                             <label class="custom-checkbox menu-popup-2">
                                                <input type="checkbox" [(ngModel)]="item.selected"
                                                   (change)="addOnChangesMulty(item  , $event)">
                                                <span class="checkmark check-padd-poup"></span></label>
                                          </div>
                                       </div>

                                    </span>



                                 </div>




                              </div>
                           </div>
                        </div>



                     </div>


                     <div class="col-12">
                        <div class="choose-option-rate">

                           <div class="col-6 pop_up_header-6">
                              <div class="add-rate">QR {{productDetailsTotalWithAddOn | number:'1.2-2'}} </div>
                           </div>
                           <div class="col-6 pop_up_header-7" (click)="addToCartAddOnsNew(selectedProductDetails)"
                              *ngIf="storeDetails?.canOrder == true">
                              <div class="add-rate">{{'button.AddtoCart' | translate}}</div>
                           </div>
                           <button style="display: none;" id="close-pop" type="button"
                              class="btn btn-secondary float-right" data-dismiss="modal">{{'button.Close' |
                              translate}}</button>

                        </div>
                     </div>







                  </div>

               </div>
            </div>
         </div>



      </div>
   </div>
</div>




<div class="modal fade show modal-product-details-combo" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg">

      <div class="modal-content modal-content-color">
         <div class="modal-header">
            <h5 class="modal-title-1" id="modal-basic-title">

               Combo :-
               <span *ngIf="lang == 0"> {{selectedProductDetails?.productName}}</span>
               <span *ngIf="lang == 1"> {{selectedProductDetails?.productNameAr}}</span>
            </h5>
            <button type="button" class="pop-up-close" aria-label="Close" data-dismiss="modal">
               <span aria-hidden="true" class="pop-up-close-bt">×</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row">
               <div class="col-md-6 product_img">
                  <div class="geeks">
                     <img [src]="selectedProductDetails?.imageUrl" class=" img img-responsive card-img-top alt=">
                  </div>


               </div>
               <div class="col-md-6 product_content menu-header-name" style="padding-top: 8px; padding-bottom: 8px;">
                  <!-- <p style="padding-top: 10px;">{{selectedProductDetails?.description}}</p> -->


                  <div class="row">

                     <div class="col-12" style="margin-left: -12px;">
                        <div class="col-6 pop_up_header-4">
                           <!-- <a  class="btn btn-sm  btn-add-to-cart" (click)="addToCartAddOnsNew(selectedProductDetails)">+ {{'label.Add' | translate}}</a> -->
                           <span *ngIf="storeDetails?.isClosed == false && storeDetails?.canOrder == true"
                              class="count-number ">
                              <button class="btn btn-outline-secondary  btn-sm left dec mr-0 mt-0"
                                 (click)="onMinusQtyComboCount()"> - </button>
                              <input class="count-number-input mt-0" type="text" [value]="countForCombo" readonly="">
                              <button class="btn btn-outline-secondary btn-sm right inc mt-0"
                                 (click)="onPlusQtyComboCount()"> + </button>
                           </span>

                        </div>
                        <div class="col-6 pop_up_header-5" style="font-size: 17px; font-weight: bold;">QR :
                           {{selectedProductDetails?.price}}</div>
                     </div>




                     <div class="scroll">

                    <ng-container *ngFor="let item of selectedProductDetails?.comboMenus">
                           <div class="col-12" *ngIf="item.isActive == true">
                              <div
                                 style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin-top: 5px !important; padding-bottom: 10px;">
                                 <strong style="font-size: 15px;" class="menu-header-txt-2">

                                    <span *ngIf="lang == 0"> {{item?.comboMenu}}</span>
                                    <span *ngIf="lang == 1"> {{item?.comboMenuAr}}</span>
                                   ({{'label.Choose' | translate}}) </strong>
                                 <div class="row" style="margin-top: 10px;">
                                    <div class="choose-option">
                                       <span style="margin-bottom: 5px;">
                                          <div class="row" *ngFor="let innerItem of item.comboProducts ; let i =index;">

                                             <div class="col-9 menu-header-txt-1">

                                                <span *ngIf="lang == 0"> {{innerItem?.comboProduct}}</span>
                                                <span *ngIf="lang == 1"> {{innerItem?.comboProductAr}}</span>

                                             </div>
                                             <!-- <div class="col-3"
                                                style="float: left;  text-align: left; font-family: calibri;font-size: 15px; ">
                                                QR : {{item?.price}}</div> -->
                                             <div class="col-1" style="float: left;">
                                                <!-- <input type="checkbox" [(ngModel)]="innerItem.selected" (change)="comboChanges(item,innerItem , i , $event)"> -->

                                                <label class="container takeaway payment-mode-padd">
                                                   <input type="checkbox" [disabled]="item.comboProducts.length ==1" [(ngModel)]="innerItem.selected"
                                                      (change)="comboChanges(item,innerItem , i , $event)">
                                                   <span class="checkmark check-padd-poup"></span></label>
                                             </div>
                                          </div>

                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-container>








                     </div>


                     <div class="col-12">
                        <div class="choose-option-rate">

                           <div class="col-6 pop_up_header-6">
                              <div class="add-rate">QR {{productDetailsTotalWithAddOn | number:'1.2-2'}} </div>
                           </div>
                           <div class="col-6 pop_up_header-7" (click)="addToCartCombo(selectedProductDetails)"
                              *ngIf="storeDetails?.canOrder == true">
                              <div class="add-rate">{{'button.AddtoCart' | translate}}</div>
                           </div>
                           <button style="display: none;" id="close-pop-combo" type="button"
                              class="btn btn-secondary float-right" data-dismiss="modal">{{'button.Close' |
                              translate}}</button>

                        </div>
                     </div>







                  </div>

               </div>
            </div>
         </div>



      </div>
   </div>
</div>



<div class="modal fade show modal-product-details-addon-combo-multy" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg">

      <div class="modal-content modal-content-color">
         <div class="modal-header">
            <h5 class="modal-title-1" id="modal-basic-title">

               Multy :-
               <span *ngIf="lang == 0"> {{selectedProductDetails?.productName}}</span>
               <span *ngIf="lang == 1"> {{selectedProductDetails?.productNameAr}}</span>
            </h5>
            <button type="button" class="pop-up-close" aria-label="Close" data-dismiss="modal">
               <span aria-hidden="true" class="pop-up-close-bt">×</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row">
               <div class="col-md-6 product_img">
                  <div class="geeks">
                     <img [src]="selectedProductDetails?.imageUrl" class=" img img-responsive card-img-top alt=">
                  </div>


               </div>
               <div class="col-md-6 product_content menu-header-name" style="padding-top: 8px; padding-bottom: 8px;">
                  <div class="row">

                     <div class="col-12" style="margin-left: -12px;">
                        <div class="col-6 pop_up_header-4">
                           <span *ngIf="storeDetails?.isClosed == false && storeDetails?.canOrder == true"
                              class="count-number ">
                              <button class="btn btn-outline-secondary  btn-sm left dec mr-0 mt-0"
                                 (click)="onMinusQtyComboCount()"> - </button>
                              <input class="count-number-input mt-0" type="text" [value]="countForCombo" readonly="">
                              <button class="btn btn-outline-secondary btn-sm right inc mt-0"
                                 (click)="onPlusQtyComboCount()"> + </button>
                           </span>

                        </div>
                        <div class="col-6 pop_up_header-5" style="font-size: 17px; font-weight: bold;">QR :
                           {{selectedProductDetails?.price}}</div>
                     </div>




                     <div class="scroll">


                        <ng-container *ngFor="let item of selectedProductDetails?.comboMenus">
                           <div class="col-12" *ngIf="item.isActive == true">
                              <div
                                 style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin-top: 5px !important; padding-bottom: 10px;">
                                 <strong style="font-size: 15px;" class="popup-choose">

                                    <span *ngIf="lang == 0"> {{item?.comboMenu}}</span>
                                    <span *ngIf="lang == 1"> {{item?.comboMenuAr}}</span>
                                   ({{'label.Choose' | translate}}) </strong>
                                 <div class="row" style="margin-top: 10px;">
                                    <div class="choose-option">
                                       <span style="margin-bottom: 5px;">
                                          <div class="row" *ngFor="let innerItem of item.comboProducts ; let i =index;">

                                             <div class="col-9 menu-header-txt-1">

                                                <span *ngIf="lang == 0"> {{innerItem?.comboProduct}}</span>
                                                <span *ngIf="lang == 1"> {{innerItem?.comboProductAr}}</span>

                                             </div>
                                             <!-- <div class="col-3"
                                                style="float: left;  text-align: left; font-family: calibri;font-size: 15px; ">
                                                QR : {{item?.price}}</div> -->
                                             <div class="col-1" style="float: left;">
                                                <!-- <input type="checkbox" [(ngModel)]="innerItem.selected" (change)="comboChanges(item,innerItem , i , $event)"> -->

                                                <label class="container takeaway payment-mode-padd">
                                                   <input type="checkbox" [(ngModel)]="innerItem.selected"
                                                   [disabled]="item.comboProducts.length == 1"
                                                      (change)="comboChanges(item,innerItem , i , $event)">
                                                   <span class="checkmark check-padd-poup"></span></label>
                                             </div>
                                          </div>

                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ng-container>






                           <div class="col-12" *ngIf="selectedProductDetails?.addOns.single.length > 0">
                              <div
                                 style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin-top: 5px !important; padding-bottom: 10px;">
                                 <strong style="font-size: 15px;" class="popup-choose">
                                    
                                  
                                 <span *ngIf="lang == 0 && selectedProductDetails?.singleAddonHeader">
                                    {{selectedProductDetails?.singleAddonHeader}}
                              </span>
                              <span *ngIf="lang == 1 && selectedProductDetails?.singleAddonHeader">
                                    {{selectedProductDetails?.singleAddonHeaderAr}}
                              </span>


                              <span *ngIf="(lang == 0 && !selectedProductDetails?.singleAddonHeader ) || (lang == 1 && !selectedProductDetails?.singleAddonHeader )">
                                 {{'label.AddOn' | translate}} - {{'label.ChooseOne' | translate}}
                              </span>
                         
                               
                                 
                                 
                                 </strong>
                                 <div class="row" style="margin-top: 10px;">
                                    <div class="choose-option">
                                       <span style="margin-bottom: 5px;">
                                          <div class="row"
                                             *ngFor="let item of selectedProductDetails?.addOns.single; let i =index;">
   
                                             <div class="col-5 popup-choose-4">
   
                                                <span *ngIf="lang == 0">{{item?.productName}}</span>
                                                <span *ngIf="lang == 1">{{item?.productNameAr}}</span>
   
                                             </div>
                                             <div class="col-4 popup-choose-2" >
                                                QR : {{item?.price}}</div>
                                             <div class="col-1 popup-choose-1">
                                                <label class="container">
                                                   <input type="checkbox" [(ngModel)]="item.selected"
                                                      (change)="addOnChangesSingle(item , i , $event)"
                                                      style="margin-left: -6px;">
                                                   <span class="checkmark check-padd-poup-1"></span></label>
                                             </div>
                                          </div>
   
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
   
   
   
                           <div class="col-12" *ngIf="selectedProductDetails?.addOns.multiple.length > 0">
                              <div
                                 style="border-bottom: 1px solid rgba(102, 102, 102, 0.521); margin-top: 5px !important; padding-bottom: 10px;">
                                 <strong style="font-size: 15px;" class="popup-choose">
                                    
                                    
                                    <span *ngIf="lang == 0 && selectedProductDetails?.multiAddonHeader">
                                       {{selectedProductDetails?.multiAddonHeader}}
                                 </span>
                                 <span *ngIf="lang == 1 && selectedProductDetails?.multiAddonHeader">
                                       {{selectedProductDetails?.multiAddonHeaderAr}}
                                 </span>
                            
                                 

                                 <span *ngIf="(lang == 0 && !selectedProductDetails?.multiAddonHeader ) || (lang == 1 && !selectedProductDetails?.multiAddonHeader )">
                                    {{'label.AddOn' | translate}} - {{'label.Optional' | translate}}
                                 </span>

                                 
                                 
                                 </strong>
                                 <div class="row" style="margin-top: 10px; margin-bottom: 15px;">
                                    <div class="choose-option">
                                       <span style="margin-bottom: 5px;">
                                          <div class="row"
                                             *ngFor="let item of selectedProductDetails?.addOns.multiple; let i =index;">
   
                                             <div class="col-5 popup-choose-4">
                                                <span *ngIf="lang == 0"> {{item?.productName}} </span>
                                                <span *ngIf="lang == 1"> {{item?.productNameAr}}</span>
   
                                             </div>
                                             <div class="col-4 popup-choose-2">
                                                QR : {{item?.price}}</div>
   
                                             <div class="col-1 popup-choose-1" *ngIf="storeDetails?.canOrder == true">
                                                <label class="container">
                                                   <input style="margin-left: -6px;" type="checkbox"[(ngModel)]="item.selected"
                                                      (change)="addOnChangesMulty(item  , $event)">
                                                   <span class="checkmark check-padd-poup-1"></span></label>
                                             </div>
                                          </div>
   
                                       </span>
   
   
   
                                    </div>
   
   
   
   
                                 </div>
                              </div>
                           </div>
   
   
   


                     </div>


                     <div class="col-12">
                        <div class="choose-option-rate">

                           <div class="col-6 pop_up_header-6">
                              <div class="add-rate">QR {{productDetailsTotalWithAddOn | number:'1.2-2'}} </div>
                           </div>
                           <div class="col-6 pop_up_header-7" (click)="addToCartAddComboMulty(selectedProductDetails)"
                              *ngIf="storeDetails?.canOrder == true">
                              <div class="add-rate">{{'button.AddtoCart' | translate}}</div>
                           </div>
                           <button style="display: none;" id="close-pop-combo-addon-multy" type="button"
                              class="btn btn-secondary float-right" data-dismiss="modal">{{'button.Close' |
                              translate}}</button>

                        </div>
                     </div>







                  </div>

               </div>
            </div>
         </div>



      </div>
   </div>
</div>




<!-- Button to Open the Modal -->
<button type="button" class="btn btn-primary" id="openModalButton" style="display: none;" data-toggle="modal"
   data-target="#welcomeHeadImage">
   Open modal

   <img [src]="" alt="storeDetails?.imageUrl">
</button>
<button type="button" class="btn btn-primary" id="openModalButtonInstagram" style="display: none;" data-toggle="modal"
   data-target="#instagram-follow-popup">
   Open modal

</button>
<app-restaurent-welcome-page [storeDetails]="storeDetails"></app-restaurent-welcome-page>
<!-- <app-instagram-follow-popup [storeDetails]="storeDetails"></app-instagram-follow-popup> -->
