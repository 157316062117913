import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup;
  constructor(private router : Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService : AuthService
    ) {}

  ngOnInit(): void {
    this.buildSignUpForm();

  }

  signUp() {
    this.authService.addCustomer(this.signUpForm.value).subscribe(resdata =>{
      
      if(resdata.id> 0){
        this.toastr.success('Succesfully ed . You Can Login');
        this.gotoLogin();
      }
    })


  }

  get f() {
    return this.signUpForm.controls;
  }

  buildSignUpForm() {
    this.signUpForm = this.fb.group({
      StoreID: [0],
      Name: ['', [Validators.required]],
      Phone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(8),
        ],
      ],
      Email: [''],
      Address: [''],
      Location: [''],
      SubmitBy: [''],
      SubmitOn: [new Date()],
      CustomerName: [''],
      domainUrl:[window.location.href]

    });
  }

  gotoLogin() {
    var phoneno = this.signUpForm.get('Phone').value
    this.router.navigate(['/login'], { queryParams: { phone: phoneno } });
  }
}
