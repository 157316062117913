

<nav class="navbar navbar-expand-lg navbar-light theme-nav-1 shadow-sm sticky-top  ">
	<div class="container">
		<a class="navbar-brand header-top" *ngIf="lang==0" >
          <img *ngIf="!companyDetails?.logoUrl" src="../../../assets/img/logo.png">
          <!-- <img *ngIf="!companyDetails?.logoUrl && themeId == 1034" src="../../../assets/img/english-creper.png"> -->
          <!-- <img *ngIf="!companyDetails?.logoUrl && themeId == 1035" src="../../../assets/img/english-pepper.png"> -->

          <img *ngIf="companyDetails?.logoUrl" [src]="companyDetails?.logoUrl">
         </a>

         <a class="navbar-brand header-top" *ngIf="lang==1" >
            <img *ngIf="!companyDetails?.logoUrl" src="../../../assets/img/logo-ar.png">
            <!-- <img *ngIf="!companyDetails?.logoUrl && themeId == 1034" src="../../../assets/img/arabic-creper.png"> -->
            <!-- <img *ngIf="!companyDetails?.logoUrl && themeId == 1035" src="../../../assets/img/arabic-pepper.png"> -->
            <img *ngIf="companyDetails?.logoUrl" [src]="companyDetails?.logoUrl">
           </a>
		<div>
         <!-- <button *ngIf="commonService.isRestuaretDetailsPage" type="button" class="navbar-toggler" data-toggle="modal" data-target=".modal-mobile-dish-search-sm">
				<i style="color:#004f2f;" class="fa fa-search"></i>
         </button> -->
         <!-- *ngIf="(router.url.includes('menu') || router.url.includes('cart')) && commonService.deliveryIconPropretiesShow == true "  -->
         <span class="language-text" *ngIf="commonService.deliveryIconPropretiesShow == true && (commonService.orderModes == 1 || commonService.orderModes == 4)" >

            <a *ngIf="commonService.deliverable" class="header-txt-4"><span class="fa fa-truck" style="font-size: 25px;"></span></a>
            <a *ngIf="!commonService.deliverable"><img src="../../../assets/mall/img/bike.png"  style="height: 30px; width: 30px" ></a> 
           
           
      </span>
 

         <span class="navbar-toggler language-text" *ngIf="commonService.showMultyLang">

            <a (click)="setLang(1)" class="language-text">العربية | </a>
            <a (click)="setLang(0)" class="language-text" >English</a> 
           
           
      </span>

       



			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				<span  class="navbar-toggler-icon"></span>
			</button>
		</div>

		<div class="collapse navbar-collapse header-txt" id="navbarNavDropdown" >
			<ul class="navbar-nav ml-auto">
          
           
				<li class="nav-item header-txt-1" >

					<a (click)="openSignUpPopup()" class="header-txt "role="button" *ngIf="!commonService.isLoggedIn && commonService.orderModes != 2" >
						<span class="nav-theme-pic rounded-pill fa fa-user"></span> {{'header.Register' | translate}}
					</a></li>
				<li class="nav-item login-header header-txt-2" *ngIf="!commonService.isLoggedIn && commonService.orderModes != 2">
					<a (click)="onLoginPopUp()" role="button" >
            			<span class="nav-theme-pic rounded-pill fa fa-lock" ></span> {{'header.Login' | translate}}
					</a>
             </li>

             <li class="nav-item whish-header  header-txt-3  ">
					<a (click)="onWishList()"  role="button" >
            			<span class="nav-theme-pic rounded-pill fa fa-heart" ></span> {{'header.Wishlist' | translate}}
					</a>
             </li>





             <li class="nav-item header-txt-4 " *ngIf="!commonService.isLoggedIn && commonService.orderModes == 2">
					<a (click)="onLoginAsGuestPopUp()" role="button">
            			<span class="nav-theme-pic rounded-pill fa fa-user header-txt"></span> {{'header.GuestLogin' | translate}}
					</a>
    			</li>
			
				<li class="nav-item dropdown nav-link-1 header-txt-5" *ngIf="commonService.isLoggedIn">
					<a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<span class="nav-theme-pic rounded-pill fa fa-user"></span> {{'header.MyAccount' | translate}}
					</a>
					<div class="dropdown-menu dropdown-menu-right  shadow-sm border-0">
                  <div class=" drop-down-popup">

						<a class="dropdown-item drop-text " routerLink="/my-account" style="color: black;">{{'My Account' | translate}}</a>

						<a class="dropdown-item drop-text " routerLink="/orderHistory" style="color: black;">{{'header.Orders' | translate}}</a>
                 
						<a class="dropdown-item drop-text" style="cursor: pointer;" (click)="logOut()" style="color: black;">{{'header.LogOut' | translate}}</a>
               </div>

          </div>
        		</li>


				<li class="nav-item dropdown dropdown-cart cart-header nav-link-1 header-txt-6">
					<a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
					<i class=" nav-theme-pic rounded-pill fa fa-shopping-basket"></i> {{'header.Cart' | translate}}
					<span class="badge badge-success-1">{{count}}</span>
					</a>
					<div class="dropdown-menu dropdown-cart-top modal-content-color p-0 dropdown-menu-right shadow-sm border-0 ">
						<div class="dropdown-cart-top-header p-4 drop-down-cart">
						<span class="fa fa-cart"></span>
						<h6 class="mb-0">
                  
                  <span *ngIf="lang == 0">
                     {{commonService?.storeDetails?.storeName}}
                  </span>
                  <span *ngIf="lang == 1">
                     {{commonService?.storeDetails?.storeNameAr}}
                  </span>


                  </h6>
						<p class="text-secondary-1 mb-0">
                     <span *ngIf="lang == 0">
                        {{commonService?.storeDetails?.address}}
                     </span>
                     <span *ngIf="lang == 1">
                        {{commonService?.storeDetails?.addressAr}}
                     </span>
                  </p>
            <small *ngIf="commonService?.cartList.length > 0"><a class="text-primary-1 font-weight-bold" (click)="onAddMore()">{{'label.ViewFullMenu' | translate}}</a></small>
						<small *ngIf="commonService?.cartList.length == 0"><a class="text-primary-1 font-weight-bold">{{'label.NoItemsInYourCart' | translate}}</a></small>

						</div>
                  <div class="clear"></div>
						<div class="dropdown-cart-top-body border-top p-2">
              <ng-container *ngFor="let item of commonService.cartList">
                <p class="mb-2"><i  class="icofont-ui-press text-danger food-item"></i> {{item.ProductName}} x {{item.Quantity}}   
                  <span class="float-right text-secondary-1 phone-number">{{commonService.currency}} {{item.TotalPrice | number:'1.2-2'}}</span></p>
              </ng-container>
						</div>
                  <div class="clear"></div>
						<div class="dropdown-cart-top-footer border-top p-2">
						<p class="mb-0 font-weight-bold text-secondary-1 ">
                     <span class="drop-down-cart">{{'label.SubTotal' | translate}}
                       
                      <span class="float-right text-dark-1 phone-number menu_header-padd ">{{commonService.currency}}{{commonService.itemTotal | number:'1.2-2'}}</span></span>
                     </p>
						
                  
                  <small class="menu_header ">{{'label.ExtraChargesMayApply' | translate}}</small>
						</div>
                  <div class="clear"></div>
						<div class="dropdown-cart-top-footer border-top p-2">
						<a class="btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2" (click)="onCartClick()" > {{'button.Checkout' | translate}}</a>
						</div>
					</div>
				</li>

            <li class="dropdown dropdown-cart lang-header nav-link-1 header-txt language" *ngIf="commonService.showMultyLang">
					<a  class="dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					{{'header.Language'| translate}}
					
					</a>
					<div class="dropdown-menu">
						
                  <li  class="nav-item "><a (click)="setLang(0)" >English</a></li>
                  <li  class="nav-item "><a (click)="setLang(1)">العربية</a></li>
						
						
						
					</div>
				</li>




           
			</ul>
		</div>
	</div>
</nav>
<!-- Header section ends here-->

<div class="modal fade modal-mobile-search-sm show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content p-5 mobile-search-box-form">
			<app-search-box></app-search-box>
		</div>
	</div>
</div>
