<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'header.Login' | translate}}</h4>
    <button type="button" class="pop-up-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
       <span aria-hidden="true" class="pop-up-close-bt">×</span>
    </button>
 </div>
 <div class="modal-body">
  

<div class="login d-flex align-items-center py-5">
    <div  class="container">
      <form [formGroup]="signInForm" >
        <ng-container >
            <div class="form-label-group">
                <input formControlName="Name" type="email" id="userName" class="form-control" placeholder="Vigil">
                <label for="userName">{{'label.Name' | translate}} <span class="required">*</span>
                </label>
             </div>
           <div class="row">
              <div class="col-3 pr-0">
                 <div class="form-label-group">
                    <input formControlName="CountryCode" type="text" id="countryCode" class="form-control" placeholder="" disabled>
                    <label for="countryCode">{{'label.ISDCode' | translate}}</label>
                 </div>
              </div>
              <div class="col-9">
                 <div class="form-label-group">
                    <input  formControlName="Phone" type="Phone" id="Phone" class="form-control" placeholder="+123 456 7890">
                    <label for="Phone">{{'label.Mobile' | translate}}</label>
                 </div>
              </div>
              <a   class=" btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2"  (click)="login()">{{'button.Login' | translate}}</a>
           </div>
      </ng-container>
      </form>
  
    </div>
  
  </div>
  
 </div>