import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  BaseURL: string;
  TokenUrl: string;
  constructor(private http: HttpClient , private meta : Meta) {
    this.BaseURL = this.getApiURL();
    this.TokenUrl = this.getApiURL();
  } 
  getApiURL() {
    return 'https://beta.edinemenu.com/webapi/api/';
    // return 'https://edinemenu.com/webapi/api/';
    // return 'https://test.edinemenu.com/webapi/api/';
    // return 'https://sohocoffee.edinemenu.com/webapi/api/';
    //return 'http://localhost:61920/api/';
  }
 
  getConnectionURL() {
    // return 'https://test.edinemenu.com/webapi/';
    return 'https://beta.edinemenu.com/webapi/';
    // return 'https://sohocoffee.edinemenu.com/webapi/';
  }
// 
  methodGet(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    // console.log(this.BaseURL + url)
    return this.http.get(this.BaseURL + url, httpOptions);
  }

  methodOptions(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.options(this.BaseURL + url, httpOptions);
  }

  methodDelete(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http.delete(this.BaseURL + url, httpOptions);
  }
  
  methodPost(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPostFormData(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPut(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPutFormData(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }

  // handleError(error) {
  //   console.log(error);
  //   let errorMessage =
  //     "Failed !  url :" + error.url + "  Message : " + error.error.Message;
  //   window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }
}
