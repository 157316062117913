import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';



@NgModule({
  declarations: [ConfirmationDialogComponent, NotificationPopupComponent],
  imports: [
    CommonModule
    
  ],
  entryComponents:[ConfirmationDialogComponent]
})
export class SharedModule { }
