import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { RestaurantsDetailComponent } from './restaurants-detail/restaurants-detail.component';
import { RestaurentServicesComponent } from './views/restaurent-services/restaurent-services.component';
import { WishlistComponent } from './views/wishlist/wishlist.component';


const routes: Routes = [
 
  { path: 'menu', component: RestaurentServicesComponent, },

  { path: 'menus', component: RestaurantsDetailComponent, },
  { path: 'cart', component: CartComponent, },
  { path: 'orderHistory', component: OrderHistoryComponent, },
  



  //views//

 


  // {
  //   path: 'creper-and-pepper',
  //   loadChildren: () => import('./views/creper-and-pepper/creper-and-pepper.module')
  //     .then(m => m.CreperAndPepperModule)
  // },
 
  // {
  //   path: 'menus',
  //   loadChildren: () => import('./views/white-sugar-one/white-sugar-one.module')
  //     .then(m => m.WhiteSugarOneModule)
  // },
  // {
  //   path: 'menus-in-category',
  //   loadChildren: () => import('./views/white-sugar-two/white-sugar-two.module')
  //     .then(m => m.WhiteSugarTwoModule)
  // },
  // {
  //   path: 'products',
  //   loadChildren: () => import('./views/white-sugar-three/white-sugar-three.module')
  //     .then(m => m.WhiteSugarThreeModule)
  // },
  // {
  //   path: 'menus',
  //   loadChildren: () => import('./views/gazebo-one/gazebo-one.module')
  //     .then(m => m.GazeboOneModule)
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./views/gazebo-two/gazebo-two.module')
  //     .then(m => m.GazeboTwoModule)
  // },
  // {
  //   path: 'products',
  //   loadChildren: () => import('./views/gazebo-three/gazebo-three.module')
  //     .then(m => m.GazeboThreeModule)
  // },
  {
    path: 'menus-in-category',
    loadChildren: () => import('./views/gazebo-four/gazebo-four.module')
      .then(m => m.GazeboFourModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./views/my-account/my-account.module')
      .then(m => m.MyAccountModule)
  },
 
  {
    path: 'expired',
    loadChildren: () => import('./views/expired/expired.module')
      .then(m => m.ExpiredModule)
  },
  {
    path: 'pre-booking',
    loadChildren: () => import('./views/pre-booking/pre-booking.module')
      .then(m => m.PreBookingModule)
  },

  // {
  //   path: '',
  //   loadChildren: () => import('./views/company-landing/company-landing.module')
  //     .then(m => m.CompanyLandingModule)
  // },
  {
    path: 'wishlist',
    loadChildren: () => import('./views/wishlist/wishlist.module')
      .then(m => m.WishlistModule)
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./views/mall/mall.module')
  //     .then(m => m.MallModule)
  // },
 
  {
    path: '',
    loadChildren: () => import('./views/store-landing/store-landing.module')
      .then(m => m.StoreLandingModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
