
<div class="modal-content modal-content-color">

    <div class="modal-header">
      <h5 class="modal-title-1">{{'label.ChooseBranch' | translate}}</h5>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true" class="pop-up-close-bt">×</span>
     </button>
       
      </div>
      <div class="modal-body" style="font-size: 16px;">
        <div class="container" >
    

            <button (click)="activeModal.close('Maithar')" class="pop-primary">
                Maithar
            </button>
            
            <button (click)="activeModal.close('Aziziyah')" class="pop-primary">
                Aziziyah
            </button>
        
      </div>
      <div class="modal-footer" >

        <button type="button" class="btn btn-primary" (click)="activeModal.close()" >{{'button.Close' | translate}}</button>
    
      </div>
    </div>
    
       
          
      