import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-choose-saved-address-popup',
  templateUrl: './choose-saved-address-popup.component.html',
  styleUrls: ['./choose-saved-address-popup.component.scss']
})
export class ChooseSavedAddressPopupComponent implements OnInit {
  customerSelectedAddressId : number = 0;
  @Input() storeId : number

  @Input() addressList
  constructor(
    public activeModal: NgbActiveModal,
    private dbService : DbService,
    private commonService : CommonService,
    private toastr : ToastrService,

  ) { }

  ngOnInit(): void {
  }


  addNewAddress(){
    this.activeModal.close(1);
  }

  cancel(){
    this.activeModal.close();
  }


 


  confirm(){
    let selectedAddress = this.commonService.customerAddresses.find(x => x.id == this.customerSelectedAddressId);
 
     let body = {
       StoreId : Number(this.storeId),
       Latitude : Number(selectedAddress.latitude),
       Longitude : Number(selectedAddress.longitude)
     }
     
     this.dbService.methodPost("StoreSettings/CheckOrderDeliverable",body).subscribe(resdata =>{
       if(resdata.isDeliverable ==  true){
         
        let result = {
          deliveryResponse : resdata,
          addressId : this.customerSelectedAddressId
        }
        //  this.deliveryAddressForm.controls.fullAddress.setValue(selectedAddress.address);
        //  this.mapAddress = selectedAddress.location;
        //  this.latitude = Number(selectedAddress.latitude);
        //  this.longitude = Number(selectedAddress.longitude);
        //  this.deliveryfee = resdata.deliveryCharge;
        this.activeModal.close(result);
       }
       else{
         this.toastr.error(
           "Location is not deliverable"
         );
       }
 
     },error =>{
       this.toastr.error(
         "Location is not deliverable"
       );
       this.customerSelectedAddressId = 0;
     }
     )
 
 
  
 
 
   }

}
