import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-signup-popup',
  templateUrl: './signup-popup.component.html',
  styleUrls: ['./signup-popup.component.scss']
})
export class SignupPopupComponent implements OnInit {
  signUpForm : FormGroup;
  constructor(

  private router : Router,
  private fb: FormBuilder ,
  private toastr: ToastrService,
  public activeModal: NgbActiveModal,
  private authService : AuthService ,
  public commonService : CommonService , 
  private modalService : NgbModal,

  ) { 

  }
  ngOnInit(): void {
    this.buildSignUpForm();
  }


  buildSignUpForm() {
    this.signUpForm = this.fb.group({
      StoreID: [0],
      Name: ["", Validators.required],
      Phone: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.maxLength(11),
        ],
      ],
      CountryCode: ['974'],
      Email: [""],
      Address: [""],
      Location: [""],
      SubmitBy: ['1'],
      SubmitOn: [new Date()],
      CustomerName: [""],
      domainUrl:[window.location.href]

    });
  }





  signUp() {
    this.authService.addCustomer(this.signUpForm.value).subscribe((resdata) => {
      if (resdata.id > 0) {
        this.toastr.success('Succesfully Registered . You Can Login');
        // this.modalService.dismissAll();

        // this.onSignIn();
        // this.modalService.open(this.signIn).result.then((result1 =>{
        //   alert(result1)
        //     if(result1 == 1){
        //       if (this.commonService.customer) {
        //         this.confirmationForm.patchValue(this.commonService.customer);
        //         console.log(this.itemForm.value);
        //       }
        //     }
        // }));;

        this.commonService.tempMob = this.signUpForm.controls.Phone.value;
        this.activeModal.close(1);

      }
    });
  }

}
