export class Config {
    // public static userDetails: any;
    // public static branchNo : string ;
    // public static branchName : string ;
    // public static userId : string ;
    // public static userName : string ;
    // public static companyId : string ;
    // public static lang : string ;
    // public static subBranchNo : string ;
    // public static subBranchName : string ;

    // public static logoImageUrl : string ;
    // public static appConfigDetails:any={};
    // public static blockChangeSubbranch:number;




    // public static appConfigDetails:any={};
    public static lang : string = "0" ;

    public static dateFormat : string ;
    public static currencyFormat : string ='1.2-2';
  }


