

<div class="login d-flex align-items-center py-5">
  <div  class="container">
    <form [formGroup]="signInForm" >
      <ng-container *ngIf="!isVerified">
     
         <div class="row">
            <div class="col-3 pr-0">
               <div class="form-label-group">
                  <input formControlName="CountryCode" type="text" id="countryCode" class="form-control" placeholder="" disabled>
                  <label for="countryCode">ISD Code</label>
               </div>
            </div>
            <div class="col-9">
               <div class="form-label-group">
                  <input  formControlName="Phone" type="email" id="userName" class="form-control" placeholder="+123 456 7890">
                  <label for="userName">Mobile</label>
               </div>
            </div>
            <a  *ngIf="!otpRequested" class=" btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2" (click)="sendOTP()">Send OTP</a>            
         </div>

         <div *ngIf="otpRequested">
            <div class="alert alert-success mb-0" role="alert">
               OTP Sent to your mobile! please verify it!
            </div>
            <p>OTP didn't recieved yet? <a href="#" class="alert-link">Resend</a></p>
         </div>
      <div *ngIf="otpFailed" class="alert alert-danger" role="alert">
      Failed ! Please Try Again
  </div>
     <div class="row" *ngIf="otpRequested">
        <div class="col-md-12 col-lg-12 mx-auto">
           <h3 class="login-heading mb-4">One Time Password</h3>
           <div class="form-label-group">
                 <input formControlName="otp" type="number" id="inputOTP" class="form-control inputOTP" placeholder="+123 456 7890">
                 <label for="inputOTP">OTP</label>
           </div>
           <a  class=" btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2" (click)="verifyOTP()">Verify</a>

        </div>
     </div>
    </ng-container>

      <div *ngIf="isVerified"  class="alert alert-success" role="alert">
        OTP Verified :) <a href="#" class="alert-link"></a>
        <div *ngIf="otpEntered == true && isVerified == false " class="alert alert-danger" role="alert">
         Invalid OTP entered ! please try again <a href="#" class="alert-link">Resend</a>
      </div>
    </div>
    </form>

  </div>

</div>
