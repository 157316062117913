<div class="modal-content-color">
<div class="modal-header"> 
      <h4 class="modal-title" id="modal-basic-title">{{'header.SignIn' | translate}}</h4>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true" class="pop-up-close-bt">×</span>
      </button>
    </div>
    <div class="modal-body">

<div class="login d-flex align-items-center py-5">
    <div  class="container">
      <form [formGroup]="signInForm" >
        <ng-container *ngIf="!isVerified">
       
           <div class="row">
              <div class="col-3 pr-0">
                 <div class="form-label-group">
                    <input formControlName="CountryCode" type="text" id="countryCode"  class="login-text-reg" placeholder="" disabled>
                    <label for="countryCode" class="login-text">{{'label.ISDCode' | translate}}</label>
                 </div>
              </div>
              <div class="col-9">
                 <div class="form-label-group ">

                  <!-- <span class="form-error" style="margin-left: 160px;"
                  *ngIf="!signInForm.get('Phone').valid && (signInForm.get('Phone').touched || submitted)"> required</span>
              </span> -->
                    <input  formControlName="Phone" type="number" id="userName" class="login-text-reg" placeholder="+123 456 7890">
                    <label for="userName" class="login-text">{{'label.Mobile' | translate}}</label>
                 </div>
              </div>
              <a  *ngIf="!otpRequested" class="btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2" (click)="sendOTP()">{{'button.SendOTP' | translate}}</a>            
           </div>
  
           <div *ngIf="otpRequested">
              <div class="alert alert-success mb-0" role="alert">
                 {{'desc.OTPSent' | translate}}
              </div>
              <div class="clear"></div>
              <p class="login-text">{{'desc.OTPNotReceived' | translate}} <a (click)="sendOTP(1)" class="alert-link">{{'button.Resend' | translate}}</a></p>
           </div>
           <div class="clear"></div>
        <div *ngIf="otpFailed" class="alert alert-danger" role="alert">
        {{'desc.Failed' | translate}}
    </div>
    <div class="clear"></div>
       <div class="row" *ngIf="otpRequested">
          <div class="col-md-12 col-lg-12 mx-auto">
             <h3 class="login-heading mb-4 login-text">{{'label.OneTimePassword' | translate}}</h3>
             <div class="clear"></div>
             <div class="form-label-group">
                   <input formControlName="otp" type="number" id="inputOTP" class="login-text-reg inputOTP" placeholder="+123 456 7890">
                   <label for="inputOTP" class="login-text">{{'label.OTP' | translate}}</label>
             </div>
             <a  class="btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2" (click)="verifyOTP()">{{'button.Verify' | translate}}</a>
  
          </div>
       </div>
      </ng-container>
  
        <div *ngIf="isVerified"  class="alert alert-success" role="alert">
          {{'desc.OTPVerified' | translate}} :) <a href="#" class="alert-link"></a>
          <div *ngIf="otpEntered == true && isVerified == false " class="alert alert-danger" role="alert">
           {{'InvalidOTP' | translate}} <a href="#" class="alert-link">{{'button.Resend' | translate}}</a>
        </div>
      </div>
      </form>
  
    </div>
  
  </div>
  </div>
</div> 