import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choose-branch-popup',
  templateUrl: './choose-branch-popup.component.html',
  styleUrls: ['./choose-branch-popup.component.scss']
})
export class ChooseBranchPopupComponent implements OnInit {

  constructor(public activeModal : NgbActiveModal) { }

  ngOnInit(): void {
  }

}
