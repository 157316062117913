import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Config } from 'src/app/services/config';
import { DbService } from 'src/app/services/db.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-delivery-map-popup',
  templateUrl: './delivery-map-popup.component.html',
  styleUrls: ['./delivery-map-popup.component.scss']
})
export class DeliveryMapPopupComponent implements OnInit {
  addressType = [
    { id :1 , name :'Apartment' , nameAr :'شقة'},
    { id :2 , name :'House' , nameAr :'بيت'},
    { id :3 , name :'Office' , nameAr :'مكتب'},

  ]

  lang = Number(Config.lang);


  deliveryAddressForm : FormGroup;
  isAddressEnterMode : boolean = false;
  @Input() storeId: number ;
  
  title: string = 'AGM project';
  @Input() latitude: number;
  @Input() longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  @Input() addressData : any;

  @ViewChild('search')
  public searchElementRef: ElementRef;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private toastr: ToastrService,
    private dbService : DbService,
    public activeModal: NgbActiveModal,
    private fb : FormBuilder,
    private common : CommonService,

    private ngZone: NgZone
  ) { }


  ngOnInit() {

    this.buildDeliveryDetailsForm();
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // if(this.latitude === undefined && this.longitude === undefined){
      //   this.setCurrentLocation();
      // }
      // else{ 
      //   this.getAddress(this.latitude , this.longitude);
      // }
      this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 18;
          this.getAddress(this.latitude ,this.longitude);
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(this.latitude == undefined && this.longitude == undefined){
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event) {
    this.latitude = $event.coords.lat;
    this.longitude =  $event.coords.lng;
    this.getLocationDeliverableOrNote();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      let addressFind : boolean = false;

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          (results).forEach(x=>{
            let address : string = x.formatted_address;
            if(!addressFind){
              if(address.includes('+')){
             }
             else{
               addressFind = true;
               this.address = address; 
               return;
             }
            }
          })
          if(!addressFind){
          this.address = results[0].formatted_address;
          }

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  confirmAddress(){

    var responseData = {
        lat : this.latitude,
        lng : this.longitude,
        location : this.address,
        deliveryResponse : this.deliveryDistanceResponse,
        form : this.deliveryAddressForm.value
    }
    this.activeModal.close(responseData);
  }

  getLocationDeliverableOrNote(){

    let body = {
      StoreId : this.storeId,
      Latitude : this.latitude,
      Longitude : this.longitude
    }
    
    this.dbService.methodPost("StoreSettings/CheckOrderDeliverable",body).subscribe(resdata =>{
    })
  }

  onAddressTypeChange(){
   if(this.f.addressTypeId.value == 2){
     this.f.building.setValue("");
     this.f.floor.setValue("");
   }
  }
  deliveryDistanceResponse ;

  confirmLocation(){
    let body = {
      StoreId : this.storeId,
      Latitude : this.latitude,
      Longitude : this.longitude
    }
    
    this.dbService.methodPost("StoreSettings/CheckOrderDeliverable",body).subscribe(resdata =>{
      if(resdata.isDeliverable ==  true){
        this.deliveryDistanceResponse = resdata;
        this.isAddressEnterMode = true;
        // let result ={
        //   lat : this.latitude,
        //   lng : this.longitude,
        //   location : this.address,
        //   deliveryResponse : resdata
        // }
        // this.activeModal.close(result); 

      }
      else{
        this.toastr.error(
          "Location is not deliverable"
        );
      }

    },error =>{
      this.toastr.error(
        "Location is not deliverable"
      );
    })

  }

  cancel(){
    this.activeModal.close();
  }


  buildDeliveryDetailsForm() {
    this.deliveryAddressForm = this.fb.group({
      name: [""],
      mobile: [""],
      addressTypeId: [1],
      building: [""],
      floor: [""],
      houseNo: [""],
      additionalDirection: [""],
    });

    if(this.addressData){
      this.deliveryAddressForm.patchValue(this.addressData);
    }



    if(this.common.customer){
      this.f.name.setValue(this.common.customer.name);
      this.f.mobile.setValue(this.common.customer.phone);

    }
  }
 
  

  get f() {
    return this.deliveryAddressForm.controls;
  }
} 

