<div class="login d-flex align-items-center py-5">
  <div class="container">
     <div class="row">
        <div class="col-md-12 col-lg-12 mx-auto">
           <h3 class="login-heading mb-4">New user!</h3>
           <form [formGroup]="signUpForm">
              <div class="form-label-group">
                 <input formControlName="Name" type="email" id="userName" class="form-control" placeholder="Vigil">
                 <label for="userName">Name <span class="required">*</span></label>
              </div>
              <div class="form-label-group">
                 <input formControlName="Phone" type="email" id="userName" class="form-control" placeholder="+123 456 7890">
                 <label for="userName">Mobile <span class="required">*</span></label>
              </div>

              <div class="form-label-group">
                 <input formControlName="Email" type="email" id="inputEmail" class="form-control" placeholder="abc@yourcompany.com">
                 <label for="inputEmail">Email address</label>
              </div>

              <div class="form-label-group">
                <input formControlName="Address" type="email" id="inputEmail" class="form-control" placeholder="abc@yourcompany.com">
                <label for="inputEmail">Address</label>
             </div>

           <div class="row">
                 <div class="col-md-6">
                    <div class="form-label-group">
                       <input formControlName="Location" type="text" id="inputAddress2" class="form-control" placeholder="Villa No 20">
                       <label for="inputAddress2">Location</label>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <div class="form-label-group">
                       <input  type="text" id="inputAddress3" class="form-control" placeholder="Souq bazar">
                       <label for="inputAddress3">Landmark</label>
                    </div>
                 </div>
           </div>

              <button type="submit" class="btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2" (click)="signUp()" [disabled]="!signUpForm.valid">Register</button>
              <div class="text-center pt-3">
                 Already have an Account? <a class="font-weight-bold" href="#" (click)="gotoLogin()"></a>
              </div>
           </form>
        </div>
     </div>

  </div>
</div>
