
<div class="modal-content modal-content-color">

<div class="modal-header">
  <h5 class="modal-title-1">{{'label.ChooseLocation' | translate}}</h5>
  <button type="button" class="pop-up-close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true" class="pop-up-close-bt">×</span>
 </button>
   
  </div>
  <div class="modal-body" style="font-size: 16px;">
    <div class="container" *ngIf="!isAddressEnterMode">

      <div class="form-group">
        <!-- <label>Enter address</label> -->
        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault();" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
      </div>
     
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)" ></agm-marker>
      </agm-map>
    
    </div>
    <div class="container" *ngIf="isAddressEnterMode">

      <form [formGroup]="deliveryAddressForm" action="
      ">
      <div class="row">
        <div class="col-6 pr-0 popup-choose-7">
          <div class="form-group">
            <label>{{'label.Name' | translate}}</label>
            <input type="text" readonly formControlName="name" class="form-control" >
          </div>
          </div>
          <div class="col-6 pr-0 popup-choose-7">
            <div class="form-group">
              <label>{{'label.Mobile' | translate}}</label>
              <input type="text" readonly formControlName="mobile" class="form-control" >
            </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pr-0 popup-choose-7">
              <ng-container *ngFor="let item of addressType">

                
                <label for=""  class="popup-choose-8 ">
                  
                <input type="radio" (change)="onAddressTypeChange()" formControlName="addressTypeId" [value]="item.id" name="addressTypeId">
              
                <span *ngIf="lang == 0">
                  {{item.name}}

                </span>
                <span *ngIf="lang == 1">
                  {{item.nameAr}}
                </span>
                <span>

                </span>
                
              </label>
                <!-- <label class="container takeaway payment-mode-padd">{{item?.name}}
                    <span class="check-padd">
                        <input type="radio"[value]="item.id"  class=" checkmark" name="radio">
                        <span class="checkmark"></span>
                    </span>
                    
                  </label> -->
            </ng-container>
              </div>
             

              <div class="col-6 pr-0 popup-choose-7" *ngIf="f.addressTypeId.value != 2">
                <div class="form-group">
                  <label>{{'label.Building' | translate}}</label>
                  <input type="text" formControlName="building" class="form-control" >
                </div>
                </div>

                <div class="col-6 pr-0 popup-choose-7" *ngIf="f.addressTypeId.value != 2">
                  <div class="form-group">
                    <label>{{'label.Floor' | translate}}</label>
                    <input type="text" formControlName="floor" class="form-control" >
                  </div>
                  </div>
                  <div class="col-6 pr-0 popup-choose-7">
                    <div class="form-group">
                      <label *ngIf="f.addressTypeId.value == 1">{{'label.AppartmentNo' | translate}}</label>
                      <label *ngIf="f.addressTypeId.value == 2">{{'label.HouseNo' | translate}}</label>
                      <label *ngIf="f.addressTypeId.value == 3">{{'label.OfficeNo' | translate}}</label>
                      <input type="text" formControlName="houseNo"  class="form-control" >
                    </div>
                    </div>

                    <div class="col-6 pr-0 popup-choose-7">
                    <div class="form-group">
                      <label>{{'label.AdditionalDirection' | translate}}</label>
                      <input type="text" formControlName="additionalDirection" class="form-control" >
                    </div>
                  </div>
                    
                  <div class="col-12 pr-0 popup-choose-7">
                    <div class="form-group">
                      <label>{{'label.Location' | translate}}</label>
                      <input type="text" readonly [(ngModel)]="address" [ngModelOptions]="{standalone: true}" class="form-control" >
                    </div>
                  </div>
</div>


                
    </form>
      
  </div>
  </div>
  <div class="modal-footer" >
    <button style="float: left;" *ngIf="isAddressEnterMode"  (click)="isAddressEnterMode= false;" type="button" class="btn btn-danger" (click)="cancel()">{{'button.ViewMap' | translate}}</button>
    <button *ngIf="!isAddressEnterMode" type="button" class="btn btn-primary" (click)="confirmLocation()">{{'button.Confirm' | translate}}</button>
    <button *ngIf="isAddressEnterMode" type="button" class="btn btn-primary" (click)="confirmAddress()">{{'button.Confirm' | translate}}</button>

  </div>
</div>

   
      
  