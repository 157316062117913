import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { Config } from 'src/app/services/config';
import { DbService } from 'src/app/services/db.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-restaurent-services',
  templateUrl: './restaurent-services.component.html',
  styleUrls: ['./restaurent-services.component.scss']
})
export class RestaurentServicesComponent implements OnInit {
  isLoaded : boolean =false;
  storeDetails
  storeId: number;
  storeServiceList =[]
  isQrMode : boolean = false;
  lang = Number(Config.lang)

  theme = '';


  constructor(private storeService : StoreService , 
    private route : ActivatedRoute,
    private dbService : DbService,
    private spinner: NgxSpinnerService, 
    private router : Router,
    private commonService : CommonService
    
    )  { }

  ngOnInit(): void {
   
    this.commonService.isRestuaretDetailsPage = true;
    // this.buildSignUpForm();
    this.route.queryParams.subscribe((queryParams) => {
      if(queryParams.theme != undefined){
        this.theme = queryParams.theme;
      }
      if (queryParams.storeId != undefined) {
        this.commonService.storeId = Number(queryParams.storeId)
        localStorage.setItem("storeId", queryParams.storeId);
        this.storeId = queryParams.storeId;
        this.getStoreDetails(queryParams.storeId);
        this.getServicesOfHotel();

        // this.getMenuDetails(queryParams.storeId);
        // this.getPromotedProductes(queryParams.storeId);
        // this.getProductsByStore(queryParams.storeId);
      }
    

      if(queryParams.viewstate == "qrcode"){
        this.isQrMode = true;
      }


      if (queryParams.tableid > 0) {
        this.commonService.orderModes = 2
	    	localStorage.setItem('orderModes' , '2');

        localStorage.setItem("tableId", queryParams.tableid);
        this.commonService.tableId = Number(queryParams.tableid);
        this.commonService.officeId = 0;
        localStorage.removeItem("officeId");
      } 
      else if(queryParams.viewstate == "qrcode") {
        this.commonService.orderModes = 1;
	    	localStorage.setItem('orderModes' , '1');

        this.commonService.officeId = 0;
        this.commonService.tableId = 0;
        localStorage.removeItem("officeId");
        localStorage.removeItem("tableId");
      }
      else if(queryParams.officeMode != undefined && queryParams.officeMode == "true"){
        this.commonService.orderModes =3
	    	localStorage.setItem('orderModes' , '3');

        this.commonService.tableId = 0;
        localStorage.removeItem('tableId');
      }
      else if(queryParams.companyMode != undefined &&
        queryParams.companyMode == "true"){
          this.commonService.orderModes = 4;
          localStorage.setItem("orderModes", "4");
          this.commonService.tableId = 0;
          this.commonService.officeId = 0;
          localStorage.removeItem("officeId");
          localStorage.removeItem("tableId");
        }
      else {
        this.commonService.orderModes = 0 ;
	    	localStorage.setItem('orderModes' , '0');

        this.commonService.tableId = 0;
        this.commonService.officeId = 0;
        localStorage.removeItem("officeId");
        localStorage.removeItem("tableId");
      }



      if(this.commonService.orderModes == 2) {
        this.onMenuClick();
      }
      



    

    });
  }


  getServicesOfHotel(){
    this.spinner.show();
    this.dbService.methodGet("Stores/GetStoreServices/" + this.storeId).subscribe(resdata =>{
      
      
      this.spinner.hide();
      this.storeServiceList = resdata;
      this.isLoaded = true;
      this.storeServiceList.forEach(x =>{
        if(x.id == 1){
          x.imagePath = '../assets/img/tablemenu.jpg';
        }
        if(x.id == 2){
          x.imagePath = '../assets/img/book-a-table.jpg';
        }
        // if(x.id == 3){
        //   x.imagePath = '../assets/img/book-a-table.jpg';
        // }
       
      })
      
      if(this.storeServiceList.length == 0 || (this.storeServiceList.length ==1) && this.isQrMode == true ){
        if(this.storeServiceList.length == 1){
          this.onServiceClick(this.storeServiceList[0].id);
        }
        else{
          this.onServiceClick(1);
        }
      }
    })
  }

  onServiceClick(serviceId){
    if(serviceId == 1){
      this.onMenuClick()
    }
    else if(serviceId == 2){
      this.onTableBooking()
    }
   
  }

  
  
  getStoreDetails(storeId) {
    this.storeService.getStoreDetailsbyId(storeId).subscribe((resdata) => {
      this.storeDetails = resdata;
    

      // if (
      //   this.commonService.roomId > 0 &&
      //   this.storeDetails.offers.length > 0
      // ) {
      //   if (!this.commonService.onceOrderPopupShowed) {
      //     this.commonService.onceOrderPopupShowed = true;
      //     this.modalService.open(this.offerContent);
      //   }
      // }
    });
  }



  onMenuClick(){
    if(this.commonService.orderModes == 2){



      if(this.theme == undefined){
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId ,tableid : this.commonService.tableId, viewstate : 'qrcode'} ,
        });
      }
      else {
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId ,tableid : this.commonService.tableId, viewstate : 'qrcode' , theme : this.theme} ,
        });
      }
   
    
    
    
    }
    else if(this.commonService.orderModes == 1){
      if(this.theme == undefined){
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId , viewstate : 'qrcode'} ,
        });
      }
      else {
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId , viewstate : 'qrcode' , theme : this.theme} ,
        });
      }
    
    }
    else if(this.commonService.orderModes == 4){
      if(this.theme == undefined){
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId , companyMode : true} ,
        });
      }
      else {
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId , companyMode : true , theme : this.theme} ,
        });
      }
    
    }
    else if(this.commonService.orderModes == 0){
      if(this.theme == undefined){
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId }
      },)
      }
      else {
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId , theme : this.theme}
      },)
      }
    
  }
    else if(this.commonService.orderModes == 3){
      if(this.theme == undefined){
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId  , officeMode : true}
      },)
      }
      else {
        this.router.navigate(['/menus'], {
          queryParams: { storeId: this.storeId  , officeMode : true , theme : this.theme}
      },) 
      }
    
  }
  }

  routeMenu(){



    // this.router.navigate(['/welcome-page'])
  
    if(this.commonService.orderModes ==  3){
      this.router.navigate(['/menu'], {
        queryParams: { storeId: this.storeId  , roomMode : true}
        })
    }
    else{
      this.router.navigate(['/menu'], {
        queryParams: { storeId: this.storeId}
        })
    }
  
  }
  routeRoomServiceMenu(){



    // this.router.navigate(['/welcome-page'])
  
    if(this.commonService.orderModes ==  3){
      this.router.navigate(['/room-service-menu'], {
        queryParams: { storeId: this.storeId  , roomMode : true}
        })
    }
    else{
      this.router.navigate(['/room-service-menu'], {
        queryParams: { storeId: this.storeId}
        })
    }
  
  }

  navigateToRestuarentList(){
    // this.router.navigate(['/roomrestlist'], {
		// 	queryParams: { roomid: this.commonService.roomId  , viewstate : 'qrcode'}
		//   })
  }

  onTableBooking(){
    // if(this.commonService.orderModes ==  3){
    //   this.router.navigate(['/pre-booking/table-booking-area'], {
    //     queryParams: { storeId: this.storeId  , roomMode : true}
    //     })
    // }
    // else{ 

      if(this.theme != undefined){
        this.router.navigate(['/pre-booking/book-table'], {
          queryParams: { storeId: this.storeId , theme : this.theme}
          })
      }
      else {
        this.router.navigate(['/pre-booking/book-table'], {
          queryParams: { storeId: this.storeId}
          })
      }
    

        // this.router.navigate(['/pre-booking/table-booking-area'], {
        //   queryParams: { storeId: this.storeId}
        //   })
    // }
  
  }


  

}
