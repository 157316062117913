<div class="col-md-12">
<div class="close-button"><img (click)="activeModal.close()" src="../../../assets/img/close-button.png"></div>
    <div class="col-md-6 left">

        <div class="box">
            <form [formGroup]="subscribeForm">
                <span class="text-center">{{'header.ToGetUpdatedOffersFromUs' | translate}}</span>
            <div class="input-container">
                <input type="text" formControlName="CustomerName" required=""/>
                <label>{{'label.Name' | translate }}</label>	
                <span class="error-message" *ngIf="(submitted || subscribeForm.get('CustomerName').touched) && !subscribeForm.get('CustomerName').valid ">
                    {{'error.PleaseenteravalidName' | translate}}

                </span>	
            </div>
           
            <!-- <div class="input-container">		
                <input type="mail" required=""/>
                <label>Email</label>
            </div> -->

            <div class="input-container">		
                <input type="number" formControlName="Phone" required=""/>
                <label>{{'label.Mobile' | translate}}</label>
                <span class="error-message" *ngIf="(submitted || subscribeForm.get('Phone').touched) && !subscribeForm.get('Phone').valid ">
                    {{'error.PleaseenteravalidMobile' | translate}}
                </span>	
            </div>


          
                <button type="button" (click)="onSubmit()" class="btn">Subscribe</button>
        </form>	
        </div>

        

       
    </div>

    <div class="col-md-6 left">
        <div class="box">
           <img src="../../../assets/img/ic_launcher.png">
        </div>
    </div>




</div>
