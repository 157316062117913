import { Component, ElementRef, Input, IterableDiffers, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Config } from 'src/app/services/config';
import { DbService } from 'src/app/services/db.service';
import { AuthService } from '../../auth/services/auth.service';
import { CommonService } from '../../services/common.service';
import { StoreService } from '../../services/store.service';
import { LoginAsGuestPopupComponent } from '../../shared-popups/login-as-guest-popup/login-as-guest-popup.component';
import { SignInPopupComponent } from '../../shared-popups/sign-in-popup/sign-in-popup.component';
import { SignupPopupComponent } from '../../shared-popups/signup-popup/signup-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit , OnChanges  {
  @ViewChild('mymodal2', { static: true }) content: ElementRef;
  otpRequested : boolean = false;
  otpFailed : boolean = false;
  signUpSuccess : boolean = false;
  @Input() companyDetails : any;

  lang = Number(Config.lang)
  @ViewChild("asGuest", { static: true }) asGuest: ElementRef;
array =[];


  count : number = 0;
  themeId : number = 0;

  signUpForm: FormGroup;
  isVerified : boolean = false;
  constructor(public commonService : CommonService , 
    private toastr: ToastrService,
    
    private storeService : StoreService,
    private fb: FormBuilder,
    private iterableDiffers: IterableDiffers,
    private db :  DbService,
    private authService : AuthService,
    private modalService : NgbModal,
    public router : Router) {
      this.iterableDiffer = iterableDiffers.find([]).create(null);

     }
    storeList =[];
    filterList =[];
    searchKey : string =""
    closeResult: string;

    ngOnInit(): void {
    // this.getAllStores();

    if(localStorage.getItem('theme')){
      this.themeId = Number(atob(localStorage.getItem('theme')));
    }
  }
  iterableDiffer
  ngOnChanges(){
  //  console.log(this.companyDetails);
  }



  
  setLang(key) {
    if (key == 0) {
      localStorage.setItem("lang", "0");
      window.location.reload();
    } else if (key == 1) {
      localStorage.setItem("lang", "1");
      window.location.reload();
 
    } else if (key == 2) {
      localStorage.setItem("lang", "2");
      window.location.reload();
    }
  }
 
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.commonService.cartList);
    if (changes) {
        // console.log('Changes detected!'); 
        // console.log(this.commonService.cartList);
        this.count = this.commonService.cartList.filter(x =>  x.IsAddOn == false || x.IsAddOn == undefined ).length;
    }
}

  resultWrapper = false;
	showSearchResult() {
		this.resultWrapper = true;
  }

 

  getAllStores(){
    this.storeService.getStores().subscribe(resdata =>{
      
      this.storeList = resdata;
      this.filterList = resdata;
    })
  }

  onSearch(){
    this.filterList = this.storeList.filter((x) =>
    x.storeName
      .toLocaleLowerCase()
      .includes(this.searchKey.toLocaleLowerCase())
  );
    // console.log(this.filterList)
  }


  logOut(){
    // this.router.navigate(['/']);
    // this.onAddMore();
    let theme = localStorage.getItem('theme');
    this.commonService.isLoggedIn =false;
    this.commonService.customer = {};
    this.commonService.cartList =[];
    this.commonService.customerAddresses =[]
    this.commonService.itemTotal =0; 
    localStorage.clear();
    if(theme != null){
      localStorage.setItem('theme', theme);
    }
    this.onAddMore();

  }

  
  navigate(storeId){
    this.router.navigate(['/menu'], {
      queryParams: { storeId: storeId},
    });
    this.resultWrapper=false;

  }
//   open(content) {
//     this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
//         this.closeResult = `Closed with: ${result}`;
//     }, (reason) => {
//         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//         console.log(this.closeResult);
//         if(this.closeResult== 'Dismissed with: 1'){
//           this.modalService.open(this.content)

//         }
//         if (this.closeResult == "Dismissed with: 2") {
//           this.modalService.open(this.asGuest);
//         }
//     });
// }

private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
}



onWishList(){
  if (localStorage.getItem("customer") != null) {
    this.router.navigate(['/wishlist'])
  }
  else{
    this.openSignUpPopup()
  }
}

// verifyOTP(){
//   let body = {
//     Phone : this.signUpForm.controls.Phone.value,
//     Otp : this.signUpForm.controls.otp.value
//   }
//   this.authService.VerifyCustomer(body).subscribe(resdata =>{
    
//     if(resdata.id){

//       this.toastr.success('Login Succesfully');
//       this.isVerified = true;
//       this.commonService.isLoggedIn = true;
//       localStorage.setItem('customer' , JSON.stringify(resdata));
//       this.commonService.customer = resdata;
//       localStorage.setItem('isLoggedIn' , 'true');
//       this.modalService.dismissAll();
//     }
//     else{

//      this.toastr.error('invalid');
//     }
//   })
// }
// signUp() {
//   console.log(this.signUpForm)
//   console.log(this.signUpForm.value);
//   this.authService.addCustomer(this.signUpForm.value).subscribe(resdata =>{
    
//     if(resdata.id> 0){
//       this.toastr.success('Succesfully Registered . You Can Login');
//       this.signUpSuccess = true;
//       this.modalService.dismissAll();
//       this.modalService.open(this.content);
//       this.commonService.tempMob = this.signUpForm.controls.Phone.value;
//     }
//     else{
//       this.toastr.error('Failed , Please Try Again');
//       this.signUpSuccess = false;
//     }
//   })


// }
onCartClick(){
  if(this.commonService.cartList.length > 0){
    this.router.navigate(['/cart'])
  }
  else{
    this.toastr.error('Sorry ! No Items in your cart ! please add atleast one');
  }
}




 

openSignUpPopup(){
  const modalRef =  this.modalService.open(SignupPopupComponent).result.then(
    (result) => {
    if(result == 1){
         const modalRef2 =  this.modalService.open(SignInPopupComponent)
    }
    if(result == 2){
         const modalRef3 =  this.modalService.open(LoginAsGuestPopupComponent).result.then(
          (result2) =>{
                   if(this.commonService.isLoggedIn){
                     this.getCustomerAddresses();
                   }
          });;;
    }
    },
  );
}

getCustomerAddresses(){
  this.db.methodGet("Customers/GetCustomerAddress/" + this.commonService.customer.id).subscribe(resdata =>{
       this.commonService.customerAddresses = resdata;
  })
}

onLoginPopUp(){
  const modalRef2 =  this.modalService.open(SignInPopupComponent).result.then(
    (result2) =>{
             if(this.commonService.isLoggedIn){
               this.getCustomerAddresses();
             }
    });;;;
}
onLoginAsGuestPopUp(){
  const modalRef3 =  this.modalService.open(LoginAsGuestPopupComponent).result.then(
    (result2) =>{
             if(this.commonService.isLoggedIn){
               this.getCustomerAddresses();
             }
    });;;;
}

// sendOTP(){
//   let body = {
//     Phone : this.signUpForm.controls.Phone.value
//   }
//   this.authService.CustomerLogin(body).subscribe(resdata =>{
//    this.otpRequested = true;
//    this.otpFailed = false;
//   },
//   error =>{
//   this.otpFailed = true;
//   })
// }

// get f() {
//   return this.signUpForm.controls;
// }

// buildSignUpForm() {
//   this.signUpForm = this.fb.group({
//     StoreID: [0],
//     Name: ['', [Validators.required]],
//     Phone: [
//       '',
//       [
//         Validators.required,
//         Validators.pattern('^[0-9]*$'),
//         Validators.maxLength(11),
//       ],
//     ],
//     Email: [''],
//     Address: [''],
//     Location: [''],
//     SubmitBy: [''],
//     SubmitOn: [new Date()],
//     CustomerName: [''],
//     CountryCode: ['974'],
//     otp :[]
//   });
// }


onAddMore(){
  if(this.commonService.orderModes == 2){
    this.router.navigate(['/menus'], {
      queryParams: { storeId: this.commonService.storeDetails.id ,tableid : this.commonService.tableId, viewstate : 'qrcode'} ,
    });
  }
  else if(this.commonService.orderModes == 1){
    this.router.navigate(['/menus'], {
      queryParams: { storeId: this.commonService.storeDetails.id , viewstate : 'qrcode'} ,
    });
  }
  else if(this.commonService.orderModes == 0){
    if(this.commonService.storeDetails){
      this.router.navigate(['/menus'], {
        queryParams: { storeId: this.commonService.storeDetails.id} ,
      });
    }
}
  else if(this.commonService.orderModes == 3){
    this.router.navigate(['/'], {
      queryParams: { officeid: this.commonService.officeId  , viewstate : 'qrcode'}
  },)

  //   this.router.navigate(['/menu'], {
  //     queryParams: { storeId: this.commonService.storeDetails.id  , officeMode : true}
  // },)
}
}
}
