import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { StoreService } from '../services/store.service';

@Component({
  selector: 'app-thumbnail-slider',
  templateUrl: './thumbnail-slider.component.html',
  styleUrls: ['./thumbnail-slider.component.scss']
})
export class ThumbnailSliderComponent implements OnInit {
	categoryList=[]
  constructor(private storeService : StoreService , private router : Router , private commonService : CommonService) { }

  ngOnInit(): void {
	  
	    if(this.commonService.orderModes == 3){ 
        this.getOfficeCategorgiesLinked(this.commonService.officeId);
		}
		else{
			this.getCategorgiesLinked();
		}

  }

  thumbnailSlider: any = {
		loop: false,
		lazyLoad: true,
		autoplaySpeed: 1000,
     	autoplayTimeout: 3000,
		autoplay:true,
		responsiveClass: true,
		navText: ['<i class="fa fa-chevron-left">', '<i class="fa fa-chevron-right">'],
		nav: true,
		dots: false,
		responsive: {
			0: {
				items: 3.5,
				autoplay:false
			},
			480: {
				items: 7.5,
				autoplay:false
			},
			940: {
				items: 9
			}
		},
  }

  getCategorgiesLinked(){
    this.storeService.getCategorylinked().subscribe(resdata =>{
      this.categoryList = resdata;
    })
  }
  getOfficeCategorgiesLinked(officeId){
    this.storeService.getOfficeCategorylinked(officeId).subscribe(resdata =>{
		
      this.categoryList = resdata;
    })
  }


  routCategory(categoryId){

   if(this.commonService.orderModes == 3){
	this.router.navigate(['/category'], {
		queryParams: { categoryId: categoryId , officeMode : true}
	  });
   }
   else{
	this.router.navigate(['/category'], {
		queryParams: { categoryId: categoryId}
	  });
   }
	


}
}
