<!-- 
<section class="restaurant-detailed-banner">
  <div class="restaurant-detailed-header relative bg">
     <div class="container">
          <div class="row d-flex align-items-end">
              <div class="col-md-8" style="margin-top: 50px;">
                  <div class="restaurant-detailed-header-left">
                  <img class="img-fluid mr-3 float-left d-none d-sm-block" alt="{{commonService?.storeDetails?.logoUrl}}" src="{{commonService?.storeDetails?.logoUrl}}">
                  <h2 class="text-white" style="padding-top: 50px;">{{commonService?.storeDetails?.storeName}}</h2>
              
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="restaurant-detailed-header-right text-right">
                  <h6 class="text-white mb-0 restaurant-detailed-ratings">
                      </h6>
                  </div>
              </div>
          </div>
     </div>
  </div>
</section> -->
<!-- <script type="text/javascript">
    function ShowHideDiv(chkPassport) {
        var dvPassport = document.getElementById("dvPassport");
        dvPassport.style.display = chkPassport.checked ? "block" : "none";
    }
</script> -->

<section class="restaurant-detailed-banner">
    <div class="text-center">
        <div class="container" style="position: relative;">


        </div>
        <div style="background-image: url({{storeDetails?.bannerUrl}}); position: relative;" class="img-fluid cover">
            <div class="restaurant-detailed-header__docked-inner">
                <div class="text-white mb-0 restaurant-detailed-ratings">

                    <div class="col-12">



                        <!-- <div class="col-10 menu_header">
                      <ngb-rating [max]="5" *ngIf="storeDetails?.enableRating == true" (click)="review1Open()" [(rate)]="storeRating" [readonly]="true" style="font-size: 25px;"></ngb-rating>
                       
                      
                   </div>
 
                   <div class="col-2 menu_header" >
 
                      <a href="#restaurant-info" class="btn-info-round ml-0"><i class="fa fa-address-card"
                            aria-hidden="true"></i></a>
                   </div> -->


                    </div>

                </div>
            </div>
        </div>
    </div>
    <div [class.restaurant-detailed-header]="storeDetails?.themeId == null || storeDetails?.themeId == 0">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-md-12">
                    <div class="restaurant-detailed-header-left"
                        *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0">
                        <!-- <div class="cart-banner"><img src="../../assets/mall/img/logo.png" class="img-fluid mr-3 float-left mb-0 d-none d-sm-block">   </div> -->

                        <div class="col-md-2 menu_header"
                            *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0"><img
                                class="img-fluid mr-3 float-left mb-0 d-none d-sm-block "
                                alt="Spice Hut Indian Restaurant" [src]="storeDetails?.logoUrl"></div>

                        <div class="col-md-10 menu_header"
                            *ngIf="storeDetails?.themeId == null || storeDetails?.themeId == 0">
                            <h2 class="text-white">

                                <span *ngIf="lang == 0"> {{storeDetails?.storeName}}</span>
                                <span *ngIf="lang == 1"> {{storeDetails?.storeNameAr}}</span>
                            </h2>

                            <p class="text-white mb-1 d-none d-sm-block"><i class="fa fa-location-pin"></i>
                                <span *ngIf="lang == 0"> {{storeDetails?.address}}</span>
                                <span *ngIf="lang == 1"> {{storeDetails?.addressAr}}</span>



                                <span *ngIf="storeDetails?.isClosed == false" class="badge badge-success-1">{{'tag.OPEN'
                                    | translate}}</span>
                                <span *ngIf="storeDetails?.isClosed == true" class="badge badge-danger">{{'tag.CLOSE' |
                                    translate}}</span>

                            </p>



                        </div>

                        <div class="col-md-12 menu_header">

                            <!-- <p class="text-white mb-1 d-none d-sm-block"><i class="fa fa-location-pin"></i>
                         {{storeDetails?.address}}
                         <span *ngIf="storeDetails?.isClosed == false" class="badge badge-success">{{'tag.OPEN' | translate}}</span>
                         <span *ngIf="storeDetails?.isClosed == true" class="badge badge-danger">{{'tag.CLOSE' | translate}}</span>
    
                      </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Banner ends-->

<!-- Content section -->
<section class="offer-dedicated-nav  border-top-0 shadow-sm">
    <div class="container">
        <div class="row d-flex align-items-end">
            <div class="col-md-12 pt-4">
                <div class=" rounded border table-bg cart-bg shadow-sm mb-4">
                    <table class="table mb-0 table-cart">
                        <!-- 11111111 -->
                        <thead class="thead-light">
                            <tr>
                                <th class=" cart-right text-black font-weight-bold" scope="col">{{'label.ItemName' |
                                    translate}} </th>
                                <th class=" cart-right-1 text-black font-weight-bold" scope="col">{{'label.Quantity' |
                                    translate}}</th>
                                <th class="  cart-right-1  text-black font-weight-bold" scope="col">{{'label.Price' |
                                    translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let item of commonService.cartList;let i=index;">
                                <tr *ngIf="item.IsAddOn == false || item.IsAddOn == undefined" class="text-gray">
                                    <td>

                                        <span *ngIf="lang == 0" class="menu_header"> {{item?.ProductName}}</span>
                                        <span *ngIf="lang == 1" class="menu_header"> {{item?.ProductNameAr}}</span>
                                        <div class="clear"></div>
                                        <p class=" mb-0 phone-number menu_header">{{commonService.currency}}{{item.Price
                                            | number:'1.2-2'}}</p>

                                        <!-- <ul>
                                        <li *ngFor="let innerItem of item.addOns">
                                         {{innerItem.ProductName}} 
                                        </li>
                                    </ul> -->

                                    </td>
                                    <td class="pl-0 pr-0">
                                        <span class="count-number menu_header-2">
                                            <button class="btn btn-outline-secondary btn-sm left dec mt-0"
                                                (click)="onMinusQty(item , i)">-</button>
                                            <input class="count-number-input mt-0" type="text" [value]="item.Quantity"
                                                readonly="">
                                            <button class="btn btn-outline-secondary btn-sm right inc mt-0"
                                                (click)="onPlusQty(item)">+</button>
                                        </span>
                                        <!-- {{'label.AddNote' | translate}} -->
                                        <a (click)="addNoteForProduct(item)"><span class=" note-icon fa fa-edit"
                                                style="font-size:21px;" title="Add Note"></span> </a>
                                    </td>
                                    <td class="text-right phone-number text-success-4">
                                        <p class=" mb-0 phone-number menu_header-3">
                                            {{commonService.currency}}{{item.TotalPrice | number:'1.2-2'}}</p>


                                        <!-- <ul style="list-style: none;">
                                        <li *ngFor="let innerItem of item.addOns">
                                            {{commonService.currency}}    {{innerItem.TotalPrice  | number:'1.2-2'}} 
                                        </li>
                                    </ul> -->
                                    </td>
                                </tr>

                                <tr *ngFor="let innerItem of item.comboDetails" class="text-gray">
                                    <td style="font-weight: normal; border-top: none;">
                                        <li class="text-success-3">
                                            <span *ngIf="lang == 0"> {{innerItem?.ComboProduct}}</span>
                                            <span *ngIf="lang == 1"> {{innerItem?.ComboProductAr}}</span>
                                        </li>
                                    </td>
                                    <td style="font-weight: normal; border-top: none; padding: 5px;"></td>
                                    <td style="font-weight: normal; border-top: none;" class="text-success-2">
                                        <!-- no price -->
                                    </td>
                                </tr>
                                <tr *ngFor="let innerItem of item.addOns" class="text-gray">
                                    <td style="font-weight: normal; border-top: none;">
                                        <li class="text-success-3">
                                            <span *ngIf="lang == 0"> {{innerItem?.ProductName}}</span>
                                            <span *ngIf="lang == 1"> {{innerItem?.ProductNameAr}}</span>
                                            ({{'label.AddOn' | translate}})

                                        </li>
                                    </td>
                                    <td style="font-weight: normal; border-top: none; padding: 5px;"></td>
                                    <td style="font-weight: normal; border-top: none;" class="text-success-2">
                                        {{commonService.currency}} {{innerItem.TotalPrice | number:'1.2-2'}} </td>
                                </tr>





                            </ng-container>


                            <tr>
                                <td class="text-left">
                                    <span class="total-label text-success-1 menu_header">{{'label.DeliveryCharges' |
                                        translate}}:</span>
                                </td>
                                <td class="text-right pl-0" colspan="2">
                                    <span class="total-label text-success-1 phone-number menu_header-1">
                                        {{commonService.currency}} {{deliveryfee | number:'1.2-2' }}</span>
                                </td>
                            </tr>
                            <tr *ngIf="discountAmount >0">
                                <td class="text-left">
                                    <span class="total-label text-success-1 menu_header">{{'label.Discount' |
                                        translate}}({{discountAppliedPerc}}%)</span>
                                </td>
                                <td class="text-right pl-0" colspan="2">
                                    <span class="total-label text-success-1 phone-number  "> {{commonService.currency}}
                                        {{discountAmount | number:'1.2-2'}}</span>
                                </td>
                            </tr>
                            <ng-container *ngIf="appliedLoyaltyDetails.length >0">
                                <tr *ngFor="let item of appliedLoyaltyDetails" >
                                    <td class="text-left">
                                        <span class="total-label text-success-1 menu_header">{{'label.LoyaltyDiscount' |
                                            translate}}({{item.loyaltyCode}})</span>
                                    </td>
                                    <td class="text-right pl-0" colspan="2">
                                        <span class="total-label text-success-1 phone-number  "> {{commonService.currency}}
                                            {{item.appliedDiscQuantity*item.discountPrice | number:'1.2-2'}}</span>
                                    </td>
                                </tr>
                            </ng-container>
                           
                            <tr *ngIf="flatDiscApplicable && flatDiscount > 0">
                                <td class="text-left">
                                    <span class="total-label text-success-1 "> {{'label.FlatDiscount' | translate}}
                                    </span>
                                </td>
                                <td class="text-right pl-0" colspan="2">
                                    <span class="total-label text-success-1 phone-number  "> {{commonService.currency}}
                                        {{flatDiscount | number:'1.2-2'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left">
                                    <span class="total-label text-success-1 menu_header ">{{'label.GrandTotal' |
                                        translate}}:</span>
                                </td>
                                <td class="text-right pl-0" colspan="2">
                                    <span class="total-label text-success-1 phone-number menu_header-1">
                                        {{commonService.currency}} {{netTotal | number:'1.2-2'}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="menu-list p-3">
                        <div class="form-group">
                            <label class="menu_header">{{'label.Note' | translate}} </label>

                            <textarea [(ngModel)]="suggestions" class="form-control menu_header"
                                style="margin-bottom: 10px;"></textarea>
                        </div>
                       
                    </div> -->
                    <div class="clear"></div>
                    <div *ngIf="this.commonService.orderModes == 0 || this.commonService.orderModes == 1 || this.commonService.orderModes == 4">
                        <!-- Tab -->
                        <section class="offer-dedicated-nav border-top-0   pl-3 pr-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="nav" id="pills-tab" role="tablist">

                                        <li class="takeaway ">
                                            <a *ngIf="isDeliveryAvailableForStore" (click)="onOrderTypeChange(1)"
                                                class="nav-link  active" id="delivery" data-toggle="pill"
                                                href="#delivery-dishes" role="tab" aria-controls="delivery-dishes"
                                                aria-selected="true"
                                                style="font-size: 15px; font-weight: bold;">{{'label.Delivery' |
                                                translate}} </a>
                                        </li>
                                        <li >
                                            <a *ngIf="storeDetails?.enablePickup == true" (click)="onOrderTypeChange(0)" class="nav-link" id="takeaway"
                                                data-toggle="pill" href="#takeaway-box" role="tab"
                                                aria-controls="takeaway-box" aria-selected="true"
                                                style="font-size: 15px; font-weight: bold;">{{'label.Takeaway' |
                                                translate}}</a>
                                        </li>
                                        <!-- <li class="nav-item">
                                    <a (click)="onOrderTypeChange(2)" class="nav-link" id="pre-order" data-toggle="pill" href="#pre-order-book" role="tab" aria-controls="pre-order-book" aria-selected="false">Pre-order</a>
                                    </li> -->
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <div class="tab-content" id="pills-tabContent" style="margin-bottom: 5px;">
                            <div class="tab-pane fade show" id="takeaway-box" role="tabpanel"
                                aria-labelledby="takeaway">
                                <div class=" rounded p-4 rating-review-select-page">




                                    <div class="menu_header" style="margin-bottom: 20px;">
                                        <div class="takeaway">{{'label.PickUpNow' | translate}}</div>
                                        <div style="float: left;">
                                            <label class="custom-checkbox">
                                                <span class="check-padd-1">

                                                    <input type="checkbox" [(ngModel)]="isImmediateTakeAway"
                                                        (change)="onImmediateChange()"
                                                        [ngModelOptions]="{standalone: true}">
                                                    <span class="checkmark chk-mark"></span>
                                                </span>


                                            </label>
                                        </div>

                                        <span *ngIf="isImmediateTakeAway"> ( {{storeDetails.settings.pickupMinTime}}
                                            Minutes )</span>
                                    </div>



                                    <div class="clear"></div>
                                    <div class="menu_header" *ngIf="isImmediateTakeAway == false;">
                                        <div class="takeaway-1">{{'label.PickUpAfter' | translate}}</div>

                                        <div style="float: left;">

                                            <span class="count-number">
                                                <button class="btn btn-outline-secondary btn-sm left dec mt-0"
                                                    (click)="onPickUpTimeCounter(1)">-</button>
                                                <input [(ngModel)]="takeAwayMinTime"
                                                    [ngModelOptions]="{standalone: true}"
                                                    class="count-number-input mt-0" type="text">
                                                <button class="btn btn-outline-secondary btn-sm left dec mt-0"
                                                    (click)="onPickUpTimeCounter(2)">+</button>
                                                <div style="float: left; margin-left: 6px;"> {{'label.Minutes' |
                                                    translate}} </div>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="clear"></div>

                                    <p class="mb-2 text-black text-primary-1 takeaway-2"><strong>{{'label.OrderBy' |
                                            translate}}: </strong></p>
                                    <p class="mb-2 text-primary-1 takeaway-3">
                                        <strong>{{commonService?.customer?.name}}</strong></p>
                                    <p class="mb-2 takeaway-3">{{commonService?.customer?.address}}</p>






                                </div>
                            </div>



                            <div *ngIf="isDeliveryAvailableForStore" class="tab-pane fade show active" id="delivery-dishes" role="tabpanel"
                                aria-labelledby="delivery">
                                <div class=" rounded p-4 explore-outlets">

                                    <form [formGroup]="deliveryAddressForm"
                                        *ngIf="storeDetails?.enableGoogleMap != true">
                                        <span *ngIf="commonService.isLoggedIn">
                                            <label for="" class="menu_header">{{'label.Name' | translate}}</label><span
                                                class="validator menu_header">*</span>

                                            <input type="text" [(ngModel)]="commonService.customer.name"
                                                [ngModelOptions]="{standalone: true}" class="form-control">
                                            <label for="" class="menu_header">{{'label.Mobile' |
                                                translate}}</label><span class="validator  menu_header">*</span>
                                            <input type="text" [(ngModel)]="commonService.customer.phone"
                                                [ngModelOptions]="{standalone: true}" class="form-control">
                                        </span>

                                        <div class="menu_header" style="margin:10px 0px;"
                                            *ngIf="storeDetails?.enableGoogleMap == true">



                                        </div>



                                        <div class="clear"></div>


                                        <label for="" class="menu_header">{{'label.Address' | translate}}</label>
                                        <span class="validator menu_header"
                                            *ngIf="storeDetails?.enableGoogleMap != true">*</span>



                                        <input type="text" formControlName="fullAddress" class="form-control">
                                    </form>

                                    <!-- <span>
                           
                            <a *ngIf="storeDetails?.enableGoogleMap == true" (click)="openMap()" class="btn btn-outline-success-1 btn-sm btn-left" style="margin:7px 0px">
                               <span *ngIf="latitude !=  undefined && longitude != undefined">
                                Change Location
                               </span>
                               <span *ngIf="latitude === undefined && longitude === undefined">
                                Choose Location
                               </span>

                            </a>
                        </span> -->

                                    <div class="clear"></div>
                                    <!-- <span class="checkmark-1"> {{mapAddress}} </span> 
                           
                            <div class="clear"></div> -->

                                    <!-- <span class="checkmark-1" *ngIf="storeDetails?.enableGoogleMap == true">
                                <input type="checkbox" [(ngModel)]="saveAddress">
                                 save address
                                </span> -->
                                    <!-- <p class="mb-1 text-black">Delivery To:</p>
                                <p class="mb-1 text-primary"><strong>Thomas Darvin</strong></p>
                                <p class="mb-1">291/d/1, 291, Cornich, Pearl Qatar,
                                123569, Qatar
                                </p> -->


                                    <section *ngIf="storeDetails?.enableGoogleMap == true" class="clearfix" style="margin-top: 10px ;">

                                        <form>


                                            <div>
                                                <span class="menu_header" style="margin-bottom: 5px;">{{'label.DeliveryAddress' | translate}}</span>
                                                <div class="clear"></div>
                                                <div class="address-bx1">
                                                    <a *ngIf="!addressData" (click)="addNewLocation()"
                                                        class="btn btn-outline-success-1 btn-sm btn-left address-bx-margin"
                                                        style="height:30px; padding:3px 10px 4px 10px;">

                                                        <span>
                                                            {{'label.Add' | translate}}
                                                        </span>

                                                    </a>
                                                    <a *ngIf="addressData" (click)="editLocation()"
                                                        class="btn btn-outline-success-1 btn-sm btn-left"
                                                        style="height:30px; padding:3px 10px 4px 10px;">

                                                        <span>
                                                            {{'label.Edit' | translate}}
                                                        </span>

                                                    </a>

                                                    <a *ngIf="commonService.customerAddresses.length > 0"
                                                        (click)="chooseSavedAddresses()"
                                                        class="btn btn-outline-success-1 btn-sm btn-left"
                                                        style="height:30px; padding:3px 10px 4px 10px;">

                                                        <span>
                                                            {{'label.Choose' | translate}}
                                                        </span>

                                                    </a>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="clear"></div>
                                        <div class="address-bx" *ngIf="addressData">

                                            <span *ngIf="addressData?.addressTypeId == 1"
                                                style="text-decoration: underline;">
                                                {{'label.Appartment' | translate}} :-
                                                <br>
                                            </span>
                                            <span *ngIf="addressData?.addressTypeId == 2"
                                                style="text-decoration: underline;">
                                                {{'label.House' | translate}}  :-
                                                <br>

                                            </span>
                                            <span *ngIf="addressData?.addressTypeId == 3"
                                                style="text-decoration: underline;">
                                                {{'label.Office' | translate}}  :-
                                                <br>

                                            </span>
                                            {{commonService?.customer?.name}} ,
                                            <br>

                                            <span *ngIf="addressData?.addressTypeId != 2">
                                                <span *ngIf="addressData?.building"> 
                                                    {{'label.Building' | translate}} : {{addressData?.building}},
                                                <br>

                                                </span>

                                                <span *ngIf="addressData?.floor">
                                                    {{'label.Floor' | translate}} : {{addressData?.floor}},
                                                <br>

                                                </span>


                                            </span>

                                            <span *ngIf="addressData?.houseNo">
                                                <span *ngIf="addressData?.addressTypeId ==1">
                                                    {{'label.AppartmentNo' | translate}} 
                                                </span>
                                                <span *ngIf="addressData?.addressTypeId ==2">
                                                    {{'label.HouseNo' | translate}} 
                                                </span>
                                                <span *ngIf="addressData?.addressTypeId ==3">
                                                    {{'label.OfficeNo' | translate}} 
                                                </span>
                                                
                                                : {{addressData?.houseNo}},
<br>
                                            </span>

                                            <span>
                                                {{commonService?.customer?.phone}}
                                                <br>
                                            </span>
                                            


                                            <span *ngIf="addressData?.additionalDirection">
                                                {{'label.Direction' | translate}} :
                                                {{addressData?.additionalDirection}}, <br> </span>

                                                <span *ngIf="addressData?.location">
                                                    {{'label.Location' | translate}} :
                                                    {{addressData?.location}}, </span>

                                            

                                        </div>
                                        <div class="clear"></div>


                                        <!-- <div class="container menu_header" style="margin:10px 0px;">
                
                                           
                                                <label class="address-chk-2" > Address
                                                   
                                                   <span class="address-chk-1">
                                                        <input type="radio"   class=" checkmark" name="radio">
                                                        <span class="checkmark"></span>
                                                    </span>
                                               
                
                                                
                                                  </label>
                                              
                                             
                                               
                                            </div> -->




                                    </section>
                                </div>
                            </div>
                            <!-- <div class="tab-pane fade show" id="pre-order-book" role="tabpanel" aria-labelledby="pre-order">
                            <div class="bg-white rounded p-4 explore-outlets">
                                <p class="mb-1 text-black">Order by:</p>
                                <p class="mb-1 text-primary"><strong>Name : John Doe</strong></p>
                                <p class="mb-1">Contact No. +971 02345681</p>
                                <p class="mb-1">Number of Persons : 4</p>
                                <p class="mb-1">Date & Time : 10-10-2020 | 09:30 PM</p>
                            </div>
                    </div> -->
                        </div>
                        <!-- Tab ends -->
                    </div>

                    <div class="clear"></div>




                    <div class="clear"></div>

                    <section class=" border-top p-3 clearfix" *ngIf="storeDetails?.enablePayment == true">
                        <h6 class="payment-mode">{{'label.PaymentMode' | translate}}</h6>

                        <div class="menu_header" style="margin-bottom: 20px;">

                            <!-- <div class="takeaway payment-mode-padd">Cash</div> -->
                            <div style="float: left;">


                                <ng-container *ngFor="let item of payModes">
                                    <label class="container takeaway payment-mode-padd">{{item?.paymentMode}}
                                        <span class="check-padd">
                                            <input type="radio" [value]="item.id" [(ngModel)]="selectedPayMode"
                                                class=" checkmark" name="radio">
                                            <span class="checkmark"></span>
                                        </span>

                                    </label>
                                </ng-container>
                                <!-- <label class="container takeaway payment-mode-padd">Cash
                                    <input type="radio" class=" checkmark" name="radio">
                                    <span class="checkmark"></span>
                                  </label>
                                  <label class="container takeaway payment-mode-padd">CB Pay
                                    <input type="radio"  class=" checkmark" name="radio">
                                    <span class="checkmark"></span>
                                  </label> -->
                            </div>




                        </div>
                        <div class="clear"></div>




                        <!-- <div class="menu_header" style="margin-bottom: 20px;">
                        <div class="takeaway">CB Pay</div>
                        <div style="float: left;">
                         <label class="custom-checkbox">
                            <input type="radio"  value="2">
                            <span class="checkmark"></span>
                           
                          </label>
                        </div> 
                     </div> -->


                        <div class="clear"></div>
                        <div class="payment-mode-field" *ngIf="selectedPayMode == 3">


                            <span class="payment-mode-field-txt">{{'label.Last4Digit' | translate}}:</span>
                            <input class="payment-mode-field-padd type-1" [(ngModel)]="cbPay4digit" type="text"
                                id="digit">



                            <div class="clear" style="margin-top: 6px;"></div>

                            <div class="type">

                                <span class="payment-mode-field-txt">{{'label.CardType' | translate}}:</span>

                                <select style="height: 25px;" [(ngModel)]="cbPayType">
                                    <option value="" disabled selected>{{'label.Type' | translate}}</option>
                                    <option value="Debit">{{'label.Debit' | translate}}</option>
                                    <option value="Credit">{{'label.Credit' | translate}}</option>

                                </select>

                            </div>



                        </div>



                    </section>


                    <section class=" border-top p-3 clearfix">
                        <a (click)="onAddMore()" class="btn btn-outline-success-1 btn-sm btn-left" type="button">
                            {{'label.AddMore' | translate}} </a>
                        <a (click)="confirmOrders()" class="btn-1 btn-primary-1 btn-sm btn-right" type="button"
                            style="color: #fff;"> {{'label.PlaceOrder' | translate}} </a>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Content section ends -->


<ng-template #mymodal2 let-modals>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sign In</h4>
        <button type="button" class="pop-up-close" aria-label="Close" (click)="modals.dismiss('Cross click')">
            <span aria-hidden="true" class="pop-up-close-bt">×</span>
        </button>
    </div>
    <div class="modal-body">
        <app-sign-in></app-sign-in>
    </div>
</ng-template>