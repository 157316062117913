<form class="explore-outlets-search">
    <div class="input-group">
        <input
            (focus)="showSearchResult()"
            [(ngModel)]="searchKey"
            [ngModelOptions]="{standalone: true}"
            (keyup)="onSearch()"

            type="text"
            placeholder="Enter your Favorite restaurants..."
            class="form-control form-control-lg" #search
        >
        <div class="input-group-append">
            <button type="button" class="btn btn-link">
            <i class="fa fa-search"></i>
            </button>
        </div>
    </div>
</form>
<div class="search-result" *ngIf="resultWrapper" #searchResult>
    <ng-container *ngFor="let item of filterList; let i=index;" >
        <a (click)="navigate(item.id)" class="search-result__item" *ngIf="i < 3">
            <div class="search-result__item-image">
                <img   src="{{item.logoUrl}}" class="img-fluid">
            </div>
            <div class="search-result__content">
                <h5  class="search-result__item-name">{{item.storeName}}</h5>
                <p  class="search-result__item-location">{{item.description}}</p>
                <p class="search-result__item-description">{{item.address}}</p>
            </div>
        </a>
    </ng-container>
</div>
