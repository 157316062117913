<section class="ex-collection">
    <div class="container">
  
  <!-- restaurents -->
  <div class="row">
                
    <div class="col-lg-12 col-md-12">
        <div class="row">
            <div class="col-12">
                <div class="section-header-left" style="margin-top:20px;">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title title">Wishlist Restaurants<span class="fs-14">
                            <a style="color: #025b35;cursor: pointer;"  class="store-btn">
                                <!-- See all stores -->
                            </a></span></h3>
                    </div>
                </div>
            </div> 
            
            <ng-container *ngFor="let item of storeList ; let i = index;">

            <div class="col-md-3 col-sm-3 col-6"    >
                <div class="product-box">
                    <div class="product-img">
                        <a (click)="onStoreSelect(item)">
                            <img (click)="onStoreSelect(item)" *ngIf="!item?.imageUrl" src="../../../../assets/mall/img/store/offer/shop-1.jpg" class="img-fluid full-width" alt="product-img">
                            <img (click)="onStoreSelect(item)" *ngIf="item?.imageUrl" src="{{item?.imageUrl}}" class="img-fluid full-width" alt="product-img">
                       
                        </a>
                        <!-- <div class="">
                            <div class="product-tags padding-10"> <span class="circle-tag" >
            <img src="../../../../assets/mall/img/svg/013-heart-1.svg" alt="tag">
          </span>
                                
                            </div>
                        </div> -->
                        <div class="">
                            <div class="product-tags"> <span class="wishlist-tag-1" (click)="onWishListDelete(item.customerWishlishId)">
                                <img src="../../../../assets/mall/img/svg/013-heart-2.svg" alt="tag">
          </span>
                                
                            </div>
                        </div>

                       

                    </div>
                    
                    
                    <div class="product-caption">
                
                
                 <div class="title-box">
                <h6 class="product-title"><a (click)="onStoreSelect(item)" class="text-light-black-title ">
                          
                    <span *ngIf="lang == 0">    {{item?.storeName}}</span>
                    <span *ngIf="lang == 1">    {{item?.storeNameAr}}</span>
                  </a></h6>
                <div class="col-12" style="float: left; margin-left: -6px;">
                <div class="product-list-rating"> 
                    <ngb-rating [max]="5" [(rate)]="item.rating" [readonly]="true" style="font-size: 20px;"></ngb-rating>
                </div></div>
                
          
          </div>
            </div>
                </div>
            </div>
        </ng-container>


          
        </div>
     
    </div>
</div>
<!-- restaurents -->
</div>
</section>