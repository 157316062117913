import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-add-suggestion-popup',
  templateUrl: './add-suggestion-popup.component.html',
  styleUrls: ['./add-suggestion-popup.component.scss']
})
export class AddSuggestionPopupComponent implements OnInit {

  suggestion : string = '' ;
  isVerified : boolean = false;
  constructor(
     private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private commonService : CommonService ,
    private modalService : NgbModal,) { }

  ngOnInit(): void {

    
  }


  onAdd(){
    this.activeModal.close(this.suggestion);
  }
  
}


