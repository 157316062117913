import { Injectable, IterableDiffers } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbService } from './db.service';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from '../shared/confirmation.service';
import { Config } from './config';
import { OrderStatusData } from '../models/order';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {



  deliveryIconPropretiesShow : boolean = false;
  deliverable : boolean = false;


  showMultyLang : boolean = true;
  orderModes : number  = 0;   
  tableBookingCartList=[]

  // 0 :- Normal Mode (with Delivery Or Take Away)
  // 1 :- Store Qr Scan
  // 2 :- Table Qr Scan
  // 3 :- Office Qr Scan
  // 4 :- Company Qr Scan


  cartList =[]
  storeDetails
  onceOrderPopupShowed : boolean = false;

  storeId;
  isRestuaretDetailsPage : boolean = false;
  isCartPage : boolean = false;

  tableId
  itemTotal : number = 0;
  viewState
  currency : string ="QR "
  isLoggedIn : boolean = false;
  customer
  officeId
  tempMob = ""
  iterableDiffer;
  customerAddresses = [];
  customerWishList = []
  loginChange: Subject<boolean> = new Subject<boolean>();

  private hubConnection : HubConnection;
  signalData : any;
  addTransferChartDataListener: () => void;

  constructor(private spinner : NgxSpinnerService, private db : DbService,
    private iterableDiffers: IterableDiffers,
    private confirmationDialogService : ConfirmationService,
    private dbService : DbService,
    private datePipe : DatePipe,
    
    private toastr: ToastrService,

    
    ) {

    this.findTotal();
   }



   push(){
   // new code for signalR

   this.hubConnection = new signalR.HubConnectionBuilder()              
   .withUrl(this.db.getConnectionURL() + 'pushNotification')
   .configureLogging(signalR.LogLevel.Information)
   .build();


this.hubConnection.start().then(() => {

// console.log('connection started');
}).catch(err => console.log('connection started error',err));

this.hubConnection.onclose(() => {

setTimeout(() => {
console.log('try to re start connection');
this.hubConnection.start().then(() => {

console.log('connection re started');
}).catch(err => console.log('connection re started error',err));
}, 5000);

});

this.hubConnection.on('privateMessageMethodName', (data) =>{



  // this.toastr.show(data);
    console.log(data);  

   let message = ''
   if(Config.lang == "0"){
     message = data.message
   }
   else{
    message = data.messageAr
   }
   let head ;

   if(Config.lang == "0"){
    head ='Please Confirm'
 }
 else{
   head ='يرجى تأكيد'
 }
 let btnOkText ='Confirm Order';
 let btnCancelText ='Cancel Order';


   if(data.orderId > 0 && data.deliveryTimeAcceptance == true){
    this.confirmationDialogService
    .confirm(head, message,btnOkText,btnCancelText)
      .then((confirmed) => {
        if (confirmed) {
          var updateOrderStatus = new OrderStatusData();
          updateOrderStatus.Id = data.orderId;
          updateOrderStatus.OrderStatus = 2;
          updateOrderStatus.SubmitBy = "1";
          updateOrderStatus.IsCustomerCancelled = false;
          updateOrderStatus.SubmitOn = this.datePipe.transform(new Date, "yyyy-MM-dd").toString();
          this.updateStatus(updateOrderStatus);
        }
        else{
          var updateOrderStatus = new OrderStatusData();
          updateOrderStatus.Id = data.orderId;
          updateOrderStatus.OrderStatus = 5;
          updateOrderStatus.OrderStatus = 5;
          updateOrderStatus.IsCustomerCancelled = true;
          updateOrderStatus.SubmitBy = "1";
          updateOrderStatus.SubmitOn = this.datePipe.transform(new Date, "yyyy-MM-dd").toString();
          this.updateStatus(updateOrderStatus);
        }

        let body = {
          Id : data.id
        }
        this.dbService.methodPost("PushNotifications/PushNotificationViewed/" + data.id,body).subscribe(resdata =>{
    
        })
      })
    }
    else{
      this.confirmationDialogService.notification(message,data.id)
      .then((confirmed) =>
      {
    
      })
    }


 

// console.log(data);
});






this.hubConnection.on('publicMessageMethodName', (data) => {

// console.log('public Message:' + data);
});

this.hubConnection.on('clientMethodName', (data) => {

// console.log('server message:' + data);
});

this.hubConnection.on('WelcomeMethodName', (data) => {

// console.log('client Id:' + data);
this.updateCustomerConnection(data);
this.hubConnection.invoke('GetDataFromClient', 'abc@abc.com', data).catch(err => console.log(err));
});

   }


   updateStatus(updateOrderStatus: OrderStatusData) {
    
      this.db.methodPost("Orders/UpdateOrderStatus", updateOrderStatus)
      .subscribe(
        orderdata => {
          this.spinner.hide();
          this.toastr.success('Order Status Updated Successfully.', 'Order Status');
          //console.log(response);
        },
        error => {

          this.spinner.hide();
          this.toastr.error('Failed to Update Order Status.', 'Order Status');
          //console.log(error);
        });
  }


  findTotal(){
    this.itemTotal = 0;
    this.cartList.forEach(x =>{
      this.itemTotal = this.itemTotal + x.TotalPrice
    })
  }
  clearCache(){
    this.cartList =[];
    this.storeDetails={};
    this.storeId = 0;
    this.tableId =0;
    this.viewState =""
  }



 updateCustomerConnection(connectionId){
   let body  = {
     Id : this.customer.id,
     ConnectionId : connectionId
   }

   
   this.db.methodPost("Customers/UpdateCustomerConnection/" + this.customer.id ,body ).subscribe(resdata =>{
     
   })
 }




}
