import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-login-as-guest-popup',
  templateUrl: './login-as-guest-popup.component.html',
  styleUrls: ['./login-as-guest-popup.component.scss']
})
export class LoginAsGuestPopupComponent implements OnInit {

  signInForm : FormGroup;
  isVerified : boolean = false;
  constructor(private route: ActivatedRoute,
     private router : Router, private fb: FormBuilder ,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,

     private authService : AuthService ,
    private commonService : CommonService ,
    private modalService : NgbModal,) { }

  ngOnInit(): void {
    this.buildSignInForm();

    
  }
  buildSignInForm() {
    this.signInForm = this.fb.group({
      CountryCode: ['974'],
      Name :['' , Validators.required],
      Phone: ['', [Validators.required , Validators.minLength(8)]],
      domainUrl:[window.location.href]

    })
  }
 

  get form(){
    return this.signInForm.controls;
  }
  

  login(){

    if(this.signInForm.invalid){
      return;
    }
    this.authService.GuestLogin(this.signInForm.value).subscribe(resdata =>{
      
      if(resdata.id){
        this.commonService.isLoggedIn = true;
        resdata.tempLogin = true;
        localStorage.setItem('customer' , JSON.stringify(resdata));
        this.commonService.customer = resdata;
        localStorage.setItem('isLoggedIn' , 'true');
        this.modalService.dismissAll();
        this.commonService.push();

      }
      else{

       this.toastr.error('invalid');
      }
    } , error =>{
      this.toastr.error('invalid');
      this.isVerified = false;
    })
  }
}

