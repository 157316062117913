import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sign-in-popup',
  templateUrl: './sign-in-popup.component.html',
  styleUrls: ['./sign-in-popup.component.scss']
})
export class SignInPopupComponent implements OnInit {
  otpRequested : boolean = false;
  otpEntered : boolean = false;

  submitted : boolean =false;
  otpFailed : boolean = false;
 
  signInForm : FormGroup;
  isVerified : boolean = false;
  constructor(private route: ActivatedRoute,
     private router : Router,
      private fb: FormBuilder ,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
       private authService : AuthService ,
    private commonService : CommonService , 
    private modalService : NgbModal) { }

  ngOnInit(): void {
    this.buildSignInForm();

    

    var phno = this.commonService.tempMob;
    if(phno != undefined && phno != ""){
      this.signInForm.patchValue({
        Phone: phno
      });
      this.sendOTP();
    }
  }
  buildSignInForm() {
    this.signInForm = this.fb.group({
      CountryCode: ['974'],
      Phone: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      domainUrl:[window.location.href]

    })
  }
  sendOTP(key? ){

  //  this.submitted = true
  //  if(this.signInForm.invalid){
  //    return;
  //  }

   this.signInForm.controls.Phone.setValue(this.signInForm.controls.Phone.value.toString())
   this.authService.CustomerLogin(this.signInForm.value).subscribe(resdata =>{
    this.otpRequested = true;
    this.otpFailed = false;
    this.commonService.tempMob ="";
   },
   error =>{
   this.otpFailed = true;
   })
  }

  get form(){
    return this.signInForm.controls;
  }
  verifyOTP(){
    // console.log(this.signInForm.value)

   this.signInForm.controls.Phone.setValue(this.signInForm.controls.Phone.value.toString())

    this.form.otp.setValue((this.form.otp.value.toString()))
    this.authService.VerifyCustomer(this.signInForm.value).subscribe(resdata =>{
      this.otpEntered = true;
      
      if(resdata.id){
        this.isVerified = true;
        this.commonService.isLoggedIn = true;
        localStorage.setItem('customer' , JSON.stringify(resdata));
        this.commonService.customer = resdata;
        localStorage.setItem('isLoggedIn' , 'true');
        this.activeModal.close(1);
        this.commonService.push();
      }
      else{
        this.toastr.error('invalid');
      }
    } , error =>{
      this.toastr.error('invalid');
      this.isVerified = false;
      this.otpEntered = true;
    })
  }
}
