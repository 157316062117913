import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Config } from 'src/app/services/config';
import { DbService } from 'src/app/services/db.service';
import { ConfirmationService } from 'src/app/shared/confirmation.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  selectedProductReviewRating =2
  storeList = []
  lang = Number(Config.lang)

  constructor(private dbService : DbService , 
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    
    private router : Router,
    private confirmationDialogService : ConfirmationService,
    
    private commonService : CommonService) { }

  ngOnInit(): void {
    this.getWishListStores();
  }



  getWishListStores(){
	  this.spinner.show();

    this.dbService.methodGet("CustomerWishlists/GetCustomerWishlistStores/" + this.commonService.customer.id).subscribe(resdata =>{
      
      this.storeList = resdata ;
      this.storeList.forEach(x  =>{
        x.rating = Math.round(x.rating);
      })
	  this.spinner.hide();

    })
  }


  onWishListDelete(id){
    let head ;
    let message ;
    if(Config.lang == "0"){
       head ='Please Confirm'
       message  ='Are you Sure to remove from wishlist ?'
     
    }
    else{
      head ='يرجى تأكيد'
      message  ='هل أنت متأكد من إزالة من قائمة الرغبات؟'

    }
    this.confirmationDialogService
      .confirm(head, message)


    // this.confirmationDialogService.confirm('Please Confirm..', 'Are you Sure to remove from wishlist ... ?')
    .then((confirmed) =>
    {
      if(confirmed){
    let body = {
      Id : id
    }
    this.dbService.methodPost("CustomerWishlists/DeleteCustomerWishlists/" + id, body).subscribe(resdata =>{
      
      if(resdata.id > 0) {
        this.toastr.success('Removed from wishlist');
        this.getWishListStores();
      }
      else{
        this.toastr.error('Failed to remove from wishlist');
      }

    })

  }
})
  }

  onStoreSelect(store){
	if(store.enableSubdomain ==false){

	this.router.navigate(['/menu'], {
		queryParams: { storeId: store.id}
	  })
  }
  else{
		let  href = store.subdomainUrl + '#/menu?storeId=' + store.id + '&theme=' + btoa(store.themeId);//find url
		window.location.href = href;
	}
}

}
