<ng-template #mymodal let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Register</h4>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true" class="pop-up-close-bt">×</span>
      </button>
  </div>
  <div class="modal-body">
    <app-signup></app-signup>
  </div>
</ng-template>

<ng-template #mymodal2 let-modals>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Sign In</h4>
      <button type="button" class="pop-up-close" aria-label="Close" (click)="modals.dismiss('Cross click')">
          <span aria-hidden="true" class="pop-up-close-bt">×</span>
      </button>
  </div>
  <div class="modal-body">
    <app-sign-in></app-sign-in>
  </div>
</ng-template>
