    <div class="modal-header">
       <div class="col-12 ">
          <div>
             <h5 class="modal-title  pop_up_header-1" id="modal-basic-title"
                style="font-size: 21px; font-weight: 600;">{{'label.OurRating' | translate}}</h5>
          </div>
          <div class="col-12" >
                   <ngb-rating  [max]="5" [(rate)]="storeRating" [readonly]="true" style="font-size: 35px;" class="pop_up_header-2"></ngb-rating>
          </div>
       </div>
       <button type="button" class="pop-up-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true" class="pop-up-close-bt">×</span>
       </button>
    </div>
    <div class="modal-body">
       <div class="col-12">
          <div>
             <h5 class="modal-title pop_up_header-1" id="modal-basic-title"
                style="font-size: 16px; font-weight: 600;">{{'label.RateUs' | translate}}</h5>
          </div>
          <div class="col-12">
 
             <ngb-rating [max]="5" [(rate)]="storeRatingForm" [readonly]="false" [id]="'ab'" style="font-size: 35px;" class="pop_up_header-2"></ngb-rating>
          </div>
          
 
          <div class="col-md-12 float-left">
            
          </div>
 
 
 
       </div>
 
       <!--Body-->
       <div class="col-12 menu_header" style="margin-top:15px;">
 
          <h5 style="font-size: 14px;font-family: Verdana, Geneva, Tahoma, sans-serif;">{{'label.Writeareview' | translate}}</h5>
          <div class="review-view">
 
             <textarea cols="80" rows="5" [(ngModel)]="review" [ngModelOptions]="{standalone: true}" class="pop_up_header"
                style="border:dashed 1px rgb(102, 102, 102);">
                {{'label.Writeareview' | translate}}
          </textarea>
 
          </div>
 
          <a type="button" (click)="onReviewFormSubmit()" class="btn btn-outline-primary-1 waves-effect">{{'button.Submit' | translate}}</a>
       </div>
 
       <div class="col-12 menu_header" style="margin-top: 30px;" *ngIf="storeDetails?.settings?.showReviews == true">
          <h5 style="font-size: 14px; font-weight: 700; font-family: Verdana, Geneva, Tahoma, sans-serif;">{{'label.Reviews'| translate}}</h5>
 
 
 
          <div class="scroll" >
          <div *ngFor="let item of storeRatingList; let i = index;"> 
          <div class="review-view-inner">
             <div class="col-12">
 
                <div class="col-8  rating-pop-1">
                   <h5>{{item?.customerName}}</h5>
                </div>
 
                <span *ngIf="commonService.isLoggedIn && commonService.customer.id == item.customerId" (click)="deleteRating(item.id ,0 , index)">
                   <div class="col-2" style="float: right;"><img src="../../../assets/mall/img/delete.png" class="img-width"></div>
                </span>
 
 
                <div class="col-2  rating-pop">
                   <span class="text-custom-white rectangle-tag bg-gradient-green">
                      {{item?.rating}}
                   </span>
                </div>
 
                
 
             </div>
          </div>
 
 
          <div class="review-view-inner-text" style="margin-bottom: 15px; padding: 10px;">
             <div class="col-12">
               {{item?.review}}
                <div style="font-weight:normal; text-align: right;">{{item?.submittedOn | date :'dd-MM-yyyy'}}</div>
             </div>
             <div class="clear"></div>
          </div>
          </div>
       </div>
 
 
       </div>
 
 
 
 
    </div>
 
 