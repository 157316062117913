<div class="modal-header">
    <h4 class="modal-title"></h4>
      <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body" style="font-size: 16px;">
      {{ message }}
    </div>
    <div class="modal-footer" >
      <!-- <button type="button" class="btn btn-danger" (click)="decline()"></button> -->
      <button type="button" class="btn btn-primary" (click)="accept()">Ok</button>
    </div>