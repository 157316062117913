<div id="welcomeHeadImage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-content-color">
        <div class="modal-body">
            <img [src]="storeDetails?.welcomeImageURL" class="img-responsive">
        </div>
        <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-md" data-dismiss="modal">OK</button>
         </div>
   
    </div>
  </div>
</div>


<style>
  .modal {
   text-align: center;
   padding: 0!important;
 }
 
 .modal:before {
   content: '';
   display: inline-block;
   height: 100%;
   vertical-align: middle;
   margin-right: -4px;
 }
 
 .modal-dialog {
   display: inline-block;
   text-align: left;
   vertical-align: middle;
 
     position: relative;
     width: auto;
     margin: 10px;
 }

 .vivira{
  font-size: 1rem !important; margin-right: 10px !important;
 }
   </style>