<div class="modal-content-color">

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'header.Register' | translate}}</h4>
    <button type="button" class="pop-up-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true" class="pop-up-close-bt">×</span>
    </button>
  </div>
  <div class="modal-body ">
    <!-- <app-signup></app-signup> -->
    <div class="login d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 mx-auto">
            <h4 class="login-heading mb-4 login-text ">{{'label.Newuser!' | translate}}</h4>
            <div class="clear"></div>
            <form [formGroup]="signUpForm">
              <div class="form-label-group">
                <input formControlName="Name" type="email" id="userName" class="login-text-reg" placeholder="Vigil">
                <label for="userName" class="login-text">{{'label.Name' | translate}} <span class="validator">*</span></label>
              </div>
              <!-- <div class="form-label-group">
                         <input formControlName="Phone" type="email" id="mobile" class="form-control"
                            placeholder="+123 456 7890">
                         <label for="mobile">Mobile <span class="validator">*</span></label>
                      </div> -->
              <div class="row">
                <div class="col-3 pr-0">
                  <div class="form-label-group">
                    <input formControlName="CountryCode" type="text" id="countryCode" class="login-text-reg"
                      placeholder="" disabled>
                    <label for="countryCode" class="login-text">{{'label.ISDCode' | translate}}</label>
                  </div>
                </div>
                <div class="col-9">
                  <div class="form-label-group">
                    <input formControlName="Phone" type="text" id="mobile" class="login-text-reg"
                      placeholder="+123 456 7890">
                    <label for="mobile" class="login-text ">{{'label.Mobile' | translate}} <span class="required">*</span></label>
                  </div>
                </div>
              </div>
              <!-- <div class="form-label-group">
                         <input formControlName="Email" type="email" id="email" class="form-control"
                            placeholder="abc@yourcompany.com">
                         <label for="email">Email address</label>
                      </div>
  
                      <div class="form-label-group">
                         <input formControlName="Address" type="email" id="address" class="form-control"
                            placeholder="abc@yourcompany.com">
                         <label for="address">Address</label>
                      </div>
  
                      <div class="row">
                         <div class="col-md-6">
                            <div class="form-label-group">
                               <input formControlName="Location" type="text" id="inputAddress2" class="form-control"
                                  placeholder="Villa No 20">
                               <label for="inputAddress2">Location</label>
                            </div>
                         </div>
                         <div class="col-md-6">
                            <div class="form-label-group">
                               <input type="text" id="inputAddress3" class="form-control" placeholder="Souq bazar">
                               <label for="inputAddress3">Landmark</label>
                            </div>
                         </div>
                      </div> -->

              <button type="submit"
                class="btn-lg btn-outline-primary-1 btn-block btn-login text-uppercase font-weight-bold mb-2"
                [disabled]="signUpForm.invalid" (click)="signUp()">{{'button.Register' | translate}}</button>
              <div class="text-center pt-3">
                {{'desc.AlreadyHaveAccount' | translate}}<a class="font-weight-bold" style="cursor: pointer;"
                  (click)="activeModal.close(1)">{{'button.SignIn' | translate}}</a>
              </div>
              <div class="text-center pt-3" *ngIf="commonService.orderModes == 2">
                <a class="font-weight-bold" style="cursor: pointer;" (click)="activeModal.close(2)">{{'label.Loginasguest' | translate}}</a>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>