import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-instagram-follow-popup',
  templateUrl: './instagram-follow-popup.component.html',
  styleUrls: ['./instagram-follow-popup.component.scss']
})
export class InstagramFollowPopupComponent implements OnInit {
 @Input() storeDetails : any; 
  constructor(public activeModal : NgbActiveModal) { } 



  ngOnInit(): void {
    // this.storeDetails.instagramUrl='https://instagram.com/edine.by.cleveric?igshid=MzRlODBiNWFlZA==';
  }


  onInstagram(){
    window.open(this.storeDetails.instagramURL, '_blank');
  }
}
