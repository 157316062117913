<div class="modal-dialog-centered  modal-sm">
    <div class="modal-content-color gr">

      <div class="close"><img src="../../../assets/mall/css/white/images/close.png" style="cursor: pointer;" (click)="activeModal.close()"></div>
       
      <div class="logo"><img src="../../../assets/mall/css/white/images/insta-logo.png"></div>
     
      <!-- <h1>
        Join Us On Instagram
      </h1>

      <p>
        Text
      </p> -->
    

        <!-- <a href="{{storeDetails?.instagramURL}}" target="_blank"> Follow Us On Instagram</a> -->
        <div class="modal-footer" style="border-top:none">
      <button type="button" class="btn bt-width" data-dismiss="modal" (click)="onInstagram()">Follow Us On Instagram</button>
         </div>
   
    </div>
  </div>
    

<!-- <div id="instagram-follow-popup" class="modal fade " tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog ">
      <div class="modal-content-color  gr">
         
       <div class="row">
        <h1>
          Join Us On Instagram
        </h1>
       </div>

          <div class="modal-footer" style="border-top:none">
        <button type="button" class="btn btn-primary btn-md" data-dismiss="modal">Follow Us On Instagram</button>
           </div>
     
      </div>
    </div>
  </div> -->
  
  
  <style>
    .modal {
     text-align: center;
     padding: 0!important;
   }
   
   .modal:before {
     content: '';
     display: inline-block;
     height: 100%;
     vertical-align: middle;
     margin-right: -4px;
   }
   
   .modal-dialog {
     display: inline-block;
     text-align: left;
     vertical-align: middle;
   
       position: relative;
       width: auto;
       margin: 100px;
   }
  
   .vivira{
    font-size: 1rem !important; margin-right: 10px !important;
   }
     </style>