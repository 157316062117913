import { Component, OnInit } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../services/common.service';
import { Config } from '../services/config';
import { StoreService } from '../services/store.service';
import { ProductRatingPopupComponent } from '../shared-popups/product-rating-popup/product-rating-popup.component';
import { StoreRatingPopupComponent } from '../shared-popups/store-rating-popup/store-rating-popup.component';
import * as signalR from '@aspnet/signalr';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
 orderHistory =[];
 lang = Number(Config.lang)
 selectedOrderDetails ;

 private _hubConnection : HubConnection;
 signalData : any;
 addTransferChartDataListener: () => void;
  constructor(private service : StoreService , public commonService : CommonService,
    private modalService : NgbModal,
    private db: DbService,
    private spinner: NgxSpinnerService, ) { }

  ngOnInit(): void {
    this.getCustomerBookingHistory();
    this.getLoyaltyBalance();


    
  }


  loyalityDetails : Array<any>= [];

  getLoyaltyBalance(){
    let body = {
      customerId : this.commonService.customer.id ,
      storeId : this.commonService.storeId,
    }
    this.db.methodPost("Customers/GetCustomerLoyaltyBalance" , body).subscribe(resdata =>{
      this.loyalityDetails = resdata;
    })
  }


  onStoreReview(item){
  const modalRef =  this.modalService.open(StoreRatingPopupComponent);
  modalRef.componentInstance.storeId = item.storeId;
  modalRef.componentInstance.storeRating = item.rating;


  }
  onProductReview(item){
  const modalRef =  this.modalService.open(ProductRatingPopupComponent);
  modalRef.componentInstance.selectedProductReviewRating = item.rating;
  modalRef.componentInstance.selectedProductReview = item;
  modalRef.componentInstance.storeId = this.selectedOrderDetails.storeId;
  }

  getCustomerBookingHistory(){
    this.service.getOrdersByCustomerId(this.commonService.customer.id).subscribe(resdata =>{
       
      this.orderHistory =resdata;
      if(this.orderHistory.length > 0){
        this.GetCustomerOrdersStatus(this.orderHistory[0].id);
      }
    })
  }

  GetCustomerOrdersStatus(orderId){
    this.service.GetCustomerOrdersStatus(orderId).subscribe(resdata =>{
     this.selectedOrderDetails = resdata;
    })
  }

}
