import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-restaurent-welcome-page',
  templateUrl: './restaurent-welcome-page.component.html',
  styleUrls: ['./restaurent-welcome-page.component.scss']
})
export class RestaurentWelcomePageComponent implements OnInit {
  @Input() storeDetails ;
  constructor() { }

  ngOnInit(): void {
  }

}
