<section class="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
    <div class="container">
        <div class="row">
            <div class="col-md-7 menu_header ">
                <div class=" bg-white-1 bg-white-2 rounded shadow-sm  mb-4 highlight-order">
                    <div class="bg-white-2 p-4 border-bottom">
                        <div class="mb-2">
                            <small>{{'label.Order' | translate}} #{{selectedOrderDetails?.orderCode}}
                              <a class="float-right font-weight-bold" href="#">
                                <!-- <i class="fa fa-headphones"></i> HELP -->
                              </a>
                              </small>
                        </div>
                        <h6 class="mb-1 mt-1"><a href="detail.html" class="order-list-txt-1">
                            <span *ngIf="lang == 0">    {{selectedOrderDetails?.storeName}}</span>
                            <span *ngIf="lang == 1">    {{selectedOrderDetails?.storeNameAr}}</span>
                            
                            </a>
                        </h6>
                        <p class="text-gray mb-0"><i class="fa fa-clock-o"></i> {{selectedOrderDetails?.orderedOn | date:'dd/MM/yyyy HH:mm:ss'}}  | {{selectedOrderDetails?.details?.length}} Items | {{commonService.currency}}{{selectedOrderDetails?.totalAmount | number:'1.2-2'}}</p>
                    </div>
                    <div class="bg-white-2 p-4 border-bottom">
                        <div class="osahan-track-order-detail po">
                            <h5 class="mt-0 mb-3">{{'label.OrderDetails' | translate}}</h5>
                            <div class="row">
                                <div class="col-md-5">
                                    <small>{{'label.FROM' | translate}}</small>
                                    <h6 class="mb-1 mt-1"><a href="detail.html" class="order-list-txt"><i class="fa fa-shopping-bag"></i>
                                        <span *ngIf="lang == 0">    {{selectedOrderDetails?.storeName}}</span>
                                        <span *ngIf="lang == 1">    {{selectedOrderDetails?.storeNameAr}}</span>
                                        
                                    </a>
                                    </h6>
                                    <p class="text-gray mb-5">{{selectedOrderDetails?.storeAddress}}</p>
                                    <small *ngIf="selectedOrderDetails?.orderType === 'Delivery'">{{'label.DELIVERTO' | translate}}</small>
                                    <small *ngIf="selectedOrderDetails?.orderType === 'Takeaway'">{{'label.PickUpBy' | translate}}</small>
                                    <small *ngIf="selectedOrderDetails?.orderType === 'Dine'">{{'label.Dinein' | translate}}</small>




                                  

                                    <h6 class="mb-1 mt-1"><span class="text-black"><i class="fa fa-map-signs"></i> {{selectedOrderDetails?.customerName}}
                                    </span>
                                    </h6>

                                    <div class="address-bx" *ngIf="selectedOrderDetails?.customerAddress && selectedOrderDetails?.orderType === 'Delivery'">

                                        <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId == 1"
                                            style="text-decoration: underline;">
                                            {{'label.Appartment' | translate}} :-
                                            <br>
                                        </span>
                                        <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId == 2"
                                            style="text-decoration: underline;">
                                            {{'label.House' | translate}}  :-
                                            <br>

                                        </span>
                                        <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId == 3"
                                            style="text-decoration: underline;">
                                            {{'label.Office' | translate}}  :-
                                            <br>

                                        </span>
                                        <!-- {{selectedOrderDetails?.customerAddress?.name}} , -->
                                        <br>

                                        <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId != 2">
                                            <span *ngIf="selectedOrderDetails?.customerAddress?.building"> 
                                                {{'label.Building' | translate}} : {{selectedOrderDetails?.customerAddress?.building}},
                                            <br>

                                            </span>

                                            <span *ngIf="selectedOrderDetails?.customerAddress?.floor">
                                                {{'label.Floor' | translate}} : {{selectedOrderDetails?.customerAddress?.floor}},
                                            <br>

                                            </span>


                                        </span>

                                        <span *ngIf="selectedOrderDetails?.customerAddress?.houseNo">
                                            <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId ==1">
                                                {{'label.AppartmentNo' | translate}} 
                                            </span>
                                            <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId ==2">
                                                {{'label.HouseNo' | translate}} 
                                            </span>
                                            <span *ngIf="selectedOrderDetails?.customerAddress?.addressTypeId ==3">
                                                {{'label.OfficeNo' | translate}} 
                                            </span>
                                            
                                            : {{selectedOrderDetails?.customerAddress?.houseNo}},
<br>
                                        </span>

                                        <span>
                                            {{selectedOrderDetails?.customerAddress?.phone}}
                                            <br>
                                        </span>
                                        


                                        <span *ngIf="selectedOrderDetails?.customerAddress?.additionalDirection">
                                            {{'label.Direction' | translate}} :
                                            {{selectedOrderDetails?.customerAddress?.additionalDirection}}, <br> </span>

                                            <span *ngIf="selectedOrderDetails?.customerAddress?.location">
                                                {{'label.Location' | translate}} :
                                                {{selectedOrderDetails?.customerAddress?.location}}, </span>

                                        

                                    </div>
                                    <p class="text-gray mb-0" *ngIf="selectedOrderDetails?.orderType === 'Dine'">{{selectedOrderDetails?.tableNo}}
                                    </p> 
                                </div>
                                <div class="col-md-7">
                                    <div class="mb-2"><small><i class="fa icofont-list"></i> {{selectedOrderDetails?.details?.length}} {{'label.ITEMS' | translate}}</small></div>
                                    <ng-container *ngFor="let item of selectedOrderDetails?.details">
                                        <ngb-rating [max]="5" (click)="onProductReview(item)" [(rate)]="item.rating" [readonly]="true" style="font-size: 14px;" class="pop_up_header-3"></ngb-rating>
                                    
                                         <div class="clear"></div>
                                        <p class="mb-2 "  *ngIf="item?.isAddOn == false">
                                            <i class="fa fa-circle text-danger food-item"></i>
                                        <span *ngIf="item?.variantId == 0 " class="order-history-vr">
                                            <span *ngIf="lang == 0">    {{item?.productName}}</span>
                                            <span *ngIf="lang == 1">    {{item?.productNameAr}}</span>
                                        
                                        </span> 
                                         <span *ngIf="item?.variantId > 0 " class="order-history-vr-1">
                                             <span *ngIf="lang == 0">    {{item?.variantName}}</span>
                                             <span *ngIf="lang == 1">    {{item?.variantNameAr}}</span>
                                            
                                            </span> "  x {{item.quantity}} 
                                         
                                         
                                        <span class="  order-text">{{commonService.currency}} {{item.price*item.quantity | number:'1.2-2'}}</span></p>
                                        <p class="mb-2 ">
                                        <ng-conatiner *ngFor="let innerItem of item.addOnOrders">
                                            <p class="mb-2" *ngIf="innerItem?.isAddOn == true"><i class="fa fa-circle text-success food-item"></i>
                                                <span *ngIf="innerItem?.variantId == 0 " class="order-history-vr">
                                                    
                                                
                                                    <span *ngIf="lang == 0">    {{innerItem?.productName}}</span>
                                                    <span *ngIf="lang == 1">    {{innerItem?.productNameAr}}</span>
                                                
                                                </span> 
                                                <span *ngIf="innerItem?.variantId > 0 " >
                                                    <span *ngIf="lang == 0">    {{innerItem?.variantName}}</span>
                                                    <span *ngIf="lang == 1">    {{innerItem?.variantNameAr}}</span>
                                                </span> " 
                                                 <!-- x {{innerItem.quantity}}    -->
                                                <span class="order-text">{{commonService.currency}} {{innerItem.price*innerItem.quantity | number:'1.2-2'}}</span>
                                            </p>
                                            <div class="clear"></div>
                                        </ng-conatiner>

                                        <ng-conatiner *ngFor="let innerItem of item.comboOrders">
                                            <p class="mb-2"><i class="fa fa-circle text-success food-item"></i>
                                                
                                                <span  >
                                                    <span *ngIf="lang == 0">    {{innerItem?.comboProductName}}</span>
                                                    <span *ngIf="lang == 1">    {{innerItem?.comboProductNameAr}}</span>
                                                </span> "  x {{innerItem.quantity}}   
                                                <!-- <span class="order-text text-secondary">{{commonService.currency}} {{innerItem.price*innerItem.quantity | number:'1.2-2'}}</span> -->
                                            </p>
                                            <div class="clear"></div>
                                        </ng-conatiner>
                                    </p>

                                    
                                   

                                    
                                  </ng-container>
                                    <!-- <p class="mb-2"><i class="fa fa-circle text-success food-item"></i> Corn &amp; Peas Salad x 1   <span class="float-right text-secondary">$209</span></p>
                                    <p class="mb-2"><i class="fa fa-circle text-success food-item"></i> Veg Seekh Sub 6" (15 cm) x 1  <span class="float-right text-secondary">$133</span></p>
                                    <p class="mb-2"><i class="fa fa-circle text-danger food-item"></i> Chicken Tikka Sub 12" (30 cm) x 1   <span class="float-right text-secondary">$314</span></p> -->
                                    <hr>
                                    <p class="mb-0 font-weight-bold text-black ">{{'label.TOTALBILL' | translate}}  <span class="  order-text">{{commonService.currency}}{{selectedOrderDetails?.totalAmount | number:'1.2-2'}}</span></p>
                                    <p class="mb-0 text-info">
                                        <small>
                                      <!-- Paid via Credit/Debit card -->
                                        <span class="order-text text-danger">{{commonService.currency}} {{selectedOrderDetails?.discountAmount | number:'1.2-2'}} OFF</span></small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white-2 p-4 mt-2">
                        <div class="row text-center">
                            <div class="col">
                                <i class="fa fa-file-text-o fa fa-3 text-info"></i>
                                <p class="mt-1 font-weight-bold text-dark mb-0">{{selectedOrderDetails?.orderStatus}}</p>
                                <!-- <small class="text-info mb-0">NOW</small> -->
                            </div>
                            <!-- <div class="col">
                                <i class="fa fa-check-circle-o fa fa-3 text-success"></i>
                                <p class="mt-1 font-weight-bold text-dark mb-0">Order Confirmed</p>
                                <small class="text-success mb-0">NEXT</small>
                            </div>
                            <div class="col">
                                <i class="fa fa-truck fa-3 text-primary"></i>
                                <p class="mt-1 font-weight-bold text-dark mb-0">Order Picked Up</p>
                                <small class="text-primary mb-0">LATER (ET : 30min)</small>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 menu_header">
                <div class="bg-white-2 rounded shadow-sm mb-4">
                    <h4 class="p-4 m-0 border-bottom">{{'label.OrderHistory' | translate}}</h4>
                    <div class="overflow-y-scroll">
                        <div class="bg-white-2 p-4 border-bottom" *ngFor="let item of orderHistory">
                            <a style="cursor: pointer;" (click)="GetCustomerOrdersStatus(item.id)">
                                <div class="row">
                                    <div class="col-9">
                                        <div class="mb-2">
                                            <small>{{'label.OrderID' | translate}} #{{item.orderCode}}</small>
                                        </div>
                                        <h6 class="mb-1 mt-1">
                                            
                                            <span *ngIf="lang == 0">    {{item?.storeName}}</span>
                                            <span *ngIf="lang == 1">    {{item?.storeNameAr}}</span>
                                        </h6>
                                        <br>
                                        
                                        <ngb-rating (click)="onStoreReview(item)"  [max]="5" [(rate)]="item.rating" [readonly]="true" style="font-size: 15px;" class="pop_up_header-3"></ngb-rating>
                                        <div class="clear"></div>
                                        <p class="text-gray mb-0"><i class="fa fa-clock-o"></i> {{item.orderedOn | date :'dd-MM-yyyy'}} | {{commonService.currency}}{{item.totalAmount | number:'1.2-2' }}</p>
                                    </div>
                                    <div class="col-3">
                                        <span class="float-right">
                                            <img [src]="item.storeURL" class="img-fluid" alt="">
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- <div class="bg-white-2 p-4 border-bottom">
                        <a href="#">
                            <div class="row">
                                <div class="col-9">
                                    <div class="mb-2">
                                        <small>Order ID #1236587492</small>
                                    </div>
                                    <h6 class="mb-1 mt-1">Delight Hut Restaurant</h6>
                                    <p class="text-gray mb-0"><i class="fa fa-clock-o"></i> 10-02-2020 | $210</p>
                                </div>
                                <div class="col-3">
                                    <span class="float-right">
                                        <img src="../../assets/img/foods/pancakes-2291908_640.jpg" class="img-fluid" alt="">
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="bg-white-2 p-4 border-bottom">
                        <a href="#">
                            <div class="row">
                                <div class="col-9">
                                    <div class="mb-2">
                                        <small>Order ID #98514692</small>
                                    </div>
                                    <h6 class="mb-1 mt-1">Humayoon Inn</h6>
                                    <p class="text-gray mb-0"><i class="fa fa-clock-o"></i> 31-122-2020 | $500</p>
                                </div>
                                <div class="col-3">
                                    <span class="float-right">
                                        <img src="../../assets/img/foods/spaghetti-2931846_640.jpg" class="img-fluid" alt="">
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
