import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {

    
  // @Input() title: string;
  @Input() message: string;
  @Input() id: number;
  // @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal , private dbService : DbService) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {


    let body = {
      Id : this.id
    }
    this.dbService.methodPost("PushNotifications/PushNotificationViewed/" + this.id,body).subscribe(resdata =>{
      
       this.activeModal.close(true);

    })
    
  }

  public dismiss() {
    this.activeModal.dismiss();
  }




}

